import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '@app/core/services/authentication.service';

@Component({
  selector: 'app-logout-redirect',
  templateUrl: './logout-redirect.component.html',
  styleUrls: ['./logout-redirect.component.scss']
})
export class LogoutRedirectComponent implements OnInit {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  ngOnInit() {
    this.authenticationService.logout().subscribe(() => {
      // Check if the current route is already the login page
      if (!this.router.url.includes('login')) {
        // If not, navigate to the login page
        this.router.navigate(['/login']);
      }
    });
  }
}
