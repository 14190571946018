import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { TokenHolderService } from 'app/core/services/token-holder.service';
import { AuthorizationAPI } from 'app/core/proxy/Auth/authorizationAPI';
import { OriginationReadApi } from 'app/core/proxy/Read/originationReadApi';
import { ApplicationInsightsService } from 'app/core/services/application-insights.service';
import { SignalrService, SignalREvent } from '@app/core/services/signalr.service';
import { SecurityContext } from '../interfaces/i-security-context';
import { LoginContext } from '../interfaces/i-login-context';
import { PasswordResetModel } from '../proxy/Auth/models';
import { HttpClient } from '@angular/common/http';
import { IpService } from '@app/core/services/ip.service';
import { SignalREvents } from '@app/Constants';
import { Router } from '@angular/router';

const credentialsKey = 'credentials';
const credentialsExpiry = 'credentialsExpiry';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  ipAddress: string;

  constructor(
    private authApi: AuthorizationAPI,
    private TokenHolder: TokenHolderService,
    private readApi: OriginationReadApi,
    private http: HttpClient,
    private ipservice: IpService,
    private ApplicationInsights: ApplicationInsightsService,
    private SignalRService: SignalrService,
    private router: Router,
    private appInsightsService: ApplicationInsightsService
  ) {
    const savedCredentials = localStorage.getItem(credentialsKey);
    const savedCredentialsExpiry = localStorage.getItem(credentialsExpiry);
    if (savedCredentials) {
      this.setSecurityContext(JSON.parse(savedCredentials), true);
      this.setCredentialsExpiry(JSON.parse(savedCredentialsExpiry), true);
    }

    this.ipservice.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
    this.SignalRService.getChannel(SignalREvents.LOGOUT_CUSTOMER_NOTIFICATION).subscribe((event: SignalREvent) => {
      if (this.credentials && this.credentials.id == event.data.p1) {
        this.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
      }
    });
  }

  private _credentials: SecurityContext | null;
  private _credentialsExpiry: Date | null;

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): SecurityContext | null {
    return this._credentials;
  }

  get credentialsExpiry(): Date | null {
    return this._credentialsExpiry;
  }

  get localCredentials(): SecurityContext | null {
    const savedCredentials = localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      return JSON.parse(savedCredentials) as SecurityContext;
    }
    return null;
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext, force: boolean): Observable<SecurityContext> {
    context.force = force;
    context.ipAddress = this.ipAddress;
    const loginPromise = this.authApi.createToken({ body: context, force: force }).then(user => {
      this.setSecurityContext(user, context.remember);
      this.setCredentialsExpiry(user.expiresUTC, context.remember);
      return user;
    });

    return from(loginPromise);
  }

  validateTwoWayAuth(twoFactorModel: { username: string; code: string; ipAddress: string }) {
    twoFactorModel.ipAddress = this.ipAddress;
    const loginPromise = this.authApi.validateTwoFactorLogin({ body: twoFactorModel }).then(user => {
      this.setSecurityContext(user, true);
      this.setCredentialsExpiry(user.expiresUTC, true);
      return user;
    });

    return from(loginPromise);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    const savedCredentials = localStorage.getItem(credentialsKey);
    const savedCredentialsExpiry = localStorage.getItem(credentialsExpiry);
    try {
      this.setSecurityContext();
      this.setCredentialsExpiry();
      return of(true);
    } catch (err) {
      this.appInsightsService.logException(
        new Error(
          'Error occurs on logout. savedCredentials: ' +
            savedCredentials +
            'savedCredentialsExpiry: ' +
            savedCredentialsExpiry +
            '. ' +
            err
        )
      );
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials && !!this.credentials.token;
  }

  isAuthenticatedValid(): boolean {
    if (!this.isAuthenticated() || credentialsExpiry == null) {
      return false;
    }

    return new Date() < new Date(this.credentialsExpiry);
  }

  requiresTwoWayAuth(credentials: SecurityContext): boolean {
    return !!this.credentials && !credentials.token && !!this.credentials.requiresTwoFactor;
  }

  isPartner(): boolean {
    return (
      this.isAuthenticated() && !!this.credentials.userType && this.credentials.userType.toLowerCase() === 'partner'
    );
  }

  isCustomer(): boolean {
    return (
      this.isAuthenticated() &&
      !!this.credentials.userType &&
      (this.credentials.userType.toLowerCase() === 'customer' || this.credentials.userType.toLowerCase() === 'admin')
    );
  }

  isEmployee(): boolean {
    return (
      this.isAuthenticated() && !!this.credentials.userType && this.credentials.userType.toLowerCase() === 'employee'
    );
  }

  isCorprate(): boolean {
    return (
      this.isAuthenticated() &&
      !!this.credentials.userType &&
      this.credentials.userType.toLowerCase() === 'corpratecardholder'
    );
  }

  isAgent(): boolean {
    return this.isAuthenticated() && !!this.credentials.userType && this.credentials.userType.toLowerCase() === 'agent';
  }

  setSecurityContext(credentials?: SecurityContext, remember?: boolean) {
    this.setCredentials(credentials, remember);
    this.setToken(credentials ? credentials.token : 'default');
    credentials ? this.ApplicationInsights.setUserId(credentials.id) : this.ApplicationInsights.clearUserId();
    credentials && !this.SignalRService.getHubConnectionId()
      ? this.SignalRService.initConnection()
      : this.SignalRService.closeConnection();
  }

  loginForShortCode(shortCode: string) {
    return this.readApi.getToken(shortCode).then(token => {
      this.setSecurityContext({ token: token.body }, false);
      this.setCredentialsExpiry(this.addHours(new Date(), 5), false);
    });
  }

  isLoginSms() {
    return this.authApi.isLoginSms();
  }

  loadAuthorizationToken(params: { customerId: string }) {
    return this.readApi
      .getAuthorizationToken(params)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        return null;
      });
  }

  passwordExpiresReset(userName: string, updateModel: PasswordResetModel) {
    return this.authApi.passwordExpiresReset(userName, { body: updateModel });
  }

  resetPassword(userName: string, updateModel: PasswordResetModel) {
    return this.authApi.reset(userName, { body: updateModel });
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  private setCredentials(credentials?: SecurityContext, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  setCredentialsExpiry(date?: Date, remember?: boolean) {
    this._credentialsExpiry = date || null;

    if (date) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsExpiry, JSON.stringify(date));
    } else {
      sessionStorage.removeItem(credentialsExpiry);
      localStorage.removeItem(credentialsExpiry);
    }
  }

  addHours(date, hours) {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);
    return newDate;
  }

  private setToken(token: string) {
    this.TokenHolder.setToken(token);
  }
}
