/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from '@azure/ms-rest-js';
import * as Models from './models';
import * as Mappers from './models/mappers';
import * as Parameters from './models/parameters';
import { OriginationReadApiContext } from './originationReadApiContext';

class OriginationReadApi extends OriginationReadApiContext {
  /**
   * Initializes a new instance of the OriginationReadApi class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, options?: Models.OriginationReadApiOptions) {
    super(credentials, options);
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentAnnouncementsResponse>
   */
  getAgentAnnouncements(
    agentId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAgentAnnouncementsResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  getAgentAnnouncements(agentId: string, callback: msRest.ServiceCallback<Models.Announcement[]>): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentAnnouncements(
    agentId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Announcement[]>
  ): void;
  getAgentAnnouncements(
    agentId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Announcement[]>,
    callback?: msRest.ServiceCallback<Models.Announcement[]>
  ): Promise<Models.GetAgentAnnouncementsResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      getAgentAnnouncementsOperationSpec,
      callback
    ) as Promise<Models.GetAgentAnnouncementsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllAgentAnnouncementsResponse>
   */
  getAllAgentAnnouncements(options?: msRest.RequestOptionsBase): Promise<Models.GetAllAgentAnnouncementsResponse>;
  /**
   * @param callback The callback
   */
  getAllAgentAnnouncements(callback: msRest.ServiceCallback<Models.Announcement[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllAgentAnnouncements(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Announcement[]>
  ): void;
  getAllAgentAnnouncements(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Announcement[]>,
    callback?: msRest.ServiceCallback<Models.Announcement[]>
  ): Promise<Models.GetAllAgentAnnouncementsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllAgentAnnouncementsOperationSpec,
      callback
    ) as Promise<Models.GetAllAgentAnnouncementsResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentsResponse>
   */
  getAgents(
    partnerId: string,
    options?: Models.OriginationReadApiGetAgentsOptionalParams
  ): Promise<Models.GetAgentsResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getAgents(partnerId: string, callback: msRest.ServiceCallback<Models.Agent[]>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgents(
    partnerId: string,
    options: Models.OriginationReadApiGetAgentsOptionalParams,
    callback: msRest.ServiceCallback<Models.Agent[]>
  ): void;
  getAgents(
    partnerId: string,
    options?: Models.OriginationReadApiGetAgentsOptionalParams | msRest.ServiceCallback<Models.Agent[]>,
    callback?: msRest.ServiceCallback<Models.Agent[]>
  ): Promise<Models.GetAgentsResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getAgentsOperationSpec,
      callback
    ) as Promise<Models.GetAgentsResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentResponse>
   */
  getAgent(agentId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAgentResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  getAgent(agentId: string, callback: msRest.ServiceCallback<Models.Agent>): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgent(agentId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Agent>): void;
  getAgent(
    agentId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Agent>,
    callback?: msRest.ServiceCallback<Models.Agent>
  ): Promise<Models.GetAgentResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      getAgentOperationSpec,
      callback
    ) as Promise<Models.GetAgentResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentApplicationsResponse>
   */
  getAgentApplications(
    agentId: string,
    options?: Models.OriginationReadApiGetAgentApplicationsOptionalParams
  ): Promise<Models.GetAgentApplicationsResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  getAgentApplications(agentId: string, callback: msRest.ServiceCallback<Models.ApplicationListProjection[]>): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentApplications(
    agentId: string,
    options: Models.OriginationReadApiGetAgentApplicationsOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection[]>
  ): void;
  getAgentApplications(
    agentId: string,
    options?:
      | Models.OriginationReadApiGetAgentApplicationsOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjection[]>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjection[]>
  ): Promise<Models.GetAgentApplicationsResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      getAgentApplicationsOperationSpec,
      callback
    ) as Promise<Models.GetAgentApplicationsResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.SearchAgentsResponse>
   */
  searchAgents(
    partnerId: string,
    options?: Models.OriginationReadApiSearchAgentsOptionalParams
  ): Promise<Models.SearchAgentsResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  searchAgents(partnerId: string, callback: msRest.ServiceCallback<Models.Agent[]>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  searchAgents(
    partnerId: string,
    options: Models.OriginationReadApiSearchAgentsOptionalParams,
    callback: msRest.ServiceCallback<Models.Agent[]>
  ): void;
  searchAgents(
    partnerId: string,
    options?: Models.OriginationReadApiSearchAgentsOptionalParams | msRest.ServiceCallback<Models.Agent[]>,
    callback?: msRest.ServiceCallback<Models.Agent[]>
  ): Promise<Models.SearchAgentsResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      searchAgentsOperationSpec,
      callback
    ) as Promise<Models.SearchAgentsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SearchAllAgentsResponse>
   */
  searchAllAgents(
    options?: Models.OriginationReadApiSearchAllAgentsOptionalParams
  ): Promise<Models.SearchAllAgentsResponse>;
  /**
   * @param callback The callback
   */
  searchAllAgents(callback: msRest.ServiceCallback<Models.Agent[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  searchAllAgents(
    options: Models.OriginationReadApiSearchAllAgentsOptionalParams,
    callback: msRest.ServiceCallback<Models.Agent[]>
  ): void;
  searchAllAgents(
    options?: Models.OriginationReadApiSearchAllAgentsOptionalParams | msRest.ServiceCallback<Models.Agent[]>,
    callback?: msRest.ServiceCallback<Models.Agent[]>
  ): Promise<Models.SearchAllAgentsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      searchAllAgentsOperationSpec,
      callback
    ) as Promise<Models.SearchAllAgentsResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.AdvanceExtendedSummaryOnAgentResponse>
   */
  advanceExtendedSummaryOnAgent(
    agentId: string,
    options?: Models.OriginationReadApiAdvanceExtendedSummaryOnAgentOptionalParams
  ): Promise<Models.AdvanceExtendedSummaryOnAgentResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  advanceExtendedSummaryOnAgent(
    agentId: string,
    callback: msRest.ServiceCallback<Models.AdvanceFullDetailPagedList>
  ): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  advanceExtendedSummaryOnAgent(
    agentId: string,
    options: Models.OriginationReadApiAdvanceExtendedSummaryOnAgentOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceFullDetailPagedList>
  ): void;
  advanceExtendedSummaryOnAgent(
    agentId: string,
    options?:
      | Models.OriginationReadApiAdvanceExtendedSummaryOnAgentOptionalParams
      | msRest.ServiceCallback<Models.AdvanceFullDetailPagedList>,
    callback?: msRest.ServiceCallback<Models.AdvanceFullDetailPagedList>
  ): Promise<Models.AdvanceExtendedSummaryOnAgentResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      advanceExtendedSummaryOnAgentOperationSpec,
      callback
    ) as Promise<Models.AdvanceExtendedSummaryOnAgentResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadAdvanceExtendedSummaryOnAgentFileResponse>
   */
  downloadAdvanceExtendedSummaryOnAgentFile(
    agentId: string,
    options?: Models.OriginationReadApiDownloadAdvanceExtendedSummaryOnAgentFileOptionalParams
  ): Promise<Models.DownloadAdvanceExtendedSummaryOnAgentFileResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  downloadAdvanceExtendedSummaryOnAgentFile(
    agentId: string,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadAdvanceExtendedSummaryOnAgentFile(
    agentId: string,
    options: Models.OriginationReadApiDownloadAdvanceExtendedSummaryOnAgentFileOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  downloadAdvanceExtendedSummaryOnAgentFile(
    agentId: string,
    options?:
      | Models.OriginationReadApiDownloadAdvanceExtendedSummaryOnAgentFileOptionalParams
      | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.DownloadAdvanceExtendedSummaryOnAgentFileResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      downloadAdvanceExtendedSummaryOnAgentFileOperationSpec,
      callback
    ) as Promise<Models.DownloadAdvanceExtendedSummaryOnAgentFileResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentSentLinkRequestResponse>
   */
  getAgentSentLinkRequest(
    agentId: string,
    options?: Models.OriginationReadApiGetAgentSentLinkRequestOptionalParams
  ): Promise<Models.GetAgentSentLinkRequestResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  getAgentSentLinkRequest(agentId: string, callback: msRest.ServiceCallback<Models.SendLinkRequest>): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentSentLinkRequest(
    agentId: string,
    options: Models.OriginationReadApiGetAgentSentLinkRequestOptionalParams,
    callback: msRest.ServiceCallback<Models.SendLinkRequest>
  ): void;
  getAgentSentLinkRequest(
    agentId: string,
    options?:
      | Models.OriginationReadApiGetAgentSentLinkRequestOptionalParams
      | msRest.ServiceCallback<Models.SendLinkRequest>,
    callback?: msRest.ServiceCallback<Models.SendLinkRequest>
  ): Promise<Models.GetAgentSentLinkRequestResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      getAgentSentLinkRequestOperationSpec,
      callback
    ) as Promise<Models.GetAgentSentLinkRequestResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentSendLinkUrlResponse>
   */
  getAgentSendLinkUrl(
    agentId: string,
    options?: Models.OriginationReadApiGetAgentSendLinkUrlOptionalParams
  ): Promise<Models.GetAgentSendLinkUrlResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  getAgentSendLinkUrl(agentId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentSendLinkUrl(
    agentId: string,
    options: Models.OriginationReadApiGetAgentSendLinkUrlOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getAgentSendLinkUrl(
    agentId: string,
    options?: Models.OriginationReadApiGetAgentSendLinkUrlOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetAgentSendLinkUrlResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      getAgentSendLinkUrlOperationSpec,
      callback
    ) as Promise<Models.GetAgentSendLinkUrlResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAlertNotificationsResponse>
   */
  getAlertNotifications(options?: msRest.RequestOptionsBase): Promise<Models.GetAlertNotificationsResponse>;
  /**
   * @param callback The callback
   */
  getAlertNotifications(callback: msRest.ServiceCallback<Models.AlertNotificationProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAlertNotifications(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.AlertNotificationProjection[]>
  ): void;
  getAlertNotifications(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AlertNotificationProjection[]>,
    callback?: msRest.ServiceCallback<Models.AlertNotificationProjection[]>
  ): Promise<Models.GetAlertNotificationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAlertNotificationsOperationSpec,
      callback
    ) as Promise<Models.GetAlertNotificationsResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAlertNotificationResponse>
   */
  getAlertNotification(id: number, options?: msRest.RequestOptionsBase): Promise<Models.GetAlertNotificationResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getAlertNotification(id: number, callback: msRest.ServiceCallback<Models.AlertNotificationProjection>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getAlertNotification(
    id: number,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.AlertNotificationProjection>
  ): void;
  getAlertNotification(
    id: number,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AlertNotificationProjection>,
    callback?: msRest.ServiceCallback<Models.AlertNotificationProjection>
  ): Promise<Models.GetAlertNotificationResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      getAlertNotificationOperationSpec,
      callback
    ) as Promise<Models.GetAlertNotificationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAlertNotificationCategoriesResponse>
   */
  getAlertNotificationCategories(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAlertNotificationCategoriesResponse>;
  /**
   * @param callback The callback
   */
  getAlertNotificationCategories(
    callback: msRest.ServiceCallback<Models.AlertNotificationEmailCategoryProjection[]>
  ): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAlertNotificationCategories(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.AlertNotificationEmailCategoryProjection[]>
  ): void;
  getAlertNotificationCategories(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AlertNotificationEmailCategoryProjection[]>,
    callback?: msRest.ServiceCallback<Models.AlertNotificationEmailCategoryProjection[]>
  ): Promise<Models.GetAlertNotificationCategoriesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAlertNotificationCategoriesOperationSpec,
      callback
    ) as Promise<Models.GetAlertNotificationCategoriesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAlertNotificationCategoriesByEmailResponse>
   */
  getAlertNotificationCategoriesByEmail(
    options?: Models.OriginationReadApiGetAlertNotificationCategoriesByEmailOptionalParams
  ): Promise<Models.GetAlertNotificationCategoriesByEmailResponse>;
  /**
   * @param callback The callback
   */
  getAlertNotificationCategoriesByEmail(callback: msRest.ServiceCallback<Models.AlertNotificationProjection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAlertNotificationCategoriesByEmail(
    options: Models.OriginationReadApiGetAlertNotificationCategoriesByEmailOptionalParams,
    callback: msRest.ServiceCallback<Models.AlertNotificationProjection>
  ): void;
  getAlertNotificationCategoriesByEmail(
    options?:
      | Models.OriginationReadApiGetAlertNotificationCategoriesByEmailOptionalParams
      | msRest.ServiceCallback<Models.AlertNotificationProjection>,
    callback?: msRest.ServiceCallback<Models.AlertNotificationProjection>
  ): Promise<Models.GetAlertNotificationCategoriesByEmailResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAlertNotificationCategoriesByEmailOperationSpec,
      callback
    ) as Promise<Models.GetAlertNotificationCategoriesByEmailResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicantNamesResponse>
   */
  getApplicantNames(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicantNamesResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getApplicantNames(organisationId: string, callback: msRest.ServiceCallback<Models.Name[]>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicantNames(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Name[]>
  ): void;
  getApplicantNames(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Name[]>,
    callback?: msRest.ServiceCallback<Models.Name[]>
  ): Promise<Models.GetApplicantNamesResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getApplicantNamesOperationSpec,
      callback
    ) as Promise<Models.GetApplicantNamesResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicantsResponse>
   */
  getApplicants(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicantsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getApplicants(applicationId: string, callback: msRest.ServiceCallback<Models.ApplicantProjection[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicants(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicantProjection[]>
  ): void;
  getApplicants(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicantProjection[]>,
    callback?: msRest.ServiceCallback<Models.ApplicantProjection[]>
  ): Promise<Models.GetApplicantsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getApplicantsOperationSpec,
      callback
    ) as Promise<Models.GetApplicantsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPrimaryApplicantResponse>
   */
  getPrimaryApplicant(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPrimaryApplicantResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getPrimaryApplicant(applicationId: string, callback: msRest.ServiceCallback<Models.PrimaryApplicant>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPrimaryApplicant(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PrimaryApplicant>
  ): void;
  getPrimaryApplicant(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PrimaryApplicant>,
    callback?: msRest.ServiceCallback<Models.PrimaryApplicant>
  ): Promise<Models.GetPrimaryApplicantResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getPrimaryApplicantOperationSpec,
      callback
    ) as Promise<Models.GetPrimaryApplicantResponse>;
  }

  /**
   * @param applicantId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicantDriverLicenceUrlResponse>
   */
  getApplicantDriverLicenceUrl(
    applicantId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicantDriverLicenceUrlResponse>;
  /**
   * @param applicantId
   * @param callback The callback
   */
  getApplicantDriverLicenceUrl(applicantId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicantId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicantDriverLicenceUrl(
    applicantId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getApplicantDriverLicenceUrl(
    applicantId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetApplicantDriverLicenceUrlResponse> {
    return this.sendOperationRequest(
      {
        applicantId,
        options
      },
      getApplicantDriverLicenceUrlOperationSpec,
      callback
    ) as Promise<Models.GetApplicantDriverLicenceUrlResponse>;
  }

  /**
   * @param applicantId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicantResponse>
   */
  getApplicant(applicantId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicantResponse>;
  /**
   * @param applicantId
   * @param callback The callback
   */
  getApplicant(applicantId: string, callback: msRest.ServiceCallback<Models.ApplicantProjection>): void;
  /**
   * @param applicantId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicant(
    applicantId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicantProjection>
  ): void;
  getApplicant(
    applicantId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicantProjection>,
    callback?: msRest.ServiceCallback<Models.ApplicantProjection>
  ): Promise<Models.GetApplicantResponse> {
    return this.sendOperationRequest(
      {
        applicantId,
        options
      },
      getApplicantOperationSpec,
      callback
    ) as Promise<Models.GetApplicantResponse>;
  }

  /**
   * @param applicantId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicantDriverLicenceImageResponse>
   */
  getApplicantDriverLicenceImage(
    applicantId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicantDriverLicenceImageResponse>;
  /**
   * @param applicantId
   * @param callback The callback
   */
  getApplicantDriverLicenceImage(applicantId: string, callback: msRest.ServiceCallback<Uint8Array>): void;
  /**
   * @param applicantId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicantDriverLicenceImage(
    applicantId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Uint8Array>
  ): void;
  getApplicantDriverLicenceImage(
    applicantId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Uint8Array>,
    callback?: msRest.ServiceCallback<Uint8Array>
  ): Promise<Models.GetApplicantDriverLicenceImageResponse> {
    return this.sendOperationRequest(
      {
        applicantId,
        options
      },
      getApplicantDriverLicenceImageOperationSpec,
      callback
    ) as Promise<Models.GetApplicantDriverLicenceImageResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSecondaryApplicantResponse>
   */
  getSecondaryApplicant(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetSecondaryApplicantResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getSecondaryApplicant(applicationId: string, callback: msRest.ServiceCallback<Models.SecondaryApplicant>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSecondaryApplicant(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.SecondaryApplicant>
  ): void;
  getSecondaryApplicant(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SecondaryApplicant>,
    callback?: msRest.ServiceCallback<Models.SecondaryApplicant>
  ): Promise<Models.GetSecondaryApplicantResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getSecondaryApplicantOperationSpec,
      callback
    ) as Promise<Models.GetSecondaryApplicantResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetOrganisationExtraDetailsResponse>
   */
  getOrganisationExtraDetails(
    options?: Models.OriginationReadApiGetOrganisationExtraDetailsOptionalParams
  ): Promise<Models.GetOrganisationExtraDetailsResponse>;
  /**
   * @param callback The callback
   */
  getOrganisationExtraDetails(callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getOrganisationExtraDetails(
    options: Models.OriginationReadApiGetOrganisationExtraDetailsOptionalParams,
    callback: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): void;
  getOrganisationExtraDetails(
    options?:
      | Models.OriginationReadApiGetOrganisationExtraDetailsOptionalParams
      | msRest.ServiceCallback<{ [propertyName: string]: string }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): Promise<Models.GetOrganisationExtraDetailsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getOrganisationExtraDetailsOperationSpec,
      callback
    ) as Promise<Models.GetOrganisationExtraDetailsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ValidateSecondaryEmailResponse>
   */
  validateSecondaryEmail(
    options?: Models.OriginationReadApiValidateSecondaryEmailOptionalParams
  ): Promise<Models.ValidateSecondaryEmailResponse>;
  /**
   * @param callback The callback
   */
  validateSecondaryEmail(callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  validateSecondaryEmail(
    options: Models.OriginationReadApiValidateSecondaryEmailOptionalParams,
    callback: msRest.ServiceCallback<Models.BoolWrapper>
  ): void;
  validateSecondaryEmail(
    options?:
      | Models.OriginationReadApiValidateSecondaryEmailOptionalParams
      | msRest.ServiceCallback<Models.BoolWrapper>,
    callback?: msRest.ServiceCallback<Models.BoolWrapper>
  ): Promise<Models.ValidateSecondaryEmailResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      validateSecondaryEmailOperationSpec,
      callback
    ) as Promise<Models.ValidateSecondaryEmailResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ValidateSecondaryMobileResponse>
   */
  validateSecondaryMobile(
    options?: Models.OriginationReadApiValidateSecondaryMobileOptionalParams
  ): Promise<Models.ValidateSecondaryMobileResponse>;
  /**
   * @param callback The callback
   */
  validateSecondaryMobile(callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  validateSecondaryMobile(
    options: Models.OriginationReadApiValidateSecondaryMobileOptionalParams,
    callback: msRest.ServiceCallback<Models.BoolWrapper>
  ): void;
  validateSecondaryMobile(
    options?:
      | Models.OriginationReadApiValidateSecondaryMobileOptionalParams
      | msRest.ServiceCallback<Models.BoolWrapper>,
    callback?: msRest.ServiceCallback<Models.BoolWrapper>
  ): Promise<Models.ValidateSecondaryMobileResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      validateSecondaryMobileOperationSpec,
      callback
    ) as Promise<Models.ValidateSecondaryMobileResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsApplicantAddressValidResponse>
   */
  isApplicantAddressValid(
    options?: Models.OriginationReadApiIsApplicantAddressValidOptionalParams
  ): Promise<Models.IsApplicantAddressValidResponse>;
  /**
   * @param callback The callback
   */
  isApplicantAddressValid(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isApplicantAddressValid(
    options: Models.OriginationReadApiIsApplicantAddressValidOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isApplicantAddressValid(
    options?: Models.OriginationReadApiIsApplicantAddressValidOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsApplicantAddressValidResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isApplicantAddressValidOperationSpec,
      callback
    ) as Promise<Models.IsApplicantAddressValidResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsNotAllowedAddressResponse>
   */
  isNotAllowedAddress(
    options?: Models.OriginationReadApiIsNotAllowedAddressOptionalParams
  ): Promise<Models.IsNotAllowedAddressResponse>;
  /**
   * @param callback The callback
   */
  isNotAllowedAddress(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isNotAllowedAddress(
    options: Models.OriginationReadApiIsNotAllowedAddressOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isNotAllowedAddress(
    options?: Models.OriginationReadApiIsNotAllowedAddressOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsNotAllowedAddressResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isNotAllowedAddressOperationSpec,
      callback
    ) as Promise<Models.IsNotAllowedAddressResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsExistingCustomerEmailResponse>
   */
  isExistingCustomerEmail(
    options?: Models.OriginationReadApiIsExistingCustomerEmailOptionalParams
  ): Promise<Models.IsExistingCustomerEmailResponse>;
  /**
   * @param callback The callback
   */
  isExistingCustomerEmail(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isExistingCustomerEmail(
    options: Models.OriginationReadApiIsExistingCustomerEmailOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isExistingCustomerEmail(
    options?: Models.OriginationReadApiIsExistingCustomerEmailOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsExistingCustomerEmailResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isExistingCustomerEmailOperationSpec,
      callback
    ) as Promise<Models.IsExistingCustomerEmailResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsExistingCustomerPhoneResponse>
   */
  isExistingCustomerPhone(
    options?: Models.OriginationReadApiIsExistingCustomerPhoneOptionalParams
  ): Promise<Models.IsExistingCustomerPhoneResponse>;
  /**
   * @param callback The callback
   */
  isExistingCustomerPhone(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isExistingCustomerPhone(
    options: Models.OriginationReadApiIsExistingCustomerPhoneOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isExistingCustomerPhone(
    options?: Models.OriginationReadApiIsExistingCustomerPhoneOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsExistingCustomerPhoneResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isExistingCustomerPhoneOperationSpec,
      callback
    ) as Promise<Models.IsExistingCustomerPhoneResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllCancelReasonsResponse>
   */
  getAllCancelReasons(options?: msRest.RequestOptionsBase): Promise<Models.GetAllCancelReasonsResponse>;
  /**
   * @param callback The callback
   */
  getAllCancelReasons(callback: msRest.ServiceCallback<Models.CancelReason[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllCancelReasons(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CancelReason[]>
  ): void;
  getAllCancelReasons(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CancelReason[]>,
    callback?: msRest.ServiceCallback<Models.CancelReason[]>
  ): Promise<Models.GetAllCancelReasonsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllCancelReasonsOperationSpec,
      callback
    ) as Promise<Models.GetAllCancelReasonsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllPartnerApplicationStatusTypesResponse>
   */
  getAllPartnerApplicationStatusTypes(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllPartnerApplicationStatusTypesResponse>;
  /**
   * @param callback The callback
   */
  getAllPartnerApplicationStatusTypes(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllPartnerApplicationStatusTypes(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getAllPartnerApplicationStatusTypes(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetAllPartnerApplicationStatusTypesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllPartnerApplicationStatusTypesOperationSpec,
      callback
    ) as Promise<Models.GetAllPartnerApplicationStatusTypesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerApplicationStatusResponse>
   */
  getPartnerApplicationStatus(
    options?: Models.OriginationReadApiGetPartnerApplicationStatusOptionalParams
  ): Promise<Models.GetPartnerApplicationStatusResponse>;
  /**
   * @param callback The callback
   */
  getPartnerApplicationStatus(callback: msRest.ServiceCallback<Models.PartnerApplicationStatus>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerApplicationStatus(
    options: Models.OriginationReadApiGetPartnerApplicationStatusOptionalParams,
    callback: msRest.ServiceCallback<Models.PartnerApplicationStatus>
  ): void;
  getPartnerApplicationStatus(
    options?:
      | Models.OriginationReadApiGetPartnerApplicationStatusOptionalParams
      | msRest.ServiceCallback<Models.PartnerApplicationStatus>,
    callback?: msRest.ServiceCallback<Models.PartnerApplicationStatus>
  ): Promise<Models.GetPartnerApplicationStatusResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerApplicationStatusOperationSpec,
      callback
    ) as Promise<Models.GetPartnerApplicationStatusResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.InDraftResponse>
   */
  inDraft(options?: Models.OriginationReadApiInDraftOptionalParams): Promise<Models.InDraftResponse>;
  /**
   * @param callback The callback
   */
  inDraft(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  inDraft(options: Models.OriginationReadApiInDraftOptionalParams, callback: msRest.ServiceCallback<string[]>): void;
  inDraft(
    options?: Models.OriginationReadApiInDraftOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.InDraftResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      inDraftOperationSpec,
      callback
    ) as Promise<Models.InDraftResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OverviewResponse>
   */
  overview(options?: Models.OriginationReadApiOverviewOptionalParams): Promise<Models.OverviewResponse>;
  /**
   * @param callback The callback
   */
  overview(callback: msRest.ServiceCallback<Models.ApplicationsOverview>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  overview(
    options: Models.OriginationReadApiOverviewOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationsOverview>
  ): void;
  overview(
    options?: Models.OriginationReadApiOverviewOptionalParams | msRest.ServiceCallback<Models.ApplicationsOverview>,
    callback?: msRest.ServiceCallback<Models.ApplicationsOverview>
  ): Promise<Models.OverviewResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      overviewOperationSpec,
      callback
    ) as Promise<Models.OverviewResponse>;
  }

  /**
   * @param otp
   * @param [options] The optional parameters
   * @returns Promise<Models.ValidateOtpResponse>
   */
  validateOtp(otp: string, options?: msRest.RequestOptionsBase): Promise<Models.ValidateOtpResponse>;
  /**
   * @param otp
   * @param callback The callback
   */
  validateOtp(otp: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param otp
   * @param options The optional parameters
   * @param callback The callback
   */
  validateOtp(
    otp: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BoolWrapper>
  ): void;
  validateOtp(
    otp: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>,
    callback?: msRest.ServiceCallback<Models.BoolWrapper>
  ): Promise<Models.ValidateOtpResponse> {
    return this.sendOperationRequest(
      {
        otp,
        options
      },
      validateOtpOperationSpec,
      callback
    ) as Promise<Models.ValidateOtpResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.ApplicationStatusResponse>
   */
  applicationStatus(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.ApplicationStatusResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  applicationStatus(applicationId: string, callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationStatus(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>
  ): void;
  applicationStatus(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: boolean }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: boolean }>
  ): Promise<Models.ApplicationStatusResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      applicationStatusOperationSpec,
      callback
    ) as Promise<Models.ApplicationStatusResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAlertResponse>
   */
  getAlert(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAlertResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getAlert(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAlert(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getAlert(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetAlertResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getAlertOperationSpec,
      callback
    ) as Promise<Models.GetAlertResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  testEquifaxAlert(options?: Models.OriginationReadApiTestEquifaxAlertOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  testEquifaxAlert(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  testEquifaxAlert(
    options: Models.OriginationReadApiTestEquifaxAlertOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  testEquifaxAlert(
    options?: Models.OriginationReadApiTestEquifaxAlertOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      testEquifaxAlertOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAlertsResponse>
   */
  getAlerts(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAlertsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getAlerts(applicationId: string, callback: msRest.ServiceCallback<Models.Alert[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAlerts(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Alert[]>
  ): void;
  getAlerts(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Alert[]>,
    callback?: msRest.ServiceCallback<Models.Alert[]>
  ): Promise<Models.GetAlertsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getAlertsOperationSpec,
      callback
    ) as Promise<Models.GetAlertsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.TestAlertResponse>
   */
  testAlert(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.TestAlertResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  testAlert(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  testAlert(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  testAlert(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.TestAlertResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      testAlertOperationSpec,
      callback
    ) as Promise<Models.TestAlertResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TestAllAlertResponse>
   */
  testAllAlert(options?: msRest.RequestOptionsBase): Promise<Models.TestAllAlertResponse>;
  /**
   * @param callback The callback
   */
  testAllAlert(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  testAllAlert(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  testAllAlert(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.TestAllAlertResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      testAllAlertOperationSpec,
      callback
    ) as Promise<Models.TestAllAlertResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.ApplicationConnectionStatusResponse>
   */
  applicationConnectionStatus(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.ApplicationConnectionStatusResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  applicationConnectionStatus(
    applicationId: string,
    callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationConnectionStatus(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>
  ): void;
  applicationConnectionStatus(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: boolean }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: boolean }>
  ): Promise<Models.ApplicationConnectionStatusResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      applicationConnectionStatusOperationSpec,
      callback
    ) as Promise<Models.ApplicationConnectionStatusResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.HasOrganisationNameBeenFinalisedResponse>
   */
  hasOrganisationNameBeenFinalised(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.HasOrganisationNameBeenFinalisedResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  hasOrganisationNameBeenFinalised(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  hasOrganisationNameBeenFinalised(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  hasOrganisationNameBeenFinalised(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.HasOrganisationNameBeenFinalisedResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      hasOrganisationNameBeenFinalisedOperationSpec,
      callback
    ) as Promise<Models.HasOrganisationNameBeenFinalisedResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsOfferReadyResponse>
   */
  isOfferReady(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsOfferReadyResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isOfferReady(applicationId: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isOfferReady(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BoolWrapper>
  ): void;
  isOfferReady(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>,
    callback?: msRest.ServiceCallback<Models.BoolWrapper>
  ): Promise<Models.IsOfferReadyResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isOfferReadyOperationSpec,
      callback
    ) as Promise<Models.IsOfferReadyResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.HasLoggedInResponse>
   */
  hasLoggedIn(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.HasLoggedInResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  hasLoggedIn(applicationId: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  hasLoggedIn(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BoolWrapper>
  ): void;
  hasLoggedIn(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>,
    callback?: msRest.ServiceCallback<Models.BoolWrapper>
  ): Promise<Models.HasLoggedInResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      hasLoggedInOperationSpec,
      callback
    ) as Promise<Models.HasLoggedInResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadContractFileResponse>
   */
  downloadContractFile(
    applicationId: string,
    options?: Models.OriginationReadApiDownloadContractFileOptionalParams
  ): Promise<Models.DownloadContractFileResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  downloadContractFile(applicationId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadContractFile(
    applicationId: string,
    options: Models.OriginationReadApiDownloadContractFileOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  downloadContractFile(
    applicationId: string,
    options?:
      | Models.OriginationReadApiDownloadContractFileOptionalParams
      | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.DownloadContractFileResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      downloadContractFileOperationSpec,
      callback
    ) as Promise<Models.DownloadContractFileResponse>;
  }

  /**
   * @param objectType
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDataResponse>
   */
  getData(
    objectType: string,
    id: string,
    options?: Models.OriginationReadApiGetDataOptionalParams
  ): Promise<Models.GetDataResponse>;
  /**
   * @param objectType
   * @param id
   * @param callback The callback
   */
  getData(objectType: string, id: string, callback: msRest.ServiceCallback<Models.Summary>): void;
  /**
   * @param objectType
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getData(
    objectType: string,
    id: string,
    options: Models.OriginationReadApiGetDataOptionalParams,
    callback: msRest.ServiceCallback<Models.Summary>
  ): void;
  getData(
    objectType: string,
    id: string,
    options?: Models.OriginationReadApiGetDataOptionalParams | msRest.ServiceCallback<Models.Summary>,
    callback?: msRest.ServiceCallback<Models.Summary>
  ): Promise<Models.GetDataResponse> {
    return this.sendOperationRequest(
      {
        objectType,
        id,
        options
      },
      getDataOperationSpec,
      callback
    ) as Promise<Models.GetDataResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCommissionDetailsResponse>
   */
  getCommissionDetails(
    applicationId: string,
    options?: Models.OriginationReadApiGetCommissionDetailsOptionalParams
  ): Promise<Models.GetCommissionDetailsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getCommissionDetails(applicationId: string, callback: msRest.ServiceCallback<Models.CommissionDetails>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCommissionDetails(
    applicationId: string,
    options: Models.OriginationReadApiGetCommissionDetailsOptionalParams,
    callback: msRest.ServiceCallback<Models.CommissionDetails>
  ): void;
  getCommissionDetails(
    applicationId: string,
    options?:
      | Models.OriginationReadApiGetCommissionDetailsOptionalParams
      | msRest.ServiceCallback<Models.CommissionDetails>,
    callback?: msRest.ServiceCallback<Models.CommissionDetails>
  ): Promise<Models.GetCommissionDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getCommissionDetailsOperationSpec,
      callback
    ) as Promise<Models.GetCommissionDetailsResponse>;
  }

  /**
   * @param shortCode
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationIdOnShortCodeResponse>
   */
  getApplicationIdOnShortCode(
    shortCode: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationIdOnShortCodeResponse>;
  /**
   * @param shortCode
   * @param callback The callback
   */
  getApplicationIdOnShortCode(shortCode: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param shortCode
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationIdOnShortCode(
    shortCode: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getApplicationIdOnShortCode(
    shortCode: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetApplicationIdOnShortCodeResponse> {
    return this.sendOperationRequest(
      {
        shortCode,
        options
      },
      getApplicationIdOnShortCodeOperationSpec,
      callback
    ) as Promise<Models.GetApplicationIdOnShortCodeResponse>;
  }

  /**
   * @param shortCode
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTokenResponse>
   */
  getToken(shortCode: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTokenResponse>;
  /**
   * @param shortCode
   * @param callback The callback
   */
  getToken(shortCode: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param shortCode
   * @param options The optional parameters
   * @param callback The callback
   */
  getToken(shortCode: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getToken(
    shortCode: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetTokenResponse> {
    return this.sendOperationRequest(
      {
        shortCode,
        options
      },
      getTokenOperationSpec,
      callback
    ) as Promise<Models.GetTokenResponse>;
  }

  /**
   * @param shortCode
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationOnShortCodeResponse>
   */
  getApplicationOnShortCode(
    shortCode: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationOnShortCodeResponse>;
  /**
   * @param shortCode
   * @param callback The callback
   */
  getApplicationOnShortCode(
    shortCode: string,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): void;
  /**
   * @param shortCode
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationOnShortCode(
    shortCode: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): void;
  getApplicationOnShortCode(
    shortCode: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationListProjection>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): Promise<Models.GetApplicationOnShortCodeResponse> {
    return this.sendOperationRequest(
      {
        shortCode,
        options
      },
      getApplicationOnShortCodeOperationSpec,
      callback
    ) as Promise<Models.GetApplicationOnShortCodeResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetShortCodeOnApplicationIdResponse>
   */
  getShortCodeOnApplicationId(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetShortCodeOnApplicationIdResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getShortCodeOnApplicationId(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getShortCodeOnApplicationId(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getShortCodeOnApplicationId(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetShortCodeOnApplicationIdResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getShortCodeOnApplicationIdOperationSpec,
      callback
    ) as Promise<Models.GetShortCodeOnApplicationIdResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetQuickUrlResponse>
   */
  getQuickUrl(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetQuickUrlResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getQuickUrl(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getQuickUrl(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getQuickUrl(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetQuickUrlResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getQuickUrlOperationSpec,
      callback
    ) as Promise<Models.GetQuickUrlResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSecondApplicantUrlResponse>
   */
  getSecondApplicantUrl(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetSecondApplicantUrlResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getSecondApplicantUrl(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSecondApplicantUrl(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getSecondApplicantUrl(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetSecondApplicantUrlResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getSecondApplicantUrlOperationSpec,
      callback
    ) as Promise<Models.GetSecondApplicantUrlResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBaseUrlResponse>
   */
  getBaseUrl(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetBaseUrlResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBaseUrl(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBaseUrl(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getBaseUrl(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetBaseUrlResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBaseUrlOperationSpec,
      callback
    ) as Promise<Models.GetBaseUrlResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationListResponse>
   */
  getApplicationList(
    options?: Models.OriginationReadApiGetApplicationListOptionalParams
  ): Promise<Models.GetApplicationListResponse>;
  /**
   * @param callback The callback
   */
  getApplicationList(callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationList(
    options: Models.OriginationReadApiGetApplicationListOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  getApplicationList(
    options?:
      | Models.OriginationReadApiGetApplicationListOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): Promise<Models.GetApplicationListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getApplicationListOperationSpec,
      callback
    ) as Promise<Models.GetApplicationListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerApplicationListResponse>
   */
  getPartnerApplicationList(
    options?: Models.OriginationReadApiGetPartnerApplicationListOptionalParams
  ): Promise<Models.GetPartnerApplicationListResponse>;
  /**
   * @param callback The callback
   */
  getPartnerApplicationList(callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerApplicationList(
    options: Models.OriginationReadApiGetPartnerApplicationListOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  getPartnerApplicationList(
    options?:
      | Models.OriginationReadApiGetPartnerApplicationListOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): Promise<Models.GetPartnerApplicationListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerApplicationListOperationSpec,
      callback
    ) as Promise<Models.GetPartnerApplicationListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCompleteApplicationsResponse>
   */
  getCompleteApplications(
    options?: Models.OriginationReadApiGetCompleteApplicationsOptionalParams
  ): Promise<Models.GetCompleteApplicationsResponse>;
  /**
   * @param callback The callback
   */
  getCompleteApplications(callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCompleteApplications(
    options: Models.OriginationReadApiGetCompleteApplicationsOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  getCompleteApplications(
    options?:
      | Models.OriginationReadApiGetCompleteApplicationsOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): Promise<Models.GetCompleteApplicationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCompleteApplicationsOperationSpec,
      callback
    ) as Promise<Models.GetCompleteApplicationsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerCancelledApplicationsResponse>
   */
  getPartnerCancelledApplications(
    options?: Models.OriginationReadApiGetPartnerCancelledApplicationsOptionalParams
  ): Promise<Models.GetPartnerCancelledApplicationsResponse>;
  /**
   * @param callback The callback
   */
  getPartnerCancelledApplications(
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerCancelledApplications(
    options: Models.OriginationReadApiGetPartnerCancelledApplicationsOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  getPartnerCancelledApplications(
    options?:
      | Models.OriginationReadApiGetPartnerCancelledApplicationsOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): Promise<Models.GetPartnerCancelledApplicationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerCancelledApplicationsOperationSpec,
      callback
    ) as Promise<Models.GetPartnerCancelledApplicationsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerCompletedApplicationsResponse>
   */
  getPartnerCompletedApplications(
    options?: Models.OriginationReadApiGetPartnerCompletedApplicationsOptionalParams
  ): Promise<Models.GetPartnerCompletedApplicationsResponse>;
  /**
   * @param callback The callback
   */
  getPartnerCompletedApplications(
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerCompletedApplications(
    options: Models.OriginationReadApiGetPartnerCompletedApplicationsOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  getPartnerCompletedApplications(
    options?:
      | Models.OriginationReadApiGetPartnerCompletedApplicationsOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): Promise<Models.GetPartnerCompletedApplicationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerCompletedApplicationsOperationSpec,
      callback
    ) as Promise<Models.GetPartnerCompletedApplicationsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerAllApplicationsResponse>
   */
  getPartnerAllApplications(
    options?: Models.OriginationReadApiGetPartnerAllApplicationsOptionalParams
  ): Promise<Models.GetPartnerAllApplicationsResponse>;
  /**
   * @param callback The callback
   */
  getPartnerAllApplications(callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerAllApplications(
    options: Models.OriginationReadApiGetPartnerAllApplicationsOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  getPartnerAllApplications(
    options?:
      | Models.OriginationReadApiGetPartnerAllApplicationsOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): Promise<Models.GetPartnerAllApplicationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerAllApplicationsOperationSpec,
      callback
    ) as Promise<Models.GetPartnerAllApplicationsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentCancelledApplicationsResponse>
   */
  getAgentCancelledApplications(
    options?: Models.OriginationReadApiGetAgentCancelledApplicationsOptionalParams
  ): Promise<Models.GetAgentCancelledApplicationsResponse>;
  /**
   * @param callback The callback
   */
  getAgentCancelledApplications(
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentCancelledApplications(
    options: Models.OriginationReadApiGetAgentCancelledApplicationsOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  getAgentCancelledApplications(
    options?:
      | Models.OriginationReadApiGetAgentCancelledApplicationsOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): Promise<Models.GetAgentCancelledApplicationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAgentCancelledApplicationsOperationSpec,
      callback
    ) as Promise<Models.GetAgentCancelledApplicationsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentCompletedApplicationsResponse>
   */
  getAgentCompletedApplications(
    options?: Models.OriginationReadApiGetAgentCompletedApplicationsOptionalParams
  ): Promise<Models.GetAgentCompletedApplicationsResponse>;
  /**
   * @param callback The callback
   */
  getAgentCompletedApplications(
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentCompletedApplications(
    options: Models.OriginationReadApiGetAgentCompletedApplicationsOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  getAgentCompletedApplications(
    options?:
      | Models.OriginationReadApiGetAgentCompletedApplicationsOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): Promise<Models.GetAgentCompletedApplicationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAgentCompletedApplicationsOperationSpec,
      callback
    ) as Promise<Models.GetAgentCompletedApplicationsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentAllApplicationsResponse>
   */
  getAgentAllApplications(
    options?: Models.OriginationReadApiGetAgentAllApplicationsOptionalParams
  ): Promise<Models.GetAgentAllApplicationsResponse>;
  /**
   * @param callback The callback
   */
  getAgentAllApplications(callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentAllApplications(
    options: Models.OriginationReadApiGetAgentAllApplicationsOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): void;
  getAgentAllApplications(
    options?:
      | Models.OriginationReadApiGetAgentAllApplicationsOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjectionExtensionPagedList>
  ): Promise<Models.GetAgentAllApplicationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAgentAllApplicationsOperationSpec,
      callback
    ) as Promise<Models.GetAgentAllApplicationsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBusinessNameFromApplicationResponse>
   */
  getBusinessNameFromApplication(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetBusinessNameFromApplicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBusinessNameFromApplication(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBusinessNameFromApplication(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getBusinessNameFromApplication(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetBusinessNameFromApplicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBusinessNameFromApplicationOperationSpec,
      callback
    ) as Promise<Models.GetBusinessNameFromApplicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankfeedAccountsResponse>
   */
  getBankfeedAccounts(
    applicationId: string,
    options?: Models.OriginationReadApiGetBankfeedAccountsOptionalParams
  ): Promise<Models.GetBankfeedAccountsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBankfeedAccounts(applicationId: string, callback: msRest.ServiceCallback<Models.BankAccount[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankfeedAccounts(
    applicationId: string,
    options: Models.OriginationReadApiGetBankfeedAccountsOptionalParams,
    callback: msRest.ServiceCallback<Models.BankAccount[]>
  ): void;
  getBankfeedAccounts(
    applicationId: string,
    options?: Models.OriginationReadApiGetBankfeedAccountsOptionalParams | msRest.ServiceCallback<Models.BankAccount[]>,
    callback?: msRest.ServiceCallback<Models.BankAccount[]>
  ): Promise<Models.GetBankfeedAccountsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBankfeedAccountsOperationSpec,
      callback
    ) as Promise<Models.GetBankfeedAccountsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankfeedMFADetailsResponse>
   */
  getBankfeedMFADetails(
    applicationId: string,
    options?: Models.OriginationReadApiGetBankfeedMFADetailsOptionalParams
  ): Promise<Models.GetBankfeedMFADetailsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBankfeedMFADetails(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsMFADetails>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankfeedMFADetails(
    applicationId: string,
    options: Models.OriginationReadApiGetBankfeedMFADetailsOptionalParams,
    callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsMFADetails>
  ): void;
  getBankfeedMFADetails(
    applicationId: string,
    options?:
      | Models.OriginationReadApiGetBankfeedMFADetailsOptionalParams
      | msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsMFADetails>,
    callback?: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsMFADetails>
  ): Promise<Models.GetBankfeedMFADetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBankfeedMFADetailsOperationSpec,
      callback
    ) as Promise<Models.GetBankfeedMFADetailsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankfeedFaskLinkResponse>
   */
  getBankfeedFaskLink(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetBankfeedFaskLinkResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBankfeedFaskLink(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankfeedFaskLink(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>
  ): void;
  getBankfeedFaskLink(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>,
    callback?: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>
  ): Promise<Models.GetBankfeedFaskLinkResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBankfeedFaskLinkOperationSpec,
      callback
    ) as Promise<Models.GetBankfeedFaskLinkResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankfeedPlaidLinkResponse>
   */
  getBankfeedPlaidLink(
    applicationId: string,
    options?: Models.OriginationReadApiGetBankfeedPlaidLinkOptionalParams
  ): Promise<Models.GetBankfeedPlaidLinkResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBankfeedPlaidLink(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankfeedPlaidLink(
    applicationId: string,
    options: Models.OriginationReadApiGetBankfeedPlaidLinkOptionalParams,
    callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>
  ): void;
  getBankfeedPlaidLink(
    applicationId: string,
    options?:
      | Models.OriginationReadApiGetBankfeedPlaidLinkOptionalParams
      | msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>,
    callback?: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>
  ): Promise<Models.GetBankfeedPlaidLinkResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBankfeedPlaidLinkOperationSpec,
      callback
    ) as Promise<Models.GetBankfeedPlaidLinkResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsProvisoApplicationResponse>
   */
  isProvisoApplication(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsProvisoApplicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isProvisoApplication(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isProvisoApplication(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isProvisoApplication(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsProvisoApplicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isProvisoApplicationOperationSpec,
      callback
    ) as Promise<Models.IsProvisoApplicationResponse>;
  }

  /**
   * @param applicationId
   * @param offerId
   * @param [options] The optional parameters
   * @returns Promise<Models.OfferResponse>
   */
  offer(applicationId: string, offerId: string, options?: msRest.RequestOptionsBase): Promise<Models.OfferResponse>;
  /**
   * @param applicationId
   * @param offerId
   * @param callback The callback
   */
  offer(applicationId: string, offerId: string, callback: msRest.ServiceCallback<Models.OfferAmount>): void;
  /**
   * @param applicationId
   * @param offerId
   * @param options The optional parameters
   * @param callback The callback
   */
  offer(
    applicationId: string,
    offerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.OfferAmount>
  ): void;
  offer(
    applicationId: string,
    offerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.OfferAmount>,
    callback?: msRest.ServiceCallback<Models.OfferAmount>
  ): Promise<Models.OfferResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        offerId,
        options
      },
      offerOperationSpec,
      callback
    ) as Promise<Models.OfferResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationSettingsResponse>
   */
  getApplicationSettings(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationSettingsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getApplicationSettings(applicationId: string, callback: msRest.ServiceCallback<Models.ApplicationSettings>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationSettings(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicationSettings>
  ): void;
  getApplicationSettings(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationSettings>,
    callback?: msRest.ServiceCallback<Models.ApplicationSettings>
  ): Promise<Models.GetApplicationSettingsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getApplicationSettingsOperationSpec,
      callback
    ) as Promise<Models.GetApplicationSettingsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCommunicationResponse>
   */
  getCommunication(
    applicationId: string,
    options?: Models.OriginationReadApiGetCommunicationOptionalParams
  ): Promise<Models.GetCommunicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getCommunication(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCommunication(
    applicationId: string,
    options: Models.OriginationReadApiGetCommunicationOptionalParams,
    callback: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): void;
  getCommunication(
    applicationId: string,
    options?:
      | Models.OriginationReadApiGetCommunicationOptionalParams
      | msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>,
    callback?: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): Promise<Models.GetCommunicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getCommunicationOperationSpec,
      callback
    ) as Promise<Models.GetCommunicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsNewCommunicationResponse>
   */
  isNewCommunication(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsNewCommunicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isNewCommunication(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isNewCommunication(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isNewCommunication(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsNewCommunicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isNewCommunicationOperationSpec,
      callback
    ) as Promise<Models.IsNewCommunicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.CommissionMethodResponse>
   */
  commissionMethod(
    applicationId: string,
    options?: Models.OriginationReadApiCommissionMethodOptionalParams
  ): Promise<Models.CommissionMethodResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  commissionMethod(applicationId: string, callback: msRest.ServiceCallback<Models.Commission>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  commissionMethod(
    applicationId: string,
    options: Models.OriginationReadApiCommissionMethodOptionalParams,
    callback: msRest.ServiceCallback<Models.Commission>
  ): void;
  commissionMethod(
    applicationId: string,
    options?: Models.OriginationReadApiCommissionMethodOptionalParams | msRest.ServiceCallback<Models.Commission>,
    callback?: msRest.ServiceCallback<Models.Commission>
  ): Promise<Models.CommissionMethodResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      commissionMethodOperationSpec,
      callback
    ) as Promise<Models.CommissionMethodResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationResponse>
   */
  getApplication(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getApplication(applicationId: string, callback: msRest.ServiceCallback<Models.ApplicationListProjection>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplication(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): void;
  getApplication(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationListProjection>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): Promise<Models.GetApplicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getApplicationOperationSpec,
      callback
    ) as Promise<Models.GetApplicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DebtorSummaryOnApplicationIdResponse>
   */
  debtorSummaryOnApplicationId(
    applicationId: string,
    options?: Models.OriginationReadApiDebtorSummaryOnApplicationIdOptionalParams
  ): Promise<Models.DebtorSummaryOnApplicationIdResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  debtorSummaryOnApplicationId(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.LoanDebtorProjectionPagedList>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  debtorSummaryOnApplicationId(
    applicationId: string,
    options: Models.OriginationReadApiDebtorSummaryOnApplicationIdOptionalParams,
    callback: msRest.ServiceCallback<Models.LoanDebtorProjectionPagedList>
  ): void;
  debtorSummaryOnApplicationId(
    applicationId: string,
    options?:
      | Models.OriginationReadApiDebtorSummaryOnApplicationIdOptionalParams
      | msRest.ServiceCallback<Models.LoanDebtorProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.LoanDebtorProjectionPagedList>
  ): Promise<Models.DebtorSummaryOnApplicationIdResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      debtorSummaryOnApplicationIdOperationSpec,
      callback
    ) as Promise<Models.DebtorSummaryOnApplicationIdResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateIndicativeFixedRepaymentResponse>
   */
  calculateIndicativeFixedRepayment(
    applicationId: string,
    options?: Models.OriginationReadApiCalculateIndicativeFixedRepaymentOptionalParams
  ): Promise<Models.CalculateIndicativeFixedRepaymentResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  calculateIndicativeFixedRepayment(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.FixedRepaymentCalculation>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateIndicativeFixedRepayment(
    applicationId: string,
    options: Models.OriginationReadApiCalculateIndicativeFixedRepaymentOptionalParams,
    callback: msRest.ServiceCallback<Models.FixedRepaymentCalculation>
  ): void;
  calculateIndicativeFixedRepayment(
    applicationId: string,
    options?:
      | Models.OriginationReadApiCalculateIndicativeFixedRepaymentOptionalParams
      | msRest.ServiceCallback<Models.FixedRepaymentCalculation>,
    callback?: msRest.ServiceCallback<Models.FixedRepaymentCalculation>
  ): Promise<Models.CalculateIndicativeFixedRepaymentResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      calculateIndicativeFixedRepaymentOperationSpec,
      callback
    ) as Promise<Models.CalculateIndicativeFixedRepaymentResponse>;
  }

  /**
   * @param applicationId
   * @param amount
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateIndicativeLineOfCreditSliderResponse>
   */
  calculateIndicativeLineOfCreditSlider(
    applicationId: string,
    amount: number,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.CalculateIndicativeLineOfCreditSliderResponse>;
  /**
   * @param applicationId
   * @param amount
   * @param callback The callback
   */
  calculateIndicativeLineOfCreditSlider(
    applicationId: string,
    amount: number,
    callback: msRest.ServiceCallback<Models.SliderResult[]>
  ): void;
  /**
   * @param applicationId
   * @param amount
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateIndicativeLineOfCreditSlider(
    applicationId: string,
    amount: number,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.SliderResult[]>
  ): void;
  calculateIndicativeLineOfCreditSlider(
    applicationId: string,
    amount: number,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SliderResult[]>,
    callback?: msRest.ServiceCallback<Models.SliderResult[]>
  ): Promise<Models.CalculateIndicativeLineOfCreditSliderResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        amount,
        options
      },
      calculateIndicativeLineOfCreditSliderOperationSpec,
      callback
    ) as Promise<Models.CalculateIndicativeLineOfCreditSliderResponse>;
  }

  /**
   * @param applicationId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateIndicativeLineOfCreditRepaymentResponse>
   */
  calculateIndicativeLineOfCreditRepayment(
    applicationId: string,
    amount: number,
    percentageOfIncome: number,
    fixedRepaymentCalculation: boolean,
    term: number,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.CalculateIndicativeLineOfCreditRepaymentResponse>;
  /**
   * @param applicationId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param callback The callback
   */
  calculateIndicativeLineOfCreditRepayment(
    applicationId: string,
    amount: number,
    percentageOfIncome: number,
    fixedRepaymentCalculation: boolean,
    term: number,
    callback: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>
  ): void;
  /**
   * @param applicationId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateIndicativeLineOfCreditRepayment(
    applicationId: string,
    amount: number,
    percentageOfIncome: number,
    fixedRepaymentCalculation: boolean,
    term: number,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>
  ): void;
  calculateIndicativeLineOfCreditRepayment(
    applicationId: string,
    amount: number,
    percentageOfIncome: number,
    fixedRepaymentCalculation: boolean,
    term: number,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>,
    callback?: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>
  ): Promise<Models.CalculateIndicativeLineOfCreditRepaymentResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        amount,
        percentageOfIncome,
        fixedRepaymentCalculation,
        term,
        options
      },
      calculateIndicativeLineOfCreditRepaymentOperationSpec,
      callback
    ) as Promise<Models.CalculateIndicativeLineOfCreditRepaymentResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateIndicativePercentOfIncomeResponse>
   */
  calculateIndicativePercentOfIncome(
    applicationId: string,
    options?: Models.OriginationReadApiCalculateIndicativePercentOfIncomeOptionalParams
  ): Promise<Models.CalculateIndicativePercentOfIncomeResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  calculateIndicativePercentOfIncome(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateIndicativePercentOfIncome(
    applicationId: string,
    options: Models.OriginationReadApiCalculateIndicativePercentOfIncomeOptionalParams,
    callback: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>
  ): void;
  calculateIndicativePercentOfIncome(
    applicationId: string,
    options?:
      | Models.OriginationReadApiCalculateIndicativePercentOfIncomeOptionalParams
      | msRest.ServiceCallback<Models.PercentOfIncomeCalculation>,
    callback?: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>
  ): Promise<Models.CalculateIndicativePercentOfIncomeResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      calculateIndicativePercentOfIncomeOperationSpec,
      callback
    ) as Promise<Models.CalculateIndicativePercentOfIncomeResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateIndicativeInvoicePaymentResponse>
   */
  calculateIndicativeInvoicePayment(
    applicationId: string,
    options?: Models.OriginationReadApiCalculateIndicativeInvoicePaymentOptionalParams
  ): Promise<Models.CalculateIndicativeInvoicePaymentResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  calculateIndicativeInvoicePayment(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.InvoicePaymentCalculation>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateIndicativeInvoicePayment(
    applicationId: string,
    options: Models.OriginationReadApiCalculateIndicativeInvoicePaymentOptionalParams,
    callback: msRest.ServiceCallback<Models.InvoicePaymentCalculation>
  ): void;
  calculateIndicativeInvoicePayment(
    applicationId: string,
    options?:
      | Models.OriginationReadApiCalculateIndicativeInvoicePaymentOptionalParams
      | msRest.ServiceCallback<Models.InvoicePaymentCalculation>,
    callback?: msRest.ServiceCallback<Models.InvoicePaymentCalculation>
  ): Promise<Models.CalculateIndicativeInvoicePaymentResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      calculateIndicativeInvoicePaymentOperationSpec,
      callback
    ) as Promise<Models.CalculateIndicativeInvoicePaymentResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetMaximumTermResponse>
   */
  getMaximumTerm(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetMaximumTermResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getMaximumTerm(applicationId: string, callback: msRest.ServiceCallback<number>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getMaximumTerm(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<number>
  ): void;
  getMaximumTerm(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<number>,
    callback?: msRest.ServiceCallback<number>
  ): Promise<Models.GetMaximumTermResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getMaximumTermOperationSpec,
      callback
    ) as Promise<Models.GetMaximumTermResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IncomeDetailsForApplicationResponse>
   */
  incomeDetailsForApplication(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IncomeDetailsForApplicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  incomeDetailsForApplication(applicationId: string, callback: msRest.ServiceCallback<Models.IncomeDetails>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  incomeDetailsForApplication(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.IncomeDetails>
  ): void;
  incomeDetailsForApplication(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.IncomeDetails>,
    callback?: msRest.ServiceCallback<Models.IncomeDetails>
  ): Promise<Models.IncomeDetailsForApplicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      incomeDetailsForApplicationOperationSpec,
      callback
    ) as Promise<Models.IncomeDetailsForApplicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsApplicationCompleteResponse>
   */
  isApplicationComplete(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsApplicationCompleteResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isApplicationComplete(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isApplicationComplete(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isApplicationComplete(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsApplicationCompleteResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isApplicationCompleteOperationSpec,
      callback
    ) as Promise<Models.IsApplicationCompleteResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetOfferDetailsResponse>
   */
  getOfferDetails(
    applicationId: string,
    options?: Models.OriginationReadApiGetOfferDetailsOptionalParams
  ): Promise<Models.GetOfferDetailsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getOfferDetails(applicationId: string, callback: msRest.ServiceCallback<Models.OfferDetails>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getOfferDetails(
    applicationId: string,
    options: Models.OriginationReadApiGetOfferDetailsOptionalParams,
    callback: msRest.ServiceCallback<Models.OfferDetails>
  ): void;
  getOfferDetails(
    applicationId: string,
    options?: Models.OriginationReadApiGetOfferDetailsOptionalParams | msRest.ServiceCallback<Models.OfferDetails>,
    callback?: msRest.ServiceCallback<Models.OfferDetails>
  ): Promise<Models.GetOfferDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getOfferDetailsOperationSpec,
      callback
    ) as Promise<Models.GetOfferDetailsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetOrganisationIdResponse>
   */
  getOrganisationId(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetOrganisationIdResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getOrganisationId(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getOrganisationId(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getOrganisationId(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetOrganisationIdResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getOrganisationIdOperationSpec,
      callback
    ) as Promise<Models.GetOrganisationIdResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetOfferIdResponse>
   */
  getOfferId(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetOfferIdResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getOfferId(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getOfferId(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getOfferId(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetOfferIdResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getOfferIdOperationSpec,
      callback
    ) as Promise<Models.GetOfferIdResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationOnCustomerIdResponse>
   */
  getApplicationOnCustomerId(
    customerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationOnCustomerIdResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getApplicationOnCustomerId(customerId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationOnCustomerId(
    customerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getApplicationOnCustomerId(
    customerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetApplicationOnCustomerIdResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getApplicationOnCustomerIdOperationSpec,
      callback
    ) as Promise<Models.GetApplicationOnCustomerIdResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationsByCustomerIdResponse>
   */
  getApplicationsByCustomerId(
    customerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationsByCustomerIdResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getApplicationsByCustomerId(
    customerId: string,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection[]>
  ): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationsByCustomerId(
    customerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection[]>
  ): void;
  getApplicationsByCustomerId(
    customerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationListProjection[]>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjection[]>
  ): Promise<Models.GetApplicationsByCustomerIdResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getApplicationsByCustomerIdOperationSpec,
      callback
    ) as Promise<Models.GetApplicationsByCustomerIdResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationDetailsOnCustomerIdResponse>
   */
  getApplicationDetailsOnCustomerId(
    customerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationDetailsOnCustomerIdResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getApplicationDetailsOnCustomerId(
    customerId: string,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationDetailsOnCustomerId(
    customerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): void;
  getApplicationDetailsOnCustomerId(
    customerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationListProjection>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): Promise<Models.GetApplicationDetailsOnCustomerIdResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getApplicationDetailsOnCustomerIdOperationSpec,
      callback
    ) as Promise<Models.GetApplicationDetailsOnCustomerIdResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @deprecated This operation is deprecated. Please do not use it any longer.
   * @returns Promise<Models.GetApplicationOnOrganisationIdResponse>
   */
  getApplicationOnOrganisationId(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationOnOrganisationIdResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  getApplicationOnOrganisationId(organisationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  getApplicationOnOrganisationId(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getApplicationOnOrganisationId(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetApplicationOnOrganisationIdResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getApplicationOnOrganisationIdOperationSpec,
      callback
    ) as Promise<Models.GetApplicationOnOrganisationIdResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationsOnPartnerIdResponse>
   */
  getApplicationsOnPartnerId(
    partnerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationsOnPartnerIdResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getApplicationsOnPartnerId(partnerId: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationsOnPartnerId(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getApplicationsOnPartnerId(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetApplicationsOnPartnerIdResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getApplicationsOnPartnerIdOperationSpec,
      callback
    ) as Promise<Models.GetApplicationsOnPartnerIdResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationsOnPartnerIdOnStatusResponse>
   */
  getApplicationsOnPartnerIdOnStatus(
    partnerId: string,
    options?: Models.OriginationReadApiGetApplicationsOnPartnerIdOnStatusOptionalParams
  ): Promise<Models.GetApplicationsOnPartnerIdOnStatusResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getApplicationsOnPartnerIdOnStatus(
    partnerId: string,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionPagedList>
  ): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationsOnPartnerIdOnStatus(
    partnerId: string,
    options: Models.OriginationReadApiGetApplicationsOnPartnerIdOnStatusOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionPagedList>
  ): void;
  getApplicationsOnPartnerIdOnStatus(
    partnerId: string,
    options?:
      | Models.OriginationReadApiGetApplicationsOnPartnerIdOnStatusOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjectionPagedList>
  ): Promise<Models.GetApplicationsOnPartnerIdOnStatusResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getApplicationsOnPartnerIdOnStatusOperationSpec,
      callback
    ) as Promise<Models.GetApplicationsOnPartnerIdOnStatusResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  getApplicationsOnPartnerIdOnStatusCSV(
    partnerId: string,
    options?: Models.OriginationReadApiGetApplicationsOnPartnerIdOnStatusCSVOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getApplicationsOnPartnerIdOnStatusCSV(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationsOnPartnerIdOnStatusCSV(
    partnerId: string,
    options: Models.OriginationReadApiGetApplicationsOnPartnerIdOnStatusCSVOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  getApplicationsOnPartnerIdOnStatusCSV(
    partnerId: string,
    options?:
      | Models.OriginationReadApiGetApplicationsOnPartnerIdOnStatusCSVOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getApplicationsOnPartnerIdOnStatusCSVOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationsOnPartnerBusinessNameResponse>
   */
  getApplicationsOnPartnerBusinessName(
    options?: Models.OriginationReadApiGetApplicationsOnPartnerBusinessNameOptionalParams
  ): Promise<Models.GetApplicationsOnPartnerBusinessNameResponse>;
  /**
   * @param callback The callback
   */
  getApplicationsOnPartnerBusinessName(
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionPagedList>
  ): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationsOnPartnerBusinessName(
    options: Models.OriginationReadApiGetApplicationsOnPartnerBusinessNameOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjectionPagedList>
  ): void;
  getApplicationsOnPartnerBusinessName(
    options?:
      | Models.OriginationReadApiGetApplicationsOnPartnerBusinessNameOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjectionPagedList>
  ): Promise<Models.GetApplicationsOnPartnerBusinessNameResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getApplicationsOnPartnerBusinessNameOperationSpec,
      callback
    ) as Promise<Models.GetApplicationsOnPartnerBusinessNameResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationDetailOnOrganisationIdResponse>
   */
  getApplicationDetailOnOrganisationId(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationDetailOnOrganisationIdResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getApplicationDetailOnOrganisationId(
    organisationId: string,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationDetailOnOrganisationId(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): void;
  getApplicationDetailOnOrganisationId(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationListProjection>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): Promise<Models.GetApplicationDetailOnOrganisationIdResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getApplicationDetailOnOrganisationIdOperationSpec,
      callback
    ) as Promise<Models.GetApplicationDetailOnOrganisationIdResponse>;
  }

  /**
   * @param organisation
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationOnOrganisationNameResponse>
   */
  getApplicationOnOrganisationName(
    organisation: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationOnOrganisationNameResponse>;
  /**
   * @param organisation
   * @param callback The callback
   */
  getApplicationOnOrganisationName(organisation: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param organisation
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationOnOrganisationName(
    organisation: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getApplicationOnOrganisationName(
    organisation: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetApplicationOnOrganisationNameResponse> {
    return this.sendOperationRequest(
      {
        organisation,
        options
      },
      getApplicationOnOrganisationNameOperationSpec,
      callback
    ) as Promise<Models.GetApplicationOnOrganisationNameResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicationCache(
    applicationId: string,
    options?: Models.OriginationReadApiUpdateApplicationCacheOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateApplicationCache(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicationCache(
    applicationId: string,
    options: Models.OriginationReadApiUpdateApplicationCacheOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateApplicationCache(
    applicationId: string,
    options?: Models.OriginationReadApiUpdateApplicationCacheOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateApplicationCacheOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateCommunicationCache(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateCommunicationCache(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCommunicationCache(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateCommunicationCache(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateCommunicationCacheOperationSpec,
      callback
    );
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSingleApplicationResponse>
   */
  getSingleApplication(
    customerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetSingleApplicationResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getSingleApplication(customerId: string, callback: msRest.ServiceCallback<Models.ApplicationListProjection>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSingleApplication(
    customerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): void;
  getSingleApplication(
    customerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationListProjection>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): Promise<Models.GetSingleApplicationResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getSingleApplicationOperationSpec,
      callback
    ) as Promise<Models.GetSingleApplicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetByPartnerResponse>
   */
  getByPartner(options?: Models.OriginationReadApiGetByPartnerOptionalParams): Promise<Models.GetByPartnerResponse>;
  /**
   * @param callback The callback
   */
  getByPartner(callback: msRest.ServiceCallback<Models.ApplicationListProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getByPartner(
    options: Models.OriginationReadApiGetByPartnerOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection[]>
  ): void;
  getByPartner(
    options?:
      | Models.OriginationReadApiGetByPartnerOptionalParams
      | msRest.ServiceCallback<Models.ApplicationListProjection[]>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjection[]>
  ): Promise<Models.GetByPartnerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getByPartnerOperationSpec,
      callback
    ) as Promise<Models.GetByPartnerResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationCurrentProcessResponse>
   */
  getApplicationCurrentProcess(
    applicationId: string,
    options?: Models.OriginationReadApiGetApplicationCurrentProcessOptionalParams
  ): Promise<Models.GetApplicationCurrentProcessResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getApplicationCurrentProcess(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationCurrentProcess(
    applicationId: string,
    options: Models.OriginationReadApiGetApplicationCurrentProcessOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getApplicationCurrentProcess(
    applicationId: string,
    options?: Models.OriginationReadApiGetApplicationCurrentProcessOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetApplicationCurrentProcessResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getApplicationCurrentProcessOperationSpec,
      callback
    ) as Promise<Models.GetApplicationCurrentProcessResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationCurrentProcessStatusResponse>
   */
  getApplicationCurrentProcessStatus(
    applicationId: string,
    options?: Models.OriginationReadApiGetApplicationCurrentProcessStatusOptionalParams
  ): Promise<Models.GetApplicationCurrentProcessStatusResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getApplicationCurrentProcessStatus(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.ApplicationProcessStatus>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationCurrentProcessStatus(
    applicationId: string,
    options: Models.OriginationReadApiGetApplicationCurrentProcessStatusOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationProcessStatus>
  ): void;
  getApplicationCurrentProcessStatus(
    applicationId: string,
    options?:
      | Models.OriginationReadApiGetApplicationCurrentProcessStatusOptionalParams
      | msRest.ServiceCallback<Models.ApplicationProcessStatus>,
    callback?: msRest.ServiceCallback<Models.ApplicationProcessStatus>
  ): Promise<Models.GetApplicationCurrentProcessStatusResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getApplicationCurrentProcessStatusOperationSpec,
      callback
    ) as Promise<Models.GetApplicationCurrentProcessStatusResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPrimaryAccountResponse>
   */
  getPrimaryAccount(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPrimaryAccountResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getPrimaryAccount(applicationId: string, callback: msRest.ServiceCallback<Models.BankAccount>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPrimaryAccount(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BankAccount>
  ): void;
  getPrimaryAccount(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BankAccount>,
    callback?: msRest.ServiceCallback<Models.BankAccount>
  ): Promise<Models.GetPrimaryAccountResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getPrimaryAccountOperationSpec,
      callback
    ) as Promise<Models.GetPrimaryAccountResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetMobileNumberByEmailResponse>
   */
  getMobileNumberByEmail(
    options?: Models.OriginationReadApiGetMobileNumberByEmailOptionalParams
  ): Promise<Models.GetMobileNumberByEmailResponse>;
  /**
   * @param callback The callback
   */
  getMobileNumberByEmail(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getMobileNumberByEmail(
    options: Models.OriginationReadApiGetMobileNumberByEmailOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getMobileNumberByEmail(
    options?: Models.OriginationReadApiGetMobileNumberByEmailOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetMobileNumberByEmailResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getMobileNumberByEmailOperationSpec,
      callback
    ) as Promise<Models.GetMobileNumberByEmailResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetContractSignatureResponse>
   */
  getContractSignature(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetContractSignatureResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getContractSignature(applicationId: string, callback: msRest.ServiceCallback<Models.ContractSignature[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getContractSignature(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ContractSignature[]>
  ): void;
  getContractSignature(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ContractSignature[]>,
    callback?: msRest.ServiceCallback<Models.ContractSignature[]>
  ): Promise<Models.GetContractSignatureResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getContractSignatureOperationSpec,
      callback
    ) as Promise<Models.GetContractSignatureResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankAccountConnectionSettingsResponse>
   */
  getBankAccountConnectionSettings(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetBankAccountConnectionSettingsResponse>;
  /**
   * @param callback The callback
   */
  getBankAccountConnectionSettings(callback: msRest.ServiceCallback<Models.BankAccountConnectionSettings>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankAccountConnectionSettings(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BankAccountConnectionSettings>
  ): void;
  getBankAccountConnectionSettings(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BankAccountConnectionSettings>,
    callback?: msRest.ServiceCallback<Models.BankAccountConnectionSettings>
  ): Promise<Models.GetBankAccountConnectionSettingsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBankAccountConnectionSettingsOperationSpec,
      callback
    ) as Promise<Models.GetBankAccountConnectionSettingsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.SanitizeCommunicationTemplateMessageResponse>
   */
  sanitizeCommunicationTemplateMessage(
    applicationId: string,
    options?: Models.OriginationReadApiSanitizeCommunicationTemplateMessageOptionalParams
  ): Promise<Models.SanitizeCommunicationTemplateMessageResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sanitizeCommunicationTemplateMessage(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sanitizeCommunicationTemplateMessage(
    applicationId: string,
    options: Models.OriginationReadApiSanitizeCommunicationTemplateMessageOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  sanitizeCommunicationTemplateMessage(
    applicationId: string,
    options?:
      | Models.OriginationReadApiSanitizeCommunicationTemplateMessageOptionalParams
      | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SanitizeCommunicationTemplateMessageResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sanitizeCommunicationTemplateMessageOperationSpec,
      callback
    ) as Promise<Models.SanitizeCommunicationTemplateMessageResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetFrontendBaseUrlResponse>
   */
  getFrontendBaseUrl(options?: msRest.RequestOptionsBase): Promise<Models.GetFrontendBaseUrlResponse>;
  /**
   * @param callback The callback
   */
  getFrontendBaseUrl(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFrontendBaseUrl(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getFrontendBaseUrl(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetFrontendBaseUrlResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getFrontendBaseUrlOperationSpec,
      callback
    ) as Promise<Models.GetFrontendBaseUrlResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankfeedPlaidLinkDemoResponse>
   */
  getBankfeedPlaidLinkDemo(options?: msRest.RequestOptionsBase): Promise<Models.GetBankfeedPlaidLinkDemoResponse>;
  /**
   * @param callback The callback
   */
  getBankfeedPlaidLinkDemo(callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankfeedPlaidLinkDemo(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>
  ): void;
  getBankfeedPlaidLinkDemo(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>,
    callback?: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>
  ): Promise<Models.GetBankfeedPlaidLinkDemoResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBankfeedPlaidLinkDemoOperationSpec,
      callback
    ) as Promise<Models.GetBankfeedPlaidLinkDemoResponse>;
  }

  /**
   * @param zipCode
   * @param [options] The optional parameters
   * @returns Promise<Models.IsValidStateResponse>
   */
  isValidState(zipCode: number, options?: msRest.RequestOptionsBase): Promise<Models.IsValidStateResponse>;
  /**
   * @param zipCode
   * @param callback The callback
   */
  isValidState(zipCode: number, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param zipCode
   * @param options The optional parameters
   * @param callback The callback
   */
  isValidState(zipCode: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isValidState(
    zipCode: number,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsValidStateResponse> {
    return this.sendOperationRequest(
      {
        zipCode,
        options
      },
      isValidStateOperationSpec,
      callback
    ) as Promise<Models.IsValidStateResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankAccountConnectionDetailsResponse>
   */
  getBankAccountConnectionDetails(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetBankAccountConnectionDetailsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBankAccountConnectionDetails(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.BankAccountConnectionDetails[]>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankAccountConnectionDetails(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BankAccountConnectionDetails[]>
  ): void;
  getBankAccountConnectionDetails(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BankAccountConnectionDetails[]>,
    callback?: msRest.ServiceCallback<Models.BankAccountConnectionDetails[]>
  ): Promise<Models.GetBankAccountConnectionDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBankAccountConnectionDetailsOperationSpec,
      callback
    ) as Promise<Models.GetBankAccountConnectionDetailsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationContactsResponse>
   */
  getApplicationContacts(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationContactsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getApplicationContacts(applicationId: string, callback: msRest.ServiceCallback<Models.CustomerProjection[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationContacts(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerProjection[]>
  ): void;
  getApplicationContacts(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerProjection[]>,
    callback?: msRest.ServiceCallback<Models.CustomerProjection[]>
  ): Promise<Models.GetApplicationContactsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getApplicationContactsOperationSpec,
      callback
    ) as Promise<Models.GetApplicationContactsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationStatusSummaryResponse>
   */
  getApplicationStatusSummary(
    options?: Models.OriginationReadApiGetApplicationStatusSummaryOptionalParams
  ): Promise<Models.GetApplicationStatusSummaryResponse>;
  /**
   * @param callback The callback
   */
  getApplicationStatusSummary(callback: msRest.ServiceCallback<Models.StatusSummary[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationStatusSummary(
    options: Models.OriginationReadApiGetApplicationStatusSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.StatusSummary[]>
  ): void;
  getApplicationStatusSummary(
    options?:
      | Models.OriginationReadApiGetApplicationStatusSummaryOptionalParams
      | msRest.ServiceCallback<Models.StatusSummary[]>,
    callback?: msRest.ServiceCallback<Models.StatusSummary[]>
  ): Promise<Models.GetApplicationStatusSummaryResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getApplicationStatusSummaryOperationSpec,
      callback
    ) as Promise<Models.GetApplicationStatusSummaryResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationOtherStatusSummaryResponse>
   */
  getApplicationOtherStatusSummary(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationOtherStatusSummaryResponse>;
  /**
   * @param callback The callback
   */
  getApplicationOtherStatusSummary(callback: msRest.ServiceCallback<Models.StatusSummary[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationOtherStatusSummary(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.StatusSummary[]>
  ): void;
  getApplicationOtherStatusSummary(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.StatusSummary[]>,
    callback?: msRest.ServiceCallback<Models.StatusSummary[]>
  ): Promise<Models.GetApplicationOtherStatusSummaryResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getApplicationOtherStatusSummaryOperationSpec,
      callback
    ) as Promise<Models.GetApplicationOtherStatusSummaryResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerApplicationStatusSummaryResponse>
   */
  getPartnerApplicationStatusSummary(
    options?: Models.OriginationReadApiGetPartnerApplicationStatusSummaryOptionalParams
  ): Promise<Models.GetPartnerApplicationStatusSummaryResponse>;
  /**
   * @param callback The callback
   */
  getPartnerApplicationStatusSummary(callback: msRest.ServiceCallback<Models.StatusSummary[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerApplicationStatusSummary(
    options: Models.OriginationReadApiGetPartnerApplicationStatusSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.StatusSummary[]>
  ): void;
  getPartnerApplicationStatusSummary(
    options?:
      | Models.OriginationReadApiGetPartnerApplicationStatusSummaryOptionalParams
      | msRest.ServiceCallback<Models.StatusSummary[]>,
    callback?: msRest.ServiceCallback<Models.StatusSummary[]>
  ): Promise<Models.GetPartnerApplicationStatusSummaryResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerApplicationStatusSummaryOperationSpec,
      callback
    ) as Promise<Models.GetPartnerApplicationStatusSummaryResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerApplicationOtherStatusSummaryResponse>
   */
  getPartnerApplicationOtherStatusSummary(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPartnerApplicationOtherStatusSummaryResponse>;
  /**
   * @param callback The callback
   */
  getPartnerApplicationOtherStatusSummary(callback: msRest.ServiceCallback<Models.StatusSummary[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerApplicationOtherStatusSummary(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.StatusSummary[]>
  ): void;
  getPartnerApplicationOtherStatusSummary(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.StatusSummary[]>,
    callback?: msRest.ServiceCallback<Models.StatusSummary[]>
  ): Promise<Models.GetPartnerApplicationOtherStatusSummaryResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerApplicationOtherStatusSummaryOperationSpec,
      callback
    ) as Promise<Models.GetPartnerApplicationOtherStatusSummaryResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsApplicationAddressValidResponse>
   */
  isApplicationAddressValid(
    options?: Models.OriginationReadApiIsApplicationAddressValidOptionalParams
  ): Promise<Models.IsApplicationAddressValidResponse>;
  /**
   * @param callback The callback
   */
  isApplicationAddressValid(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isApplicationAddressValid(
    options: Models.OriginationReadApiIsApplicationAddressValidOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isApplicationAddressValid(
    options?: Models.OriginationReadApiIsApplicationAddressValidOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsApplicationAddressValidResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isApplicationAddressValidOperationSpec,
      callback
    ) as Promise<Models.IsApplicationAddressValidResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetUnderReviewKycNoteDataResponse>
   */
  getUnderReviewKycNoteData(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetUnderReviewKycNoteDataResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getUnderReviewKycNoteData(applicationId: string, callback: msRest.ServiceCallback<any>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getUnderReviewKycNoteData(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<any>
  ): void;
  getUnderReviewKycNoteData(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<any>,
    callback?: msRest.ServiceCallback<any>
  ): Promise<Models.GetUnderReviewKycNoteDataResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getUnderReviewKycNoteDataOperationSpec,
      callback
    ) as Promise<Models.GetUnderReviewKycNoteDataResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsManualReviewResponse>
   */
  isManualReview(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsManualReviewResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isManualReview(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isManualReview(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isManualReview(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsManualReviewResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isManualReviewOperationSpec,
      callback
    ) as Promise<Models.IsManualReviewResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.UnderReviewTestResponse>
   */
  underReviewTest(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.UnderReviewTestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  underReviewTest(applicationId: string, callback: msRest.ServiceCallback<Models.DecisionDetails>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  underReviewTest(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.DecisionDetails>
  ): void;
  underReviewTest(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DecisionDetails>,
    callback?: msRest.ServiceCallback<Models.DecisionDetails>
  ): Promise<Models.UnderReviewTestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      underReviewTestOperationSpec,
      callback
    ) as Promise<Models.UnderReviewTestResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.CompareEnableDisableApprovalTestResponse>
   */
  compareEnableDisableApprovalTest(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.CompareEnableDisableApprovalTestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  compareEnableDisableApprovalTest(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  compareEnableDisableApprovalTest(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  compareEnableDisableApprovalTest(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.CompareEnableDisableApprovalTestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      compareEnableDisableApprovalTestOperationSpec,
      callback
    ) as Promise<Models.CompareEnableDisableApprovalTestResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetUnderReviewStateResponse>
   */
  getUnderReviewState(
    options?: Models.OriginationReadApiGetUnderReviewStateOptionalParams
  ): Promise<Models.GetUnderReviewStateResponse>;
  /**
   * @param callback The callback
   */
  getUnderReviewState(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getUnderReviewState(
    options: Models.OriginationReadApiGetUnderReviewStateOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getUnderReviewState(
    options?: Models.OriginationReadApiGetUnderReviewStateOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetUnderReviewStateResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getUnderReviewStateOperationSpec,
      callback
    ) as Promise<Models.GetUnderReviewStateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllNotesResponse>
   */
  getAllNotes(options?: Models.OriginationReadApiGetAllNotesOptionalParams): Promise<Models.GetAllNotesResponse>;
  /**
   * @param callback The callback
   */
  getAllNotes(callback: msRest.ServiceCallback<Models.Note[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllNotes(
    options: Models.OriginationReadApiGetAllNotesOptionalParams,
    callback: msRest.ServiceCallback<Models.Note[]>
  ): void;
  getAllNotes(
    options?: Models.OriginationReadApiGetAllNotesOptionalParams | msRest.ServiceCallback<Models.Note[]>,
    callback?: msRest.ServiceCallback<Models.Note[]>
  ): Promise<Models.GetAllNotesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllNotesOperationSpec,
      callback
    ) as Promise<Models.GetAllNotesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetNotesByTypeResponse>
   */
  getNotesByType(
    options?: Models.OriginationReadApiGetNotesByTypeOptionalParams
  ): Promise<Models.GetNotesByTypeResponse>;
  /**
   * @param callback The callback
   */
  getNotesByType(callback: msRest.ServiceCallback<Models.Note[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getNotesByType(
    options: Models.OriginationReadApiGetNotesByTypeOptionalParams,
    callback: msRest.ServiceCallback<Models.Note[]>
  ): void;
  getNotesByType(
    options?: Models.OriginationReadApiGetNotesByTypeOptionalParams | msRest.ServiceCallback<Models.Note[]>,
    callback?: msRest.ServiceCallback<Models.Note[]>
  ): Promise<Models.GetNotesByTypeResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getNotesByTypeOperationSpec,
      callback
    ) as Promise<Models.GetNotesByTypeResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationInsightsResponse>
   */
  getApplicationInsights(
    options?: Models.OriginationReadApiGetApplicationInsightsOptionalParams
  ): Promise<Models.GetApplicationInsightsResponse>;
  /**
   * @param callback The callback
   */
  getApplicationInsights(callback: msRest.ServiceCallback<Models.Note[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationInsights(
    options: Models.OriginationReadApiGetApplicationInsightsOptionalParams,
    callback: msRest.ServiceCallback<Models.Note[]>
  ): void;
  getApplicationInsights(
    options?: Models.OriginationReadApiGetApplicationInsightsOptionalParams | msRest.ServiceCallback<Models.Note[]>,
    callback?: msRest.ServiceCallback<Models.Note[]>
  ): Promise<Models.GetApplicationInsightsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getApplicationInsightsOperationSpec,
      callback
    ) as Promise<Models.GetApplicationInsightsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetOldApplicationsResponse>
   */
  getOldApplications(
    options?: Models.OriginationReadApiGetOldApplicationsOptionalParams
  ): Promise<Models.GetOldApplicationsResponse>;
  /**
   * @param callback The callback
   */
  getOldApplications(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getOldApplications(
    options: Models.OriginationReadApiGetOldApplicationsOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getOldApplications(
    options?: Models.OriginationReadApiGetOldApplicationsOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetOldApplicationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getOldApplicationsOperationSpec,
      callback
    ) as Promise<Models.GetOldApplicationsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  getApplicationSummaryCSV(
    options?: Models.OriginationReadApiGetApplicationSummaryCSVOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  getApplicationSummaryCSV(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationSummaryCSV(
    options: Models.OriginationReadApiGetApplicationSummaryCSVOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  getApplicationSummaryCSV(
    options?: Models.OriginationReadApiGetApplicationSummaryCSVOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getApplicationSummaryCSVOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  getLoanNoAdvanceSummaryCSV(
    options?: Models.OriginationReadApiGetLoanNoAdvanceSummaryCSVOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  getLoanNoAdvanceSummaryCSV(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanNoAdvanceSummaryCSV(
    options: Models.OriginationReadApiGetLoanNoAdvanceSummaryCSVOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  getLoanNoAdvanceSummaryCSV(
    options?: Models.OriginationReadApiGetLoanNoAdvanceSummaryCSVOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanNoAdvanceSummaryCSVOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCancelOldOfferReadyApplicationsResponse>
   */
  getCancelOldOfferReadyApplications(
    options?: Models.OriginationReadApiGetCancelOldOfferReadyApplicationsOptionalParams
  ): Promise<Models.GetCancelOldOfferReadyApplicationsResponse>;
  /**
   * @param callback The callback
   */
  getCancelOldOfferReadyApplications(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCancelOldOfferReadyApplications(
    options: Models.OriginationReadApiGetCancelOldOfferReadyApplicationsOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getCancelOldOfferReadyApplications(
    options?:
      | Models.OriginationReadApiGetCancelOldOfferReadyApplicationsOptionalParams
      | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetCancelOldOfferReadyApplicationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCancelOldOfferReadyApplicationsOperationSpec,
      callback
    ) as Promise<Models.GetCancelOldOfferReadyApplicationsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ContactListResponse>
   */
  contactList(options?: Models.OriginationReadApiContactListOptionalParams): Promise<Models.ContactListResponse>;
  /**
   * @param callback The callback
   */
  contactList(callback: msRest.ServiceCallback<{ [propertyName: string]: string[] }>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  contactList(
    options: Models.OriginationReadApiContactListOptionalParams,
    callback: msRest.ServiceCallback<{ [propertyName: string]: string[] }>
  ): void;
  contactList(
    options?:
      | Models.OriginationReadApiContactListOptionalParams
      | msRest.ServiceCallback<{ [propertyName: string]: string[] }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: string[] }>
  ): Promise<Models.ContactListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      contactListOperationSpec,
      callback
    ) as Promise<Models.ContactListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OfferReadyReminderForPartnerAndAgentResponse>
   */
  offerReadyReminderForPartnerAndAgent(
    options?: Models.OriginationReadApiOfferReadyReminderForPartnerAndAgentOptionalParams
  ): Promise<Models.OfferReadyReminderForPartnerAndAgentResponse>;
  /**
   * @param callback The callback
   */
  offerReadyReminderForPartnerAndAgent(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  offerReadyReminderForPartnerAndAgent(
    options: Models.OriginationReadApiOfferReadyReminderForPartnerAndAgentOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  offerReadyReminderForPartnerAndAgent(
    options?:
      | Models.OriginationReadApiOfferReadyReminderForPartnerAndAgentOptionalParams
      | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.OfferReadyReminderForPartnerAndAgentResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      offerReadyReminderForPartnerAndAgentOperationSpec,
      callback
    ) as Promise<Models.OfferReadyReminderForPartnerAndAgentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OfferReadyReminderForCustomerResponse>
   */
  offerReadyReminderForCustomer(
    options?: Models.OriginationReadApiOfferReadyReminderForCustomerOptionalParams
  ): Promise<Models.OfferReadyReminderForCustomerResponse>;
  /**
   * @param callback The callback
   */
  offerReadyReminderForCustomer(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  offerReadyReminderForCustomer(
    options: Models.OriginationReadApiOfferReadyReminderForCustomerOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  offerReadyReminderForCustomer(
    options?: Models.OriginationReadApiOfferReadyReminderForCustomerOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.OfferReadyReminderForCustomerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      offerReadyReminderForCustomerOperationSpec,
      callback
    ) as Promise<Models.OfferReadyReminderForCustomerResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DraftReminderForPartnerAndAgentResponse>
   */
  draftReminderForPartnerAndAgent(
    options?: Models.OriginationReadApiDraftReminderForPartnerAndAgentOptionalParams
  ): Promise<Models.DraftReminderForPartnerAndAgentResponse>;
  /**
   * @param callback The callback
   */
  draftReminderForPartnerAndAgent(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  draftReminderForPartnerAndAgent(
    options: Models.OriginationReadApiDraftReminderForPartnerAndAgentOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  draftReminderForPartnerAndAgent(
    options?: Models.OriginationReadApiDraftReminderForPartnerAndAgentOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.DraftReminderForPartnerAndAgentResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      draftReminderForPartnerAndAgentOperationSpec,
      callback
    ) as Promise<Models.DraftReminderForPartnerAndAgentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UndrawnAdvanceReminderForPartnerAndAgentResponse>
   */
  undrawnAdvanceReminderForPartnerAndAgent(
    options?: Models.OriginationReadApiUndrawnAdvanceReminderForPartnerAndAgentOptionalParams
  ): Promise<Models.UndrawnAdvanceReminderForPartnerAndAgentResponse>;
  /**
   * @param callback The callback
   */
  undrawnAdvanceReminderForPartnerAndAgent(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  undrawnAdvanceReminderForPartnerAndAgent(
    options: Models.OriginationReadApiUndrawnAdvanceReminderForPartnerAndAgentOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  undrawnAdvanceReminderForPartnerAndAgent(
    options?:
      | Models.OriginationReadApiUndrawnAdvanceReminderForPartnerAndAgentOptionalParams
      | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.UndrawnAdvanceReminderForPartnerAndAgentResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      undrawnAdvanceReminderForPartnerAndAgentOperationSpec,
      callback
    ) as Promise<Models.UndrawnAdvanceReminderForPartnerAndAgentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UndrawnAdvanceReminderForCustomerResponse>
   */
  undrawnAdvanceReminderForCustomer(
    options?: Models.OriginationReadApiUndrawnAdvanceReminderForCustomerOptionalParams
  ): Promise<Models.UndrawnAdvanceReminderForCustomerResponse>;
  /**
   * @param callback The callback
   */
  undrawnAdvanceReminderForCustomer(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  undrawnAdvanceReminderForCustomer(
    options: Models.OriginationReadApiUndrawnAdvanceReminderForCustomerOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  undrawnAdvanceReminderForCustomer(
    options?:
      | Models.OriginationReadApiUndrawnAdvanceReminderForCustomerOptionalParams
      | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.UndrawnAdvanceReminderForCustomerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      undrawnAdvanceReminderForCustomerOperationSpec,
      callback
    ) as Promise<Models.UndrawnAdvanceReminderForCustomerResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.NoApplicationAndNoLoanCustomerResponse>
   */
  noApplicationAndNoLoanCustomer(
    options?: Models.OriginationReadApiNoApplicationAndNoLoanCustomerOptionalParams
  ): Promise<Models.NoApplicationAndNoLoanCustomerResponse>;
  /**
   * @param callback The callback
   */
  noApplicationAndNoLoanCustomer(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  noApplicationAndNoLoanCustomer(
    options: Models.OriginationReadApiNoApplicationAndNoLoanCustomerOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  noApplicationAndNoLoanCustomer(
    options?: Models.OriginationReadApiNoApplicationAndNoLoanCustomerOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.NoApplicationAndNoLoanCustomerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      noApplicationAndNoLoanCustomerOperationSpec,
      callback
    ) as Promise<Models.NoApplicationAndNoLoanCustomerResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetInvalidStateResponse>
   */
  getInvalidState(
    options?: Models.OriginationReadApiGetInvalidStateOptionalParams
  ): Promise<Models.GetInvalidStateResponse>;
  /**
   * @param callback The callback
   */
  getInvalidState(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getInvalidState(
    options: Models.OriginationReadApiGetInvalidStateOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getInvalidState(
    options?: Models.OriginationReadApiGetInvalidStateOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetInvalidStateResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getInvalidStateOperationSpec,
      callback
    ) as Promise<Models.GetInvalidStateResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadBankStatementDocumentResponse>
   */
  downloadBankStatementDocument(
    organisationId: string,
    options?: Models.OriginationReadApiDownloadBankStatementDocumentOptionalParams
  ): Promise<Models.DownloadBankStatementDocumentResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  downloadBankStatementDocument(
    organisationId: string,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadBankStatementDocument(
    organisationId: string,
    options: Models.OriginationReadApiDownloadBankStatementDocumentOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  downloadBankStatementDocument(
    organisationId: string,
    options?:
      | Models.OriginationReadApiDownloadBankStatementDocumentOptionalParams
      | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.DownloadBankStatementDocumentResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      downloadBankStatementDocumentOperationSpec,
      callback
    ) as Promise<Models.DownloadBankStatementDocumentResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankStatementDocumentsResponse>
   */
  getBankStatementDocuments(
    organisationId: string,
    options?: Models.OriginationReadApiGetBankStatementDocumentsOptionalParams
  ): Promise<Models.GetBankStatementDocumentsResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getBankStatementDocuments(organisationId: string, callback: msRest.ServiceCallback<Models.FileDetails[]>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankStatementDocuments(
    organisationId: string,
    options: Models.OriginationReadApiGetBankStatementDocumentsOptionalParams,
    callback: msRest.ServiceCallback<Models.FileDetails[]>
  ): void;
  getBankStatementDocuments(
    organisationId: string,
    options?:
      | Models.OriginationReadApiGetBankStatementDocumentsOptionalParams
      | msRest.ServiceCallback<Models.FileDetails[]>,
    callback?: msRest.ServiceCallback<Models.FileDetails[]>
  ): Promise<Models.GetBankStatementDocumentsResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getBankStatementDocumentsOperationSpec,
      callback
    ) as Promise<Models.GetBankStatementDocumentsResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllBankStatementDocumentsResponse>
   */
  getAllBankStatementDocuments(
    organisationId: string,
    options?: Models.OriginationReadApiGetAllBankStatementDocumentsOptionalParams
  ): Promise<Models.GetAllBankStatementDocumentsResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getAllBankStatementDocuments(organisationId: string, callback: msRest.ServiceCallback<Models.FileDetails[]>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllBankStatementDocuments(
    organisationId: string,
    options: Models.OriginationReadApiGetAllBankStatementDocumentsOptionalParams,
    callback: msRest.ServiceCallback<Models.FileDetails[]>
  ): void;
  getAllBankStatementDocuments(
    organisationId: string,
    options?:
      | Models.OriginationReadApiGetAllBankStatementDocumentsOptionalParams
      | msRest.ServiceCallback<Models.FileDetails[]>,
    callback?: msRest.ServiceCallback<Models.FileDetails[]>
  ): Promise<Models.GetAllBankStatementDocumentsResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getAllBankStatementDocumentsOperationSpec,
      callback
    ) as Promise<Models.GetAllBankStatementDocumentsResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadBankStatementDocumentsResponse>
   */
  downloadBankStatementDocuments(
    organisationId: string,
    options?: Models.OriginationReadApiDownloadBankStatementDocumentsOptionalParams
  ): Promise<Models.DownloadBankStatementDocumentsResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  downloadBankStatementDocuments(
    organisationId: string,
    callback: msRest.ServiceCallback<Models.TransactionDataFile[]>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadBankStatementDocuments(
    organisationId: string,
    options: Models.OriginationReadApiDownloadBankStatementDocumentsOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDataFile[]>
  ): void;
  downloadBankStatementDocuments(
    organisationId: string,
    options?:
      | Models.OriginationReadApiDownloadBankStatementDocumentsOptionalParams
      | msRest.ServiceCallback<Models.TransactionDataFile[]>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile[]>
  ): Promise<Models.DownloadBankStatementDocumentsResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      downloadBankStatementDocumentsOperationSpec,
      callback
    ) as Promise<Models.DownloadBankStatementDocumentsResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankStatementMissingTransactionDocumentsResponse>
   */
  getBankStatementMissingTransactionDocuments(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetBankStatementMissingTransactionDocumentsResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getBankStatementMissingTransactionDocuments(
    organisationId: string,
    callback: msRest.ServiceCallback<Models.FileDetails[]>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankStatementMissingTransactionDocuments(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.FileDetails[]>
  ): void;
  getBankStatementMissingTransactionDocuments(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.FileDetails[]>,
    callback?: msRest.ServiceCallback<Models.FileDetails[]>
  ): Promise<Models.GetBankStatementMissingTransactionDocumentsResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getBankStatementMissingTransactionDocumentsOperationSpec,
      callback
    ) as Promise<Models.GetBankStatementMissingTransactionDocumentsResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadBankStatementMissingTransactionDocumentResponse>
   */
  downloadBankStatementMissingTransactionDocument(
    organisationId: string,
    options?: Models.OriginationReadApiDownloadBankStatementMissingTransactionDocumentOptionalParams
  ): Promise<Models.DownloadBankStatementMissingTransactionDocumentResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  downloadBankStatementMissingTransactionDocument(
    organisationId: string,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadBankStatementMissingTransactionDocument(
    organisationId: string,
    options: Models.OriginationReadApiDownloadBankStatementMissingTransactionDocumentOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  downloadBankStatementMissingTransactionDocument(
    organisationId: string,
    options?:
      | Models.OriginationReadApiDownloadBankStatementMissingTransactionDocumentOptionalParams
      | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.DownloadBankStatementMissingTransactionDocumentResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      downloadBankStatementMissingTransactionDocumentOperationSpec,
      callback
    ) as Promise<Models.DownloadBankStatementMissingTransactionDocumentResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankStatementMissingTransactionsResponse>
   */
  getBankStatementMissingTransactions(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetBankStatementMissingTransactionsResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getBankStatementMissingTransactions(
    organisationId: string,
    callback: msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsTransactionMatrix[]>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankStatementMissingTransactions(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsTransactionMatrix[]>
  ): void;
  getBankStatementMissingTransactions(
    organisationId: string,
    options?:
      | msRest.RequestOptionsBase
      | msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsTransactionMatrix[]>,
    callback?: msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsTransactionMatrix[]>
  ): Promise<Models.GetBankStatementMissingTransactionsResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getBankStatementMissingTransactionsOperationSpec,
      callback
    ) as Promise<Models.GetBankStatementMissingTransactionsResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetUploadBankStatementUrlResponse>
   */
  getUploadBankStatementUrl(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetUploadBankStatementUrlResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getUploadBankStatementUrl(organisationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getUploadBankStatementUrl(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getUploadBankStatementUrl(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetUploadBankStatementUrlResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getUploadBankStatementUrlOperationSpec,
      callback
    ) as Promise<Models.GetUploadBankStatementUrlResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetChargeOffDateResponse>
   */
  getChargeOffDate(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetChargeOffDateResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getChargeOffDate(loanId: string, callback: msRest.ServiceCallback<Date>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getChargeOffDate(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Date>): void;
  getChargeOffDate(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Date>,
    callback?: msRest.ServiceCallback<Date>
  ): Promise<Models.GetChargeOffDateResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getChargeOffDateOperationSpec,
      callback
    ) as Promise<Models.GetChargeOffDateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetManualCheckListResponse>
   */
  getManualCheckList(
    options?: Models.OriginationReadApiGetManualCheckListOptionalParams
  ): Promise<Models.GetManualCheckListResponse>;
  /**
   * @param callback The callback
   */
  getManualCheckList(callback: msRest.ServiceCallback<Models.CheckList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getManualCheckList(
    options: Models.OriginationReadApiGetManualCheckListOptionalParams,
    callback: msRest.ServiceCallback<Models.CheckList>
  ): void;
  getManualCheckList(
    options?: Models.OriginationReadApiGetManualCheckListOptionalParams | msRest.ServiceCallback<Models.CheckList>,
    callback?: msRest.ServiceCallback<Models.CheckList>
  ): Promise<Models.GetManualCheckListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getManualCheckListOperationSpec,
      callback
    ) as Promise<Models.GetManualCheckListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSettlementCheckListResponse>
   */
  getSettlementCheckList(
    options?: Models.OriginationReadApiGetSettlementCheckListOptionalParams
  ): Promise<Models.GetSettlementCheckListResponse>;
  /**
   * @param callback The callback
   */
  getSettlementCheckList(callback: msRest.ServiceCallback<Models.CheckList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getSettlementCheckList(
    options: Models.OriginationReadApiGetSettlementCheckListOptionalParams,
    callback: msRest.ServiceCallback<Models.CheckList>
  ): void;
  getSettlementCheckList(
    options?: Models.OriginationReadApiGetSettlementCheckListOptionalParams | msRest.ServiceCallback<Models.CheckList>,
    callback?: msRest.ServiceCallback<Models.CheckList>
  ): Promise<Models.GetSettlementCheckListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getSettlementCheckListOperationSpec,
      callback
    ) as Promise<Models.GetSettlementCheckListResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentApplicationCommunicationsResponse>
   */
  getAgentApplicationCommunications(
    agentId: string,
    options?: Models.OriginationReadApiGetAgentApplicationCommunicationsOptionalParams
  ): Promise<Models.GetAgentApplicationCommunicationsResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  getAgentApplicationCommunications(
    agentId: string,
    callback: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentApplicationCommunications(
    agentId: string,
    options: Models.OriginationReadApiGetAgentApplicationCommunicationsOptionalParams,
    callback: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): void;
  getAgentApplicationCommunications(
    agentId: string,
    options?:
      | Models.OriginationReadApiGetAgentApplicationCommunicationsOptionalParams
      | msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>,
    callback?: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): Promise<Models.GetAgentApplicationCommunicationsResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      getAgentApplicationCommunicationsOperationSpec,
      callback
    ) as Promise<Models.GetAgentApplicationCommunicationsResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsNewAgentCommunicationResponse>
   */
  isNewAgentCommunication(
    agentId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsNewAgentCommunicationResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  isNewAgentCommunication(agentId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  isNewAgentCommunication(
    agentId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isNewAgentCommunication(
    agentId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsNewAgentCommunicationResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      isNewAgentCommunicationOperationSpec,
      callback
    ) as Promise<Models.IsNewAgentCommunicationResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsNewAgentApplicationCommunicationResponse>
   */
  isNewAgentApplicationCommunication(
    agentId: string,
    options?: Models.OriginationReadApiIsNewAgentApplicationCommunicationOptionalParams
  ): Promise<Models.IsNewAgentApplicationCommunicationResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  isNewAgentApplicationCommunication(agentId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  isNewAgentApplicationCommunication(
    agentId: string,
    options: Models.OriginationReadApiIsNewAgentApplicationCommunicationOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isNewAgentApplicationCommunication(
    agentId: string,
    options?:
      | Models.OriginationReadApiIsNewAgentApplicationCommunicationOptionalParams
      | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsNewAgentApplicationCommunicationResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      isNewAgentApplicationCommunicationOperationSpec,
      callback
    ) as Promise<Models.IsNewAgentApplicationCommunicationResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsNewAgentApplicationCommunicationListResponse>
   */
  isNewAgentApplicationCommunicationList(
    agentId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsNewAgentApplicationCommunicationListResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  isNewAgentApplicationCommunicationList(
    agentId: string,
    callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>
  ): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  isNewAgentApplicationCommunicationList(
    agentId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>
  ): void;
  isNewAgentApplicationCommunicationList(
    agentId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: boolean }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: boolean }>
  ): Promise<Models.IsNewAgentApplicationCommunicationListResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      isNewAgentApplicationCommunicationListOperationSpec,
      callback
    ) as Promise<Models.IsNewAgentApplicationCommunicationListResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerCommunicationsResponse>
   */
  getPartnerCommunications(
    partnerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPartnerCommunicationsResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getPartnerCommunications(
    partnerId: string,
    callback: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerCommunications(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): void;
  getPartnerCommunications(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>,
    callback?: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): Promise<Models.GetPartnerCommunicationsResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getPartnerCommunicationsOperationSpec,
      callback
    ) as Promise<Models.GetPartnerCommunicationsResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerApplicationCommunicationsResponse>
   */
  getPartnerApplicationCommunications(
    partnerId: string,
    options?: Models.OriginationReadApiGetPartnerApplicationCommunicationsOptionalParams
  ): Promise<Models.GetPartnerApplicationCommunicationsResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getPartnerApplicationCommunications(
    partnerId: string,
    callback: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerApplicationCommunications(
    partnerId: string,
    options: Models.OriginationReadApiGetPartnerApplicationCommunicationsOptionalParams,
    callback: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): void;
  getPartnerApplicationCommunications(
    partnerId: string,
    options?:
      | Models.OriginationReadApiGetPartnerApplicationCommunicationsOptionalParams
      | msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>,
    callback?: msRest.ServiceCallback<Models.CommunicationProjectionExtension[]>
  ): Promise<Models.GetPartnerApplicationCommunicationsResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getPartnerApplicationCommunicationsOperationSpec,
      callback
    ) as Promise<Models.GetPartnerApplicationCommunicationsResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsNewPartnerCommunicationResponse>
   */
  isNewPartnerCommunication(
    partnerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsNewPartnerCommunicationResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  isNewPartnerCommunication(partnerId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  isNewPartnerCommunication(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isNewPartnerCommunication(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsNewPartnerCommunicationResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      isNewPartnerCommunicationOperationSpec,
      callback
    ) as Promise<Models.IsNewPartnerCommunicationResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsNewPartnerApplicationCommunicationResponse>
   */
  isNewPartnerApplicationCommunication(
    partnerId: string,
    options?: Models.OriginationReadApiIsNewPartnerApplicationCommunicationOptionalParams
  ): Promise<Models.IsNewPartnerApplicationCommunicationResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  isNewPartnerApplicationCommunication(partnerId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  isNewPartnerApplicationCommunication(
    partnerId: string,
    options: Models.OriginationReadApiIsNewPartnerApplicationCommunicationOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isNewPartnerApplicationCommunication(
    partnerId: string,
    options?:
      | Models.OriginationReadApiIsNewPartnerApplicationCommunicationOptionalParams
      | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsNewPartnerApplicationCommunicationResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      isNewPartnerApplicationCommunicationOperationSpec,
      callback
    ) as Promise<Models.IsNewPartnerApplicationCommunicationResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsNewPartnerApplicationCommunicationListResponse>
   */
  isNewPartnerApplicationCommunicationList(
    partnerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsNewPartnerApplicationCommunicationListResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  isNewPartnerApplicationCommunicationList(
    partnerId: string,
    callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>
  ): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  isNewPartnerApplicationCommunicationList(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>
  ): void;
  isNewPartnerApplicationCommunicationList(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: boolean }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: boolean }>
  ): Promise<Models.IsNewPartnerApplicationCommunicationListResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      isNewPartnerApplicationCommunicationListOperationSpec,
      callback
    ) as Promise<Models.IsNewPartnerApplicationCommunicationListResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankStatementCommunicationMessageResponse>
   */
  getBankStatementCommunicationMessage(
    organisationId: string,
    options?: Models.OriginationReadApiGetBankStatementCommunicationMessageOptionalParams
  ): Promise<Models.GetBankStatementCommunicationMessageResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getBankStatementCommunicationMessage(organisationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankStatementCommunicationMessage(
    organisationId: string,
    options: Models.OriginationReadApiGetBankStatementCommunicationMessageOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getBankStatementCommunicationMessage(
    organisationId: string,
    options?:
      | Models.OriginationReadApiGetBankStatementCommunicationMessageOptionalParams
      | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetBankStatementCommunicationMessageResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getBankStatementCommunicationMessageOperationSpec,
      callback
    ) as Promise<Models.GetBankStatementCommunicationMessageResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCommunicationTodosResponse>
   */
  getCommunicationTodos(
    options?: Models.OriginationReadApiGetCommunicationTodosOptionalParams
  ): Promise<Models.GetCommunicationTodosResponse>;
  /**
   * @param callback The callback
   */
  getCommunicationTodos(callback: msRest.ServiceCallback<Models.TodayCommunication[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCommunicationTodos(
    options: Models.OriginationReadApiGetCommunicationTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodayCommunication[]>
  ): void;
  getCommunicationTodos(
    options?:
      | Models.OriginationReadApiGetCommunicationTodosOptionalParams
      | msRest.ServiceCallback<Models.TodayCommunication[]>,
    callback?: msRest.ServiceCallback<Models.TodayCommunication[]>
  ): Promise<Models.GetCommunicationTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCommunicationTodosOperationSpec,
      callback
    ) as Promise<Models.GetCommunicationTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCommunicationRulesResponse>
   */
  getCommunicationRules(options?: msRest.RequestOptionsBase): Promise<Models.GetCommunicationRulesResponse>;
  /**
   * @param callback The callback
   */
  getCommunicationRules(callback: msRest.ServiceCallback<Models.CommunicationRule[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCommunicationRules(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CommunicationRule[]>
  ): void;
  getCommunicationRules(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunicationRule[]>,
    callback?: msRest.ServiceCallback<Models.CommunicationRule[]>
  ): Promise<Models.GetCommunicationRulesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCommunicationRulesOperationSpec,
      callback
    ) as Promise<Models.GetCommunicationRulesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsRuleAllowedResponse>
   */
  isRuleAllowed(options?: Models.OriginationReadApiIsRuleAllowedOptionalParams): Promise<Models.IsRuleAllowedResponse>;
  /**
   * @param callback The callback
   */
  isRuleAllowed(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isRuleAllowed(
    options: Models.OriginationReadApiIsRuleAllowedOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isRuleAllowed(
    options?: Models.OriginationReadApiIsRuleAllowedOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsRuleAllowedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isRuleAllowedOperationSpec,
      callback
    ) as Promise<Models.IsRuleAllowedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetStartupConfigResponse>
   */
  getStartupConfig(options?: msRest.RequestOptionsBase): Promise<Models.GetStartupConfigResponse>;
  /**
   * @param callback The callback
   */
  getStartupConfig(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getStartupConfig(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  getStartupConfig(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.GetStartupConfigResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getStartupConfigOperationSpec,
      callback
    ) as Promise<Models.GetStartupConfigResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsAccountingSystemEnabledResponse>
   */
  isAccountingSystemEnabled(options?: msRest.RequestOptionsBase): Promise<Models.IsAccountingSystemEnabledResponse>;
  /**
   * @param callback The callback
   */
  isAccountingSystemEnabled(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isAccountingSystemEnabled(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isAccountingSystemEnabled(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsAccountingSystemEnabledResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isAccountingSystemEnabledOperationSpec,
      callback
    ) as Promise<Models.IsAccountingSystemEnabledResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsApplicantImageCaptureEnabledResponse>
   */
  isApplicantImageCaptureEnabled(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsApplicantImageCaptureEnabledResponse>;
  /**
   * @param callback The callback
   */
  isApplicantImageCaptureEnabled(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isApplicantImageCaptureEnabled(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isApplicantImageCaptureEnabled(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsApplicantImageCaptureEnabledResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isApplicantImageCaptureEnabledOperationSpec,
      callback
    ) as Promise<Models.IsApplicantImageCaptureEnabledResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllContactsResponse>
   */
  getAllContacts(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAllContactsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getAllContacts(applicationId: string, callback: msRest.ServiceCallback<Models.Contact[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllContacts(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Contact[]>
  ): void;
  getAllContacts(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Contact[]>,
    callback?: msRest.ServiceCallback<Models.Contact[]>
  ): Promise<Models.GetAllContactsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getAllContactsOperationSpec,
      callback
    ) as Promise<Models.GetAllContactsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLocationResponse>
   */
  getLocation(options?: Models.OriginationReadApiGetLocationOptionalParams): Promise<Models.GetLocationResponse>;
  /**
   * @param callback The callback
   */
  getLocation(callback: msRest.ServiceCallback<Models.Location>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLocation(
    options: Models.OriginationReadApiGetLocationOptionalParams,
    callback: msRest.ServiceCallback<Models.Location>
  ): void;
  getLocation(
    options?: Models.OriginationReadApiGetLocationOptionalParams | msRest.ServiceCallback<Models.Location>,
    callback?: msRest.ServiceCallback<Models.Location>
  ): Promise<Models.GetLocationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLocationOperationSpec,
      callback
    ) as Promise<Models.GetLocationResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCustomerResponse>
   */
  getCustomer(customerId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetCustomerResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getCustomer(customerId: string, callback: msRest.ServiceCallback<Models.Customer>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCustomer(
    customerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Customer>
  ): void;
  getCustomer(
    customerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Customer>,
    callback?: msRest.ServiceCallback<Models.Customer>
  ): Promise<Models.GetCustomerResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getCustomerOperationSpec,
      callback
    ) as Promise<Models.GetCustomerResponse>;
  }

  /**
   * @param email
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCustomerOnEmailResponse>
   */
  getCustomerOnEmail(email: string, options?: msRest.RequestOptionsBase): Promise<Models.GetCustomerOnEmailResponse>;
  /**
   * @param email
   * @param callback The callback
   */
  getCustomerOnEmail(email: string, callback: msRest.ServiceCallback<Models.Customer>): void;
  /**
   * @param email
   * @param options The optional parameters
   * @param callback The callback
   */
  getCustomerOnEmail(
    email: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Customer>
  ): void;
  getCustomerOnEmail(
    email: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Customer>,
    callback?: msRest.ServiceCallback<Models.Customer>
  ): Promise<Models.GetCustomerOnEmailResponse> {
    return this.sendOperationRequest(
      {
        email,
        options
      },
      getCustomerOnEmailOperationSpec,
      callback
    ) as Promise<Models.GetCustomerOnEmailResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCustomersResponse>
   */
  getCustomers(options?: Models.OriginationReadApiGetCustomersOptionalParams): Promise<Models.GetCustomersResponse>;
  /**
   * @param callback The callback
   */
  getCustomers(callback: msRest.ServiceCallback<Models.CustomerProjectionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCustomers(
    options: Models.OriginationReadApiGetCustomersOptionalParams,
    callback: msRest.ServiceCallback<Models.CustomerProjectionPagedList>
  ): void;
  getCustomers(
    options?:
      | Models.OriginationReadApiGetCustomersOptionalParams
      | msRest.ServiceCallback<Models.CustomerProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.CustomerProjectionPagedList>
  ): Promise<Models.GetCustomersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCustomersOperationSpec,
      callback
    ) as Promise<Models.GetCustomersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SearchCustomersResponse>
   */
  searchCustomers(
    options?: Models.OriginationReadApiSearchCustomersOptionalParams
  ): Promise<Models.SearchCustomersResponse>;
  /**
   * @param callback The callback
   */
  searchCustomers(callback: msRest.ServiceCallback<Models.CustomerProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  searchCustomers(
    options: Models.OriginationReadApiSearchCustomersOptionalParams,
    callback: msRest.ServiceCallback<Models.CustomerProjection[]>
  ): void;
  searchCustomers(
    options?:
      | Models.OriginationReadApiSearchCustomersOptionalParams
      | msRest.ServiceCallback<Models.CustomerProjection[]>,
    callback?: msRest.ServiceCallback<Models.CustomerProjection[]>
  ): Promise<Models.SearchCustomersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      searchCustomersOperationSpec,
      callback
    ) as Promise<Models.SearchCustomersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAuthorizationTokenResponse>
   */
  getAuthorizationToken(
    options?: Models.OriginationReadApiGetAuthorizationTokenOptionalParams
  ): Promise<Models.GetAuthorizationTokenResponse>;
  /**
   * @param callback The callback
   */
  getAuthorizationToken(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAuthorizationToken(
    options: Models.OriginationReadApiGetAuthorizationTokenOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getAuthorizationToken(
    options?: Models.OriginationReadApiGetAuthorizationTokenOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetAuthorizationTokenResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAuthorizationTokenOperationSpec,
      callback
    ) as Promise<Models.GetAuthorizationTokenResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDashboardAccountsResponse>
   */
  getDashboardAccounts(
    options?: Models.OriginationReadApiGetDashboardAccountsOptionalParams
  ): Promise<Models.GetDashboardAccountsResponse>;
  /**
   * @param callback The callback
   */
  getDashboardAccounts(callback: msRest.ServiceCallback<Models.DashboardAccounts>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDashboardAccounts(
    options: Models.OriginationReadApiGetDashboardAccountsOptionalParams,
    callback: msRest.ServiceCallback<Models.DashboardAccounts>
  ): void;
  getDashboardAccounts(
    options?:
      | Models.OriginationReadApiGetDashboardAccountsOptionalParams
      | msRest.ServiceCallback<Models.DashboardAccounts>,
    callback?: msRest.ServiceCallback<Models.DashboardAccounts>
  ): Promise<Models.GetDashboardAccountsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDashboardAccountsOperationSpec,
      callback
    ) as Promise<Models.GetDashboardAccountsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDashboardLoanResponse>
   */
  getDashboardLoan(
    options?: Models.OriginationReadApiGetDashboardLoanOptionalParams
  ): Promise<Models.GetDashboardLoanResponse>;
  /**
   * @param callback The callback
   */
  getDashboardLoan(callback: msRest.ServiceCallback<Models.DashboardLoan>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDashboardLoan(
    options: Models.OriginationReadApiGetDashboardLoanOptionalParams,
    callback: msRest.ServiceCallback<Models.DashboardLoan>
  ): void;
  getDashboardLoan(
    options?: Models.OriginationReadApiGetDashboardLoanOptionalParams | msRest.ServiceCallback<Models.DashboardLoan>,
    callback?: msRest.ServiceCallback<Models.DashboardLoan>
  ): Promise<Models.GetDashboardLoanResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDashboardLoanOperationSpec,
      callback
    ) as Promise<Models.GetDashboardLoanResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDashboardBusinessPerformanceResponse>
   */
  getDashboardBusinessPerformance(
    options?: Models.OriginationReadApiGetDashboardBusinessPerformanceOptionalParams
  ): Promise<Models.GetDashboardBusinessPerformanceResponse>;
  /**
   * @param callback The callback
   */
  getDashboardBusinessPerformance(callback: msRest.ServiceCallback<Models.DashboardBusinessPerformance>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDashboardBusinessPerformance(
    options: Models.OriginationReadApiGetDashboardBusinessPerformanceOptionalParams,
    callback: msRest.ServiceCallback<Models.DashboardBusinessPerformance>
  ): void;
  getDashboardBusinessPerformance(
    options?:
      | Models.OriginationReadApiGetDashboardBusinessPerformanceOptionalParams
      | msRest.ServiceCallback<Models.DashboardBusinessPerformance>,
    callback?: msRest.ServiceCallback<Models.DashboardBusinessPerformance>
  ): Promise<Models.GetDashboardBusinessPerformanceResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDashboardBusinessPerformanceOperationSpec,
      callback
    ) as Promise<Models.GetDashboardBusinessPerformanceResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDashboardInsightResponse>
   */
  getDashboardInsight(
    options?: Models.OriginationReadApiGetDashboardInsightOptionalParams
  ): Promise<Models.GetDashboardInsightResponse>;
  /**
   * @param callback The callback
   */
  getDashboardInsight(callback: msRest.ServiceCallback<Models.DashboardInsight>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDashboardInsight(
    options: Models.OriginationReadApiGetDashboardInsightOptionalParams,
    callback: msRest.ServiceCallback<Models.DashboardInsight>
  ): void;
  getDashboardInsight(
    options?:
      | Models.OriginationReadApiGetDashboardInsightOptionalParams
      | msRest.ServiceCallback<Models.DashboardInsight>,
    callback?: msRest.ServiceCallback<Models.DashboardInsight>
  ): Promise<Models.GetDashboardInsightResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDashboardInsightOperationSpec,
      callback
    ) as Promise<Models.GetDashboardInsightResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDashboardCashFlowsResponse>
   */
  getDashboardCashFlows(
    options?: Models.OriginationReadApiGetDashboardCashFlowsOptionalParams
  ): Promise<Models.GetDashboardCashFlowsResponse>;
  /**
   * @param callback The callback
   */
  getDashboardCashFlows(callback: msRest.ServiceCallback<Models.DashboardCashFlows>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDashboardCashFlows(
    options: Models.OriginationReadApiGetDashboardCashFlowsOptionalParams,
    callback: msRest.ServiceCallback<Models.DashboardCashFlows>
  ): void;
  getDashboardCashFlows(
    options?:
      | Models.OriginationReadApiGetDashboardCashFlowsOptionalParams
      | msRest.ServiceCallback<Models.DashboardCashFlows>,
    callback?: msRest.ServiceCallback<Models.DashboardCashFlows>
  ): Promise<Models.GetDashboardCashFlowsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDashboardCashFlowsOperationSpec,
      callback
    ) as Promise<Models.GetDashboardCashFlowsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDashboardOutgoingInoviceResponse>
   */
  getDashboardOutgoingInovice(
    options?: Models.OriginationReadApiGetDashboardOutgoingInoviceOptionalParams
  ): Promise<Models.GetDashboardOutgoingInoviceResponse>;
  /**
   * @param callback The callback
   */
  getDashboardOutgoingInovice(callback: msRest.ServiceCallback<Models.DashboardOutgoingInvoices>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDashboardOutgoingInovice(
    options: Models.OriginationReadApiGetDashboardOutgoingInoviceOptionalParams,
    callback: msRest.ServiceCallback<Models.DashboardOutgoingInvoices>
  ): void;
  getDashboardOutgoingInovice(
    options?:
      | Models.OriginationReadApiGetDashboardOutgoingInoviceOptionalParams
      | msRest.ServiceCallback<Models.DashboardOutgoingInvoices>,
    callback?: msRest.ServiceCallback<Models.DashboardOutgoingInvoices>
  ): Promise<Models.GetDashboardOutgoingInoviceResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDashboardOutgoingInoviceOperationSpec,
      callback
    ) as Promise<Models.GetDashboardOutgoingInoviceResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  build(options?: Models.OriginationReadApiBuildOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  build(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  build(options: Models.OriginationReadApiBuildOptionalParams, callback: msRest.ServiceCallback<void>): void;
  build(
    options?: Models.OriginationReadApiBuildOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanRepaymentResponse>
   */
  getLoanRepayment(
    options?: Models.OriginationReadApiGetLoanRepaymentOptionalParams
  ): Promise<Models.GetLoanRepaymentResponse>;
  /**
   * @param callback The callback
   */
  getLoanRepayment(callback: msRest.ServiceCallback<Models.DashboardLoanRepayment>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanRepayment(
    options: Models.OriginationReadApiGetLoanRepaymentOptionalParams,
    callback: msRest.ServiceCallback<Models.DashboardLoanRepayment>
  ): void;
  getLoanRepayment(
    options?:
      | Models.OriginationReadApiGetLoanRepaymentOptionalParams
      | msRest.ServiceCallback<Models.DashboardLoanRepayment>,
    callback?: msRest.ServiceCallback<Models.DashboardLoanRepayment>
  ): Promise<Models.GetLoanRepaymentResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanRepaymentOperationSpec,
      callback
    ) as Promise<Models.GetLoanRepaymentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanUtilisationResponse>
   */
  getLoanUtilisation(
    options?: Models.OriginationReadApiGetLoanUtilisationOptionalParams
  ): Promise<Models.GetLoanUtilisationResponse>;
  /**
   * @param callback The callback
   */
  getLoanUtilisation(callback: msRest.ServiceCallback<Models.DashboardCreditUtilisation>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanUtilisation(
    options: Models.OriginationReadApiGetLoanUtilisationOptionalParams,
    callback: msRest.ServiceCallback<Models.DashboardCreditUtilisation>
  ): void;
  getLoanUtilisation(
    options?:
      | Models.OriginationReadApiGetLoanUtilisationOptionalParams
      | msRest.ServiceCallback<Models.DashboardCreditUtilisation>,
    callback?: msRest.ServiceCallback<Models.DashboardCreditUtilisation>
  ): Promise<Models.GetLoanUtilisationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanUtilisationOperationSpec,
      callback
    ) as Promise<Models.GetLoanUtilisationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanFundingResponse>
   */
  getLoanFunding(
    options?: Models.OriginationReadApiGetLoanFundingOptionalParams
  ): Promise<Models.GetLoanFundingResponse>;
  /**
   * @param callback The callback
   */
  getLoanFunding(callback: msRest.ServiceCallback<Models.DashboardFundUtilisation[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanFunding(
    options: Models.OriginationReadApiGetLoanFundingOptionalParams,
    callback: msRest.ServiceCallback<Models.DashboardFundUtilisation[]>
  ): void;
  getLoanFunding(
    options?:
      | Models.OriginationReadApiGetLoanFundingOptionalParams
      | msRest.ServiceCallback<Models.DashboardFundUtilisation[]>,
    callback?: msRest.ServiceCallback<Models.DashboardFundUtilisation[]>
  ): Promise<Models.GetLoanFundingResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanFundingOperationSpec,
      callback
    ) as Promise<Models.GetLoanFundingResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllDisplayMessageResponse>
   */
  getAllDisplayMessage(options?: msRest.RequestOptionsBase): Promise<Models.GetAllDisplayMessageResponse>;
  /**
   * @param callback The callback
   */
  getAllDisplayMessage(callback: msRest.ServiceCallback<Models.DisplayMessageModal[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllDisplayMessage(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.DisplayMessageModal[]>
  ): void;
  getAllDisplayMessage(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DisplayMessageModal[]>,
    callback?: msRest.ServiceCallback<Models.DisplayMessageModal[]>
  ): Promise<Models.GetAllDisplayMessageResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllDisplayMessageOperationSpec,
      callback
    ) as Promise<Models.GetAllDisplayMessageResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDisplayMessageForLoanResponse>
   */
  getDisplayMessageForLoan(
    options?: Models.OriginationReadApiGetDisplayMessageForLoanOptionalParams
  ): Promise<Models.GetDisplayMessageForLoanResponse>;
  /**
   * @param callback The callback
   */
  getDisplayMessageForLoan(callback: msRest.ServiceCallback<Models.DisplayMessageModal[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDisplayMessageForLoan(
    options: Models.OriginationReadApiGetDisplayMessageForLoanOptionalParams,
    callback: msRest.ServiceCallback<Models.DisplayMessageModal[]>
  ): void;
  getDisplayMessageForLoan(
    options?:
      | Models.OriginationReadApiGetDisplayMessageForLoanOptionalParams
      | msRest.ServiceCallback<Models.DisplayMessageModal[]>,
    callback?: msRest.ServiceCallback<Models.DisplayMessageModal[]>
  ): Promise<Models.GetDisplayMessageForLoanResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDisplayMessageForLoanOperationSpec,
      callback
    ) as Promise<Models.GetDisplayMessageForLoanResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllQuestionMessageResponse>
   */
  getAllQuestionMessage(options?: msRest.RequestOptionsBase): Promise<Models.GetAllQuestionMessageResponse>;
  /**
   * @param callback The callback
   */
  getAllQuestionMessage(callback: msRest.ServiceCallback<Models.QuestionMessageModal[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllQuestionMessage(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.QuestionMessageModal[]>
  ): void;
  getAllQuestionMessage(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.QuestionMessageModal[]>,
    callback?: msRest.ServiceCallback<Models.QuestionMessageModal[]>
  ): Promise<Models.GetAllQuestionMessageResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllQuestionMessageOperationSpec,
      callback
    ) as Promise<Models.GetAllQuestionMessageResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetQuestionMessageForLoanResponse>
   */
  getQuestionMessageForLoan(
    options?: Models.OriginationReadApiGetQuestionMessageForLoanOptionalParams
  ): Promise<Models.GetQuestionMessageForLoanResponse>;
  /**
   * @param callback The callback
   */
  getQuestionMessageForLoan(callback: msRest.ServiceCallback<Models.QuestionMessageModal[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getQuestionMessageForLoan(
    options: Models.OriginationReadApiGetQuestionMessageForLoanOptionalParams,
    callback: msRest.ServiceCallback<Models.QuestionMessageModal[]>
  ): void;
  getQuestionMessageForLoan(
    options?:
      | Models.OriginationReadApiGetQuestionMessageForLoanOptionalParams
      | msRest.ServiceCallback<Models.QuestionMessageModal[]>,
    callback?: msRest.ServiceCallback<Models.QuestionMessageModal[]>
  ): Promise<Models.GetQuestionMessageForLoanResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getQuestionMessageForLoanOperationSpec,
      callback
    ) as Promise<Models.GetQuestionMessageForLoanResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllQuestionMessageForLoanResponse>
   */
  getAllQuestionMessageForLoan(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllQuestionMessageForLoanResponse>;
  /**
   * @param callback The callback
   */
  getAllQuestionMessageForLoan(
    callback: msRest.ServiceCallback<{ [propertyName: string]: Models.QuestionMessageModal[] }>
  ): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllQuestionMessageForLoan(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: Models.QuestionMessageModal[] }>
  ): void;
  getAllQuestionMessageForLoan(
    options?:
      | msRest.RequestOptionsBase
      | msRest.ServiceCallback<{ [propertyName: string]: Models.QuestionMessageModal[] }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: Models.QuestionMessageModal[] }>
  ): Promise<Models.GetAllQuestionMessageForLoanResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllQuestionMessageForLoanOperationSpec,
      callback
    ) as Promise<Models.GetAllQuestionMessageForLoanResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSystemMessageResponse>
   */
  getSystemMessage(
    options?: Models.OriginationReadApiGetSystemMessageOptionalParams
  ): Promise<Models.GetSystemMessageResponse>;
  /**
   * @param callback The callback
   */
  getSystemMessage(callback: msRest.ServiceCallback<Models.SystemMessageModal>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getSystemMessage(
    options: Models.OriginationReadApiGetSystemMessageOptionalParams,
    callback: msRest.ServiceCallback<Models.SystemMessageModal>
  ): void;
  getSystemMessage(
    options?:
      | Models.OriginationReadApiGetSystemMessageOptionalParams
      | msRest.ServiceCallback<Models.SystemMessageModal>,
    callback?: msRest.ServiceCallback<Models.SystemMessageModal>
  ): Promise<Models.GetSystemMessageResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getSystemMessageOperationSpec,
      callback
    ) as Promise<Models.GetSystemMessageResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDocumentsPagedResponse>
   */
  getDocumentsPaged(
    applicationId: string,
    options?: Models.OriginationReadApiGetDocumentsPagedOptionalParams
  ): Promise<Models.GetDocumentsPagedResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getDocumentsPaged(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.DatedTransactionDataFilePagedList>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getDocumentsPaged(
    applicationId: string,
    options: Models.OriginationReadApiGetDocumentsPagedOptionalParams,
    callback: msRest.ServiceCallback<Models.DatedTransactionDataFilePagedList>
  ): void;
  getDocumentsPaged(
    applicationId: string,
    options?:
      | Models.OriginationReadApiGetDocumentsPagedOptionalParams
      | msRest.ServiceCallback<Models.DatedTransactionDataFilePagedList>,
    callback?: msRest.ServiceCallback<Models.DatedTransactionDataFilePagedList>
  ): Promise<Models.GetDocumentsPagedResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getDocumentsPagedOperationSpec,
      callback
    ) as Promise<Models.GetDocumentsPagedResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadDocumentResponse>
   */
  downloadDocument(
    applicationId: string,
    options?: Models.OriginationReadApiDownloadDocumentOptionalParams
  ): Promise<Models.DownloadDocumentResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  downloadDocument(applicationId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadDocument(
    applicationId: string,
    options: Models.OriginationReadApiDownloadDocumentOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  downloadDocument(
    applicationId: string,
    options?:
      | Models.OriginationReadApiDownloadDocumentOptionalParams
      | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.DownloadDocumentResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      downloadDocumentOperationSpec,
      callback
    ) as Promise<Models.DownloadDocumentResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadDocumentsResponse>
   */
  downloadDocuments(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.DownloadDocumentsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  downloadDocuments(applicationId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadDocuments(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionDataFile[]>
  ): void;
  downloadDocuments(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile[]>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile[]>
  ): Promise<Models.DownloadDocumentsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      downloadDocumentsOperationSpec,
      callback
    ) as Promise<Models.DownloadDocumentsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetEarlyRepaymentDetailsResponse>
   */
  getEarlyRepaymentDetails(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetEarlyRepaymentDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getEarlyRepaymentDetails(loanId: string, callback: msRest.ServiceCallback<Models.EarlyRepaymentDetail>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getEarlyRepaymentDetails(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.EarlyRepaymentDetail>
  ): void;
  getEarlyRepaymentDetails(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.EarlyRepaymentDetail>,
    callback?: msRest.ServiceCallback<Models.EarlyRepaymentDetail>
  ): Promise<Models.GetEarlyRepaymentDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getEarlyRepaymentDetailsOperationSpec,
      callback
    ) as Promise<Models.GetEarlyRepaymentDetailsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllContractDocumentsResponse>
   */
  getAllContractDocuments(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllContractDocumentsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getAllContractDocuments(applicationId: string, callback: msRest.ServiceCallback<Models.ContractDocument[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllContractDocuments(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ContractDocument[]>
  ): void;
  getAllContractDocuments(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ContractDocument[]>,
    callback?: msRest.ServiceCallback<Models.ContractDocument[]>
  ): Promise<Models.GetAllContractDocumentsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getAllContractDocumentsOperationSpec,
      callback
    ) as Promise<Models.GetAllContractDocumentsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllContractDocumentsPagedResponse>
   */
  getAllContractDocumentsPaged(
    applicationId: string,
    options?: Models.OriginationReadApiGetAllContractDocumentsPagedOptionalParams
  ): Promise<Models.GetAllContractDocumentsPagedResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getAllContractDocumentsPaged(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.ContractDocumentPagedList>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllContractDocumentsPaged(
    applicationId: string,
    options: Models.OriginationReadApiGetAllContractDocumentsPagedOptionalParams,
    callback: msRest.ServiceCallback<Models.ContractDocumentPagedList>
  ): void;
  getAllContractDocumentsPaged(
    applicationId: string,
    options?:
      | Models.OriginationReadApiGetAllContractDocumentsPagedOptionalParams
      | msRest.ServiceCallback<Models.ContractDocumentPagedList>,
    callback?: msRest.ServiceCallback<Models.ContractDocumentPagedList>
  ): Promise<Models.GetAllContractDocumentsPagedResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getAllContractDocumentsPagedOperationSpec,
      callback
    ) as Promise<Models.GetAllContractDocumentsPagedResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetContractDetailsResponse>
   */
  getContractDetails(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetContractDetailsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getContractDetails(applicationId: string, callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getContractDetails(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): void;
  getContractDetails(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: string }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): Promise<Models.GetContractDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getContractDetailsOperationSpec,
      callback
    ) as Promise<Models.GetContractDetailsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DeletePastContractResponse>
   */
  deletePastContract(
    applicationId: string,
    options?: Models.OriginationReadApiDeletePastContractOptionalParams
  ): Promise<Models.DeletePastContractResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  deletePastContract(applicationId: string, callback: msRest.ServiceCallback<Models.ContractDocumentPagedList>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  deletePastContract(
    applicationId: string,
    options: Models.OriginationReadApiDeletePastContractOptionalParams,
    callback: msRest.ServiceCallback<Models.ContractDocumentPagedList>
  ): void;
  deletePastContract(
    applicationId: string,
    options?:
      | Models.OriginationReadApiDeletePastContractOptionalParams
      | msRest.ServiceCallback<Models.ContractDocumentPagedList>,
    callback?: msRest.ServiceCallback<Models.ContractDocumentPagedList>
  ): Promise<Models.DeletePastContractResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      deletePastContractOperationSpec,
      callback
    ) as Promise<Models.DeletePastContractResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBaseSupportDocumentResponse>
   */
  getBaseSupportDocument(options?: msRest.RequestOptionsBase): Promise<Models.GetBaseSupportDocumentResponse>;
  /**
   * @param callback The callback
   */
  getBaseSupportDocument(callback: msRest.ServiceCallback<Models.ContractDocument[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBaseSupportDocument(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ContractDocument[]>
  ): void;
  getBaseSupportDocument(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ContractDocument[]>,
    callback?: msRest.ServiceCallback<Models.ContractDocument[]>
  ): Promise<Models.GetBaseSupportDocumentResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBaseSupportDocumentOperationSpec,
      callback
    ) as Promise<Models.GetBaseSupportDocumentResponse>;
  }

  /**
   * @param documentId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadContractFileOnDocumentIdResponse>
   */
  downloadContractFileOnDocumentId(
    documentId: string,
    options?: Models.OriginationReadApiDownloadContractFileOnDocumentIdOptionalParams
  ): Promise<Models.DownloadContractFileOnDocumentIdResponse>;
  /**
   * @param documentId
   * @param callback The callback
   */
  downloadContractFileOnDocumentId(
    documentId: string,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  /**
   * @param documentId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadContractFileOnDocumentId(
    documentId: string,
    options: Models.OriginationReadApiDownloadContractFileOnDocumentIdOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  downloadContractFileOnDocumentId(
    documentId: string,
    options?:
      | Models.OriginationReadApiDownloadContractFileOnDocumentIdOptionalParams
      | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.DownloadContractFileOnDocumentIdResponse> {
    return this.sendOperationRequest(
      {
        documentId,
        options
      },
      downloadContractFileOnDocumentIdOperationSpec,
      callback
    ) as Promise<Models.DownloadContractFileOnDocumentIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetW9FormSampleDocumentResponse>
   */
  getW9FormSampleDocument(options?: msRest.RequestOptionsBase): Promise<Models.GetW9FormSampleDocumentResponse>;
  /**
   * @param callback The callback
   */
  getW9FormSampleDocument(callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getW9FormSampleDocument(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  getW9FormSampleDocument(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.GetW9FormSampleDocumentResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getW9FormSampleDocumentOperationSpec,
      callback
    ) as Promise<Models.GetW9FormSampleDocumentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetIntelligenceListResponse>
   */
  getIntelligenceList(
    options?: Models.OriginationReadApiGetIntelligenceListOptionalParams
  ): Promise<Models.GetIntelligenceListResponse>;
  /**
   * @param callback The callback
   */
  getIntelligenceList(callback: msRest.ServiceCallback<Models.IntelligenceResult[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getIntelligenceList(
    options: Models.OriginationReadApiGetIntelligenceListOptionalParams,
    callback: msRest.ServiceCallback<Models.IntelligenceResult[]>
  ): void;
  getIntelligenceList(
    options?:
      | Models.OriginationReadApiGetIntelligenceListOptionalParams
      | msRest.ServiceCallback<Models.IntelligenceResult[]>,
    callback?: msRest.ServiceCallback<Models.IntelligenceResult[]>
  ): Promise<Models.GetIntelligenceListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getIntelligenceListOperationSpec,
      callback
    ) as Promise<Models.GetIntelligenceListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsLegalFlaggedResponse>
   */
  isLegalFlagged(
    options?: Models.OriginationReadApiIsLegalFlaggedOptionalParams
  ): Promise<Models.IsLegalFlaggedResponse>;
  /**
   * @param callback The callback
   */
  isLegalFlagged(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isLegalFlagged(
    options: Models.OriginationReadApiIsLegalFlaggedOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isLegalFlagged(
    options?: Models.OriginationReadApiIsLegalFlaggedOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsLegalFlaggedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isLegalFlaggedOperationSpec,
      callback
    ) as Promise<Models.IsLegalFlaggedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetInvestorsResponse>
   */
  getInvestors(options?: Models.OriginationReadApiGetInvestorsOptionalParams): Promise<Models.GetInvestorsResponse>;
  /**
   * @param callback The callback
   */
  getInvestors(callback: msRest.ServiceCallback<Models.CustomerProjectionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getInvestors(
    options: Models.OriginationReadApiGetInvestorsOptionalParams,
    callback: msRest.ServiceCallback<Models.CustomerProjectionPagedList>
  ): void;
  getInvestors(
    options?:
      | Models.OriginationReadApiGetInvestorsOptionalParams
      | msRest.ServiceCallback<Models.CustomerProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.CustomerProjectionPagedList>
  ): Promise<Models.GetInvestorsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getInvestorsOperationSpec,
      callback
    ) as Promise<Models.GetInvestorsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsBankTransactionDataStaleResponse>
   */
  isBankTransactionDataStale(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsBankTransactionDataStaleResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isBankTransactionDataStale(loanId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isBankTransactionDataStale(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isBankTransactionDataStale(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsBankTransactionDataStaleResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isBankTransactionDataStaleOperationSpec,
      callback
    ) as Promise<Models.IsBankTransactionDataStaleResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankTransactionDataStaleMessageResponse>
   */
  getBankTransactionDataStaleMessage(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetBankTransactionDataStaleMessageResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getBankTransactionDataStaleMessage(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankTransactionDataStaleMessage(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getBankTransactionDataStaleMessage(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetBankTransactionDataStaleMessageResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getBankTransactionDataStaleMessageOperationSpec,
      callback
    ) as Promise<Models.GetBankTransactionDataStaleMessageResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLastDisableReasonResponse>
   */
  getLastDisableReason(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetLastDisableReasonResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLastDisableReason(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLastDisableReason(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getLastDisableReason(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetLastDisableReasonResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLastDisableReasonOperationSpec,
      callback
    ) as Promise<Models.GetLastDisableReasonResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllTestNameDisableReasonsResponse>
   */
  getAllTestNameDisableReasons(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllTestNameDisableReasonsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getAllTestNameDisableReasons(loanId: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllTestNameDisableReasons(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getAllTestNameDisableReasons(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetAllTestNameDisableReasonsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getAllTestNameDisableReasonsOperationSpec,
      callback
    ) as Promise<Models.GetAllTestNameDisableReasonsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllLoansTestNameDisableReasonsResponse>
   */
  getAllLoansTestNameDisableReasons(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllLoansTestNameDisableReasonsResponse>;
  /**
   * @param callback The callback
   */
  getAllLoansTestNameDisableReasons(callback: msRest.ServiceCallback<Models.HealthRuleDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllLoansTestNameDisableReasons(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.HealthRuleDetails[]>
  ): void;
  getAllLoansTestNameDisableReasons(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.HealthRuleDetails[]>,
    callback?: msRest.ServiceCallback<Models.HealthRuleDetails[]>
  ): Promise<Models.GetAllLoansTestNameDisableReasonsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllLoansTestNameDisableReasonsOperationSpec,
      callback
    ) as Promise<Models.GetAllLoansTestNameDisableReasonsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllInternalDisableReasonsResponse>
   */
  getAllInternalDisableReasons(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllInternalDisableReasonsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getAllInternalDisableReasons(loanId: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllInternalDisableReasons(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getAllInternalDisableReasons(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetAllInternalDisableReasonsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getAllInternalDisableReasonsOperationSpec,
      callback
    ) as Promise<Models.GetAllInternalDisableReasonsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllExternalDisableReasonsResponse>
   */
  getAllExternalDisableReasons(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllExternalDisableReasonsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getAllExternalDisableReasons(loanId: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllExternalDisableReasons(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getAllExternalDisableReasons(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetAllExternalDisableReasonsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getAllExternalDisableReasonsOperationSpec,
      callback
    ) as Promise<Models.GetAllExternalDisableReasonsResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoansOnCustomerIdResponse>
   */
  getLoansOnCustomerId(
    customerId: string,
    options?: Models.OriginationReadApiGetLoansOnCustomerIdOptionalParams
  ): Promise<Models.GetLoansOnCustomerIdResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getLoansOnCustomerId(
    customerId: string,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoansOnCustomerId(
    customerId: string,
    options: Models.OriginationReadApiGetLoansOnCustomerIdOptionalParams,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): void;
  getLoansOnCustomerId(
    customerId: string,
    options?:
      | Models.OriginationReadApiGetLoansOnCustomerIdOptionalParams
      | msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): Promise<Models.GetLoansOnCustomerIdResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getLoansOnCustomerIdOperationSpec,
      callback
    ) as Promise<Models.GetLoansOnCustomerIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPaymentQueriesResponse>
   */
  getPaymentQueries(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPaymentQueriesResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getPaymentQueries(loanId: string, callback: msRest.ServiceCallback<Models.PaymentQuery[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPaymentQueries(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PaymentQuery[]>
  ): void;
  getPaymentQueries(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PaymentQuery[]>,
    callback?: msRest.ServiceCallback<Models.PaymentQuery[]>
  ): Promise<Models.GetPaymentQueriesResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getPaymentQueriesOperationSpec,
      callback
    ) as Promise<Models.GetPaymentQueriesResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanReadyApplicationsOnCustomerIdResponse>
   */
  getLoanReadyApplicationsOnCustomerId(
    customerId: string,
    options?: Models.OriginationReadApiGetLoanReadyApplicationsOnCustomerIdOptionalParams
  ): Promise<Models.GetLoanReadyApplicationsOnCustomerIdResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getLoanReadyApplicationsOnCustomerId(
    customerId: string,
    callback: msRest.ServiceCallback<Models.LoanReadyApplicationPagedList>
  ): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanReadyApplicationsOnCustomerId(
    customerId: string,
    options: Models.OriginationReadApiGetLoanReadyApplicationsOnCustomerIdOptionalParams,
    callback: msRest.ServiceCallback<Models.LoanReadyApplicationPagedList>
  ): void;
  getLoanReadyApplicationsOnCustomerId(
    customerId: string,
    options?:
      | Models.OriginationReadApiGetLoanReadyApplicationsOnCustomerIdOptionalParams
      | msRest.ServiceCallback<Models.LoanReadyApplicationPagedList>,
    callback?: msRest.ServiceCallback<Models.LoanReadyApplicationPagedList>
  ): Promise<Models.GetLoanReadyApplicationsOnCustomerIdResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getLoanReadyApplicationsOnCustomerIdOperationSpec,
      callback
    ) as Promise<Models.GetLoanReadyApplicationsOnCustomerIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCommissionOnLoanResponse>
   */
  getCommissionOnLoan(
    loanId: string,
    options?: Models.OriginationReadApiGetCommissionOnLoanOptionalParams
  ): Promise<Models.GetCommissionOnLoanResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getCommissionOnLoan(loanId: string, callback: msRest.ServiceCallback<Models.Commission>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCommissionOnLoan(
    loanId: string,
    options: Models.OriginationReadApiGetCommissionOnLoanOptionalParams,
    callback: msRest.ServiceCallback<Models.Commission>
  ): void;
  getCommissionOnLoan(
    loanId: string,
    options?: Models.OriginationReadApiGetCommissionOnLoanOptionalParams | msRest.ServiceCallback<Models.Commission>,
    callback?: msRest.ServiceCallback<Models.Commission>
  ): Promise<Models.GetCommissionOnLoanResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getCommissionOnLoanOperationSpec,
      callback
    ) as Promise<Models.GetCommissionOnLoanResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPaymentArrangementsResponse>
   */
  getPaymentArrangements(
    loanId: string,
    options?: Models.OriginationReadApiGetPaymentArrangementsOptionalParams
  ): Promise<Models.GetPaymentArrangementsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getPaymentArrangements(loanId: string, callback: msRest.ServiceCallback<Models.PaymentArrangement[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPaymentArrangements(
    loanId: string,
    options: Models.OriginationReadApiGetPaymentArrangementsOptionalParams,
    callback: msRest.ServiceCallback<Models.PaymentArrangement[]>
  ): void;
  getPaymentArrangements(
    loanId: string,
    options?:
      | Models.OriginationReadApiGetPaymentArrangementsOptionalParams
      | msRest.ServiceCallback<Models.PaymentArrangement[]>,
    callback?: msRest.ServiceCallback<Models.PaymentArrangement[]>
  ): Promise<Models.GetPaymentArrangementsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getPaymentArrangementsOperationSpec,
      callback
    ) as Promise<Models.GetPaymentArrangementsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanNotificationsResponse>
   */
  getLoanNotifications(
    loanId: string,
    options?: Models.OriginationReadApiGetLoanNotificationsOptionalParams
  ): Promise<Models.GetLoanNotificationsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanNotifications(
    loanId: string,
    callback: msRest.ServiceCallback<Models.LoanNotificationProjectionPagedList>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanNotifications(
    loanId: string,
    options: Models.OriginationReadApiGetLoanNotificationsOptionalParams,
    callback: msRest.ServiceCallback<Models.LoanNotificationProjectionPagedList>
  ): void;
  getLoanNotifications(
    loanId: string,
    options?:
      | Models.OriginationReadApiGetLoanNotificationsOptionalParams
      | msRest.ServiceCallback<Models.LoanNotificationProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.LoanNotificationProjectionPagedList>
  ): Promise<Models.GetLoanNotificationsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanNotificationsOperationSpec,
      callback
    ) as Promise<Models.GetLoanNotificationsResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanByOrganisationIdResponse>
   */
  getLoanByOrganisationId(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetLoanByOrganisationIdResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getLoanByOrganisationId(
    organisationId: string,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanByOrganisationId(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): void;
  getLoanByOrganisationId(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjection>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): Promise<Models.GetLoanByOrganisationIdResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getLoanByOrganisationIdOperationSpec,
      callback
    ) as Promise<Models.GetLoanByOrganisationIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanResponse>
   */
  getLoan(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoanResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoan(loanId: string, callback: msRest.ServiceCallback<Models.CustomerLoanProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoan(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): void;
  getLoan(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjection>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): Promise<Models.GetLoanResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanOperationSpec,
      callback
    ) as Promise<Models.GetLoanResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanByApplicationIdResponse>
   */
  getLoanByApplicationId(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetLoanByApplicationIdResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getLoanByApplicationId(applicationId: string, callback: msRest.ServiceCallback<Models.CustomerLoanProjection>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanByApplicationId(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): void;
  getLoanByApplicationId(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjection>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): Promise<Models.GetLoanByApplicationIdResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getLoanByApplicationIdOperationSpec,
      callback
    ) as Promise<Models.GetLoanByApplicationIdResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanDataByOrganistionIdResponse>
   */
  getLoanDataByOrganistionId(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetLoanDataByOrganistionIdResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getLoanDataByOrganistionId(
    organisationId: string,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanDataByOrganistionId(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): void;
  getLoanDataByOrganistionId(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjection>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): Promise<Models.GetLoanDataByOrganistionIdResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getLoanDataByOrganistionIdOperationSpec,
      callback
    ) as Promise<Models.GetLoanDataByOrganistionIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanDataByLoanIdResponse>
   */
  getLoanDataByLoanId(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoanDataByLoanIdResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanDataByLoanId(loanId: string, callback: msRest.ServiceCallback<Models.CustomerLoanProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanDataByLoanId(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): void;
  getLoanDataByLoanId(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjection>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjection>
  ): Promise<Models.GetLoanDataByLoanIdResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanDataByLoanIdOperationSpec,
      callback
    ) as Promise<Models.GetLoanDataByLoanIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanBalancesResponse>
   */
  getLoanBalances(
    loanId: string,
    options?: Models.OriginationReadApiGetLoanBalancesOptionalParams
  ): Promise<Models.GetLoanBalancesResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanBalances(loanId: string, callback: msRest.ServiceCallback<Models.LoanBalances>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanBalances(
    loanId: string,
    options: Models.OriginationReadApiGetLoanBalancesOptionalParams,
    callback: msRest.ServiceCallback<Models.LoanBalances>
  ): void;
  getLoanBalances(
    loanId: string,
    options?: Models.OriginationReadApiGetLoanBalancesOptionalParams | msRest.ServiceCallback<Models.LoanBalances>,
    callback?: msRest.ServiceCallback<Models.LoanBalances>
  ): Promise<Models.GetLoanBalancesResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanBalancesOperationSpec,
      callback
    ) as Promise<Models.GetLoanBalancesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetEveryLoanBalancesResponse>
   */
  getEveryLoanBalances(options?: msRest.RequestOptionsBase): Promise<Models.GetEveryLoanBalancesResponse>;
  /**
   * @param callback The callback
   */
  getEveryLoanBalances(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getEveryLoanBalances(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  getEveryLoanBalances(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetEveryLoanBalancesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getEveryLoanBalancesOperationSpec,
      callback
    ) as Promise<Models.GetEveryLoanBalancesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  getEveryLoanBalancesCsvLocal(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  getEveryLoanBalancesCsvLocal(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getEveryLoanBalancesCsvLocal(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  getEveryLoanBalancesCsvLocal(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getEveryLoanBalancesCsvLocalOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllLoanBalancesResponse>
   */
  getAllLoanBalances(
    loanId: string,
    options?: Models.OriginationReadApiGetAllLoanBalancesOptionalParams
  ): Promise<Models.GetAllLoanBalancesResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getAllLoanBalances(loanId: string, callback: msRest.ServiceCallback<Models.AllLoanBalances>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllLoanBalances(
    loanId: string,
    options: Models.OriginationReadApiGetAllLoanBalancesOptionalParams,
    callback: msRest.ServiceCallback<Models.AllLoanBalances>
  ): void;
  getAllLoanBalances(
    loanId: string,
    options?:
      | Models.OriginationReadApiGetAllLoanBalancesOptionalParams
      | msRest.ServiceCallback<Models.AllLoanBalances>,
    callback?: msRest.ServiceCallback<Models.AllLoanBalances>
  ): Promise<Models.GetAllLoanBalancesResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getAllLoanBalancesOperationSpec,
      callback
    ) as Promise<Models.GetAllLoanBalancesResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanSettingsResponse>
   */
  getLoanSettings(
    loanId: string,
    options?: Models.OriginationReadApiGetLoanSettingsOptionalParams
  ): Promise<Models.GetLoanSettingsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanSettings(loanId: string, callback: msRest.ServiceCallback<Models.LoanSettings>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanSettings(
    loanId: string,
    options: Models.OriginationReadApiGetLoanSettingsOptionalParams,
    callback: msRest.ServiceCallback<Models.LoanSettings>
  ): void;
  getLoanSettings(
    loanId: string,
    options?: Models.OriginationReadApiGetLoanSettingsOptionalParams | msRest.ServiceCallback<Models.LoanSettings>,
    callback?: msRest.ServiceCallback<Models.LoanSettings>
  ): Promise<Models.GetLoanSettingsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanSettingsOperationSpec,
      callback
    ) as Promise<Models.GetLoanSettingsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllLoanDataResponse>
   */
  getAllLoanData(options?: msRest.RequestOptionsBase): Promise<Models.GetAllLoanDataResponse>;
  /**
   * @param callback The callback
   */
  getAllLoanData(callback: msRest.ServiceCallback<Models.CustomerLoanProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllLoanData(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjection[]>
  ): void;
  getAllLoanData(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjection[]>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjection[]>
  ): Promise<Models.GetAllLoanDataResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllLoanDataOperationSpec,
      callback
    ) as Promise<Models.GetAllLoanDataResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllLoanDataApplicationIdResponse>
   */
  getAllLoanDataApplicationId(
    options?: Models.OriginationReadApiGetAllLoanDataApplicationIdOptionalParams
  ): Promise<Models.GetAllLoanDataApplicationIdResponse>;
  /**
   * @param callback The callback
   */
  getAllLoanDataApplicationId(callback: msRest.ServiceCallback<Models.CustomerLoanProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllLoanDataApplicationId(
    options: Models.OriginationReadApiGetAllLoanDataApplicationIdOptionalParams,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjection[]>
  ): void;
  getAllLoanDataApplicationId(
    options?:
      | Models.OriginationReadApiGetAllLoanDataApplicationIdOptionalParams
      | msRest.ServiceCallback<Models.CustomerLoanProjection[]>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjection[]>
  ): Promise<Models.GetAllLoanDataApplicationIdResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllLoanDataApplicationIdOperationSpec,
      callback
    ) as Promise<Models.GetAllLoanDataApplicationIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoansResponse>
   */
  getLoans(options?: Models.OriginationReadApiGetLoansOptionalParams): Promise<Models.GetLoansResponse>;
  /**
   * @param callback The callback
   */
  getLoans(callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoans(
    options: Models.OriginationReadApiGetLoansOptionalParams,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): void;
  getLoans(
    options?:
      | Models.OriginationReadApiGetLoansOptionalParams
      | msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): Promise<Models.GetLoansResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoansOperationSpec,
      callback
    ) as Promise<Models.GetLoansResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetClosedLoansResponse>
   */
  getClosedLoans(options?: msRest.RequestOptionsBase): Promise<Models.GetClosedLoansResponse>;
  /**
   * @param callback The callback
   */
  getClosedLoans(callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getClosedLoans(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): void;
  getClosedLoans(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): Promise<Models.GetClosedLoansResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getClosedLoansOperationSpec,
      callback
    ) as Promise<Models.GetClosedLoansResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetEnabledLoansResponse>
   */
  getEnabledLoans(options?: msRest.RequestOptionsBase): Promise<Models.GetEnabledLoansResponse>;
  /**
   * @param callback The callback
   */
  getEnabledLoans(callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getEnabledLoans(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): void;
  getEnabledLoans(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): Promise<Models.GetEnabledLoansResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getEnabledLoansOperationSpec,
      callback
    ) as Promise<Models.GetEnabledLoansResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDisabledLoansResponse>
   */
  getDisabledLoans(options?: msRest.RequestOptionsBase): Promise<Models.GetDisabledLoansResponse>;
  /**
   * @param callback The callback
   */
  getDisabledLoans(callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDisabledLoans(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): void;
  getDisabledLoans(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): Promise<Models.GetDisabledLoansResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDisabledLoansOperationSpec,
      callback
    ) as Promise<Models.GetDisabledLoansResponse>;
  }

  /**
   * @param typeName
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoansByTypeResponse>
   */
  getLoansByType(typeName: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoansByTypeResponse>;
  /**
   * @param typeName
   * @param callback The callback
   */
  getLoansByType(typeName: string, callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>): void;
  /**
   * @param typeName
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoansByType(
    typeName: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): void;
  getLoansByType(
    typeName: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): Promise<Models.GetLoansByTypeResponse> {
    return this.sendOperationRequest(
      {
        typeName,
        options
      },
      getLoansByTypeOperationSpec,
      callback
    ) as Promise<Models.GetLoansByTypeResponse>;
  }

  /**
   * @param criteria
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoansByHealthResponse>
   */
  getLoansByHealth(criteria: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoansByHealthResponse>;
  /**
   * @param criteria
   * @param callback The callback
   */
  getLoansByHealth(criteria: string, callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>): void;
  /**
   * @param criteria
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoansByHealth(
    criteria: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): void;
  getLoansByHealth(
    criteria: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjectionPagedList>
  ): Promise<Models.GetLoansByHealthResponse> {
    return this.sendOperationRequest(
      {
        criteria,
        options
      },
      getLoansByHealthOperationSpec,
      callback
    ) as Promise<Models.GetLoansByHealthResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPaidCommissionsResponse>
   */
  getPaidCommissions(
    loanId: string,
    options?: Models.OriginationReadApiGetPaidCommissionsOptionalParams
  ): Promise<Models.GetPaidCommissionsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getPaidCommissions(loanId: string, callback: msRest.ServiceCallback<Models.PaidCommissionPagedList>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPaidCommissions(
    loanId: string,
    options: Models.OriginationReadApiGetPaidCommissionsOptionalParams,
    callback: msRest.ServiceCallback<Models.PaidCommissionPagedList>
  ): void;
  getPaidCommissions(
    loanId: string,
    options?:
      | Models.OriginationReadApiGetPaidCommissionsOptionalParams
      | msRest.ServiceCallback<Models.PaidCommissionPagedList>,
    callback?: msRest.ServiceCallback<Models.PaidCommissionPagedList>
  ): Promise<Models.GetPaidCommissionsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getPaidCommissionsOperationSpec,
      callback
    ) as Promise<Models.GetPaidCommissionsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.DebtorSummaryResponse>
   */
  debtorSummary(
    loanId: string,
    options?: Models.OriginationReadApiDebtorSummaryOptionalParams
  ): Promise<Models.DebtorSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  debtorSummary(loanId: string, callback: msRest.ServiceCallback<Models.LoanDebtorProjectionPagedList>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  debtorSummary(
    loanId: string,
    options: Models.OriginationReadApiDebtorSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.LoanDebtorProjectionPagedList>
  ): void;
  debtorSummary(
    loanId: string,
    options?:
      | Models.OriginationReadApiDebtorSummaryOptionalParams
      | msRest.ServiceCallback<Models.LoanDebtorProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.LoanDebtorProjectionPagedList>
  ): Promise<Models.DebtorSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      debtorSummaryOperationSpec,
      callback
    ) as Promise<Models.DebtorSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.TransactionSummaryResponse>
   */
  transactionSummary(
    loanId: string,
    options?: Models.OriginationReadApiTransactionSummaryOptionalParams
  ): Promise<Models.TransactionSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  transactionSummary(loanId: string, callback: msRest.ServiceCallback<Models.TransactionProjectionPagedList>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  transactionSummary(
    loanId: string,
    options: Models.OriginationReadApiTransactionSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionProjectionPagedList>
  ): void;
  transactionSummary(
    loanId: string,
    options?:
      | Models.OriginationReadApiTransactionSummaryOptionalParams
      | msRest.ServiceCallback<Models.TransactionProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.TransactionProjectionPagedList>
  ): Promise<Models.TransactionSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      transactionSummaryOperationSpec,
      callback
    ) as Promise<Models.TransactionSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.TransactionsOutstandingBalanceResponse>
   */
  transactionsOutstandingBalance(
    loanId: string,
    options?: Models.OriginationReadApiTransactionsOutstandingBalanceOptionalParams
  ): Promise<Models.TransactionsOutstandingBalanceResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  transactionsOutstandingBalance(loanId: string, callback: msRest.ServiceCallback<number>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  transactionsOutstandingBalance(
    loanId: string,
    options: Models.OriginationReadApiTransactionsOutstandingBalanceOptionalParams,
    callback: msRest.ServiceCallback<number>
  ): void;
  transactionsOutstandingBalance(
    loanId: string,
    options?: Models.OriginationReadApiTransactionsOutstandingBalanceOptionalParams | msRest.ServiceCallback<number>,
    callback?: msRest.ServiceCallback<number>
  ): Promise<Models.TransactionsOutstandingBalanceResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      transactionsOutstandingBalanceOperationSpec,
      callback
    ) as Promise<Models.TransactionsOutstandingBalanceResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTransactionBalanceResponse>
   */
  getTransactionBalance(
    loanId: string,
    options?: Models.OriginationReadApiGetTransactionBalanceOptionalParams
  ): Promise<Models.GetTransactionBalanceResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getTransactionBalance(loanId: string, callback: msRest.ServiceCallback<number>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getTransactionBalance(
    loanId: string,
    options: Models.OriginationReadApiGetTransactionBalanceOptionalParams,
    callback: msRest.ServiceCallback<number>
  ): void;
  getTransactionBalance(
    loanId: string,
    options?: Models.OriginationReadApiGetTransactionBalanceOptionalParams | msRest.ServiceCallback<number>,
    callback?: msRest.ServiceCallback<number>
  ): Promise<Models.GetTransactionBalanceResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getTransactionBalanceOperationSpec,
      callback
    ) as Promise<Models.GetTransactionBalanceResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllTransactionsResponse>
   */
  getAllTransactions(
    options?: Models.OriginationReadApiGetAllTransactionsOptionalParams
  ): Promise<Models.GetAllTransactionsResponse>;
  /**
   * @param callback The callback
   */
  getAllTransactions(callback: msRest.ServiceCallback<Models.TransactionProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllTransactions(
    options: Models.OriginationReadApiGetAllTransactionsOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionProjection[]>
  ): void;
  getAllTransactions(
    options?:
      | Models.OriginationReadApiGetAllTransactionsOptionalParams
      | msRest.ServiceCallback<Models.TransactionProjection[]>,
    callback?: msRest.ServiceCallback<Models.TransactionProjection[]>
  ): Promise<Models.GetAllTransactionsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllTransactionsOperationSpec,
      callback
    ) as Promise<Models.GetAllTransactionsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllRepaymentTransactionsResponse>
   */
  getAllRepaymentTransactions(
    options?: Models.OriginationReadApiGetAllRepaymentTransactionsOptionalParams
  ): Promise<Models.GetAllRepaymentTransactionsResponse>;
  /**
   * @param callback The callback
   */
  getAllRepaymentTransactions(callback: msRest.ServiceCallback<Models.TransactionProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllRepaymentTransactions(
    options: Models.OriginationReadApiGetAllRepaymentTransactionsOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionProjection[]>
  ): void;
  getAllRepaymentTransactions(
    options?:
      | Models.OriginationReadApiGetAllRepaymentTransactionsOptionalParams
      | msRest.ServiceCallback<Models.TransactionProjection[]>,
    callback?: msRest.ServiceCallback<Models.TransactionProjection[]>
  ): Promise<Models.GetAllRepaymentTransactionsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllRepaymentTransactionsOperationSpec,
      callback
    ) as Promise<Models.GetAllRepaymentTransactionsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllRepaymentTransactionsPerLoanResponse>
   */
  getAllRepaymentTransactionsPerLoan(
    options?: Models.OriginationReadApiGetAllRepaymentTransactionsPerLoanOptionalParams
  ): Promise<Models.GetAllRepaymentTransactionsPerLoanResponse>;
  /**
   * @param callback The callback
   */
  getAllRepaymentTransactionsPerLoan(callback: msRest.ServiceCallback<Models.TransactionProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllRepaymentTransactionsPerLoan(
    options: Models.OriginationReadApiGetAllRepaymentTransactionsPerLoanOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionProjection[]>
  ): void;
  getAllRepaymentTransactionsPerLoan(
    options?:
      | Models.OriginationReadApiGetAllRepaymentTransactionsPerLoanOptionalParams
      | msRest.ServiceCallback<Models.TransactionProjection[]>,
    callback?: msRest.ServiceCallback<Models.TransactionProjection[]>
  ): Promise<Models.GetAllRepaymentTransactionsPerLoanResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllRepaymentTransactionsPerLoanOperationSpec,
      callback
    ) as Promise<Models.GetAllRepaymentTransactionsPerLoanResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.TransactionSummaryOnIdsResponse>
   */
  transactionSummaryOnIds(
    loanId: string,
    options?: Models.OriginationReadApiTransactionSummaryOnIdsOptionalParams
  ): Promise<Models.TransactionSummaryOnIdsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  transactionSummaryOnIds(loanId: string, callback: msRest.ServiceCallback<Models.TransactionProjection[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  transactionSummaryOnIds(
    loanId: string,
    options: Models.OriginationReadApiTransactionSummaryOnIdsOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionProjection[]>
  ): void;
  transactionSummaryOnIds(
    loanId: string,
    options?:
      | Models.OriginationReadApiTransactionSummaryOnIdsOptionalParams
      | msRest.ServiceCallback<Models.TransactionProjection[]>,
    callback?: msRest.ServiceCallback<Models.TransactionProjection[]>
  ): Promise<Models.TransactionSummaryOnIdsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      transactionSummaryOnIdsOperationSpec,
      callback
    ) as Promise<Models.TransactionSummaryOnIdsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.AdvanceSummaryResponse>
   */
  advanceSummary(
    loanId: string,
    options?: Models.OriginationReadApiAdvanceSummaryOptionalParams
  ): Promise<Models.AdvanceSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  advanceSummary(loanId: string, callback: msRest.ServiceCallback<Models.AdvanceProjectionPagedList>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  advanceSummary(
    loanId: string,
    options: Models.OriginationReadApiAdvanceSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionPagedList>
  ): void;
  advanceSummary(
    loanId: string,
    options?:
      | Models.OriginationReadApiAdvanceSummaryOptionalParams
      | msRest.ServiceCallback<Models.AdvanceProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.AdvanceProjectionPagedList>
  ): Promise<Models.AdvanceSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      advanceSummaryOperationSpec,
      callback
    ) as Promise<Models.AdvanceSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.AdvanceFullSummaryResponse>
   */
  advanceFullSummary(
    loanId: string,
    options?: Models.OriginationReadApiAdvanceFullSummaryOptionalParams
  ): Promise<Models.AdvanceFullSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  advanceFullSummary(
    loanId: string,
    callback: msRest.ServiceCallback<Models.AdvanceAdjustmentProjectionPagedList>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  advanceFullSummary(
    loanId: string,
    options: Models.OriginationReadApiAdvanceFullSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceAdjustmentProjectionPagedList>
  ): void;
  advanceFullSummary(
    loanId: string,
    options?:
      | Models.OriginationReadApiAdvanceFullSummaryOptionalParams
      | msRest.ServiceCallback<Models.AdvanceAdjustmentProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.AdvanceAdjustmentProjectionPagedList>
  ): Promise<Models.AdvanceFullSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      advanceFullSummaryOperationSpec,
      callback
    ) as Promise<Models.AdvanceFullSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.AdvanceExtendedSummaryResponse>
   */
  advanceExtendedSummary(
    loanId: string,
    options?: Models.OriginationReadApiAdvanceExtendedSummaryOptionalParams
  ): Promise<Models.AdvanceExtendedSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  advanceExtendedSummary(
    loanId: string,
    callback: msRest.ServiceCallback<Models.ExtendedAdvanceProjectionPagedList>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  advanceExtendedSummary(
    loanId: string,
    options: Models.OriginationReadApiAdvanceExtendedSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.ExtendedAdvanceProjectionPagedList>
  ): void;
  advanceExtendedSummary(
    loanId: string,
    options?:
      | Models.OriginationReadApiAdvanceExtendedSummaryOptionalParams
      | msRest.ServiceCallback<Models.ExtendedAdvanceProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.ExtendedAdvanceProjectionPagedList>
  ): Promise<Models.AdvanceExtendedSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      advanceExtendedSummaryOperationSpec,
      callback
    ) as Promise<Models.AdvanceExtendedSummaryResponse>;
  }

  /**
   * @param loanId
   * @param advanceId
   * @param [options] The optional parameters
   * @returns Promise<Models.AdvanceHistoryDetailsResponse>
   */
  advanceHistoryDetails(
    loanId: string,
    advanceId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.AdvanceHistoryDetailsResponse>;
  /**
   * @param loanId
   * @param advanceId
   * @param callback The callback
   */
  advanceHistoryDetails(
    loanId: string,
    advanceId: string,
    callback: msRest.ServiceCallback<Models.AdvanceProjection>
  ): void;
  /**
   * @param loanId
   * @param advanceId
   * @param options The optional parameters
   * @param callback The callback
   */
  advanceHistoryDetails(
    loanId: string,
    advanceId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.AdvanceProjection>
  ): void;
  advanceHistoryDetails(
    loanId: string,
    advanceId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AdvanceProjection>,
    callback?: msRest.ServiceCallback<Models.AdvanceProjection>
  ): Promise<Models.AdvanceHistoryDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        advanceId,
        options
      },
      advanceHistoryDetailsOperationSpec,
      callback
    ) as Promise<Models.AdvanceHistoryDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CashflowDetailsMethodResponse>
   */
  cashflowDetailsMethod(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.CashflowDetailsMethodResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  cashflowDetailsMethod(loanId: string, callback: msRest.ServiceCallback<Models.CashflowDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  cashflowDetailsMethod(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CashflowDetails>
  ): void;
  cashflowDetailsMethod(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CashflowDetails>,
    callback?: msRest.ServiceCallback<Models.CashflowDetails>
  ): Promise<Models.CashflowDetailsMethodResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      cashflowDetailsMethodOperationSpec,
      callback
    ) as Promise<Models.CashflowDetailsMethodResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IncomeDetailsMethodResponse>
   */
  incomeDetailsMethod(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.IncomeDetailsMethodResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  incomeDetailsMethod(loanId: string, callback: msRest.ServiceCallback<Models.IncomeDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  incomeDetailsMethod(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.IncomeDetails>
  ): void;
  incomeDetailsMethod(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.IncomeDetails>,
    callback?: msRest.ServiceCallback<Models.IncomeDetails>
  ): Promise<Models.IncomeDetailsMethodResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      incomeDetailsMethodOperationSpec,
      callback
    ) as Promise<Models.IncomeDetailsMethodResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateFixedRepaymentResponse>
   */
  calculateFixedRepayment(
    loanId: string,
    options?: Models.OriginationReadApiCalculateFixedRepaymentOptionalParams
  ): Promise<Models.CalculateFixedRepaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  calculateFixedRepayment(loanId: string, callback: msRest.ServiceCallback<Models.FixedRepaymentCalculation>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateFixedRepayment(
    loanId: string,
    options: Models.OriginationReadApiCalculateFixedRepaymentOptionalParams,
    callback: msRest.ServiceCallback<Models.FixedRepaymentCalculation>
  ): void;
  calculateFixedRepayment(
    loanId: string,
    options?:
      | Models.OriginationReadApiCalculateFixedRepaymentOptionalParams
      | msRest.ServiceCallback<Models.FixedRepaymentCalculation>,
    callback?: msRest.ServiceCallback<Models.FixedRepaymentCalculation>
  ): Promise<Models.CalculateFixedRepaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      calculateFixedRepaymentOperationSpec,
      callback
    ) as Promise<Models.CalculateFixedRepaymentResponse>;
  }

  /**
   * @param loanId
   * @param amount
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateLineOfCreditSliderResponse>
   */
  calculateLineOfCreditSlider(
    loanId: string,
    amount: number,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.CalculateLineOfCreditSliderResponse>;
  /**
   * @param loanId
   * @param amount
   * @param callback The callback
   */
  calculateLineOfCreditSlider(
    loanId: string,
    amount: number,
    callback: msRest.ServiceCallback<{ [propertyName: string]: number }>
  ): void;
  /**
   * @param loanId
   * @param amount
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateLineOfCreditSlider(
    loanId: string,
    amount: number,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: number }>
  ): void;
  calculateLineOfCreditSlider(
    loanId: string,
    amount: number,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: number }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: number }>
  ): Promise<Models.CalculateLineOfCreditSliderResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        amount,
        options
      },
      calculateLineOfCreditSliderOperationSpec,
      callback
    ) as Promise<Models.CalculateLineOfCreditSliderResponse>;
  }

  /**
   * @param loanId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateLineOfCreditRepaymentResponse>
   */
  calculateLineOfCreditRepayment(
    loanId: string,
    amount: number,
    percentageOfIncome: number,
    fixedRepaymentCalculation: boolean,
    term: number,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.CalculateLineOfCreditRepaymentResponse>;
  /**
   * @param loanId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param callback The callback
   */
  calculateLineOfCreditRepayment(
    loanId: string,
    amount: number,
    percentageOfIncome: number,
    fixedRepaymentCalculation: boolean,
    term: number,
    callback: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>
  ): void;
  /**
   * @param loanId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateLineOfCreditRepayment(
    loanId: string,
    amount: number,
    percentageOfIncome: number,
    fixedRepaymentCalculation: boolean,
    term: number,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>
  ): void;
  calculateLineOfCreditRepayment(
    loanId: string,
    amount: number,
    percentageOfIncome: number,
    fixedRepaymentCalculation: boolean,
    term: number,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>,
    callback?: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>
  ): Promise<Models.CalculateLineOfCreditRepaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        amount,
        percentageOfIncome,
        fixedRepaymentCalculation,
        term,
        options
      },
      calculateLineOfCreditRepaymentOperationSpec,
      callback
    ) as Promise<Models.CalculateLineOfCreditRepaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculatePercentOfIncomeResponse>
   */
  calculatePercentOfIncome(
    loanId: string,
    options?: Models.OriginationReadApiCalculatePercentOfIncomeOptionalParams
  ): Promise<Models.CalculatePercentOfIncomeResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  calculatePercentOfIncome(loanId: string, callback: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculatePercentOfIncome(
    loanId: string,
    options: Models.OriginationReadApiCalculatePercentOfIncomeOptionalParams,
    callback: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>
  ): void;
  calculatePercentOfIncome(
    loanId: string,
    options?:
      | Models.OriginationReadApiCalculatePercentOfIncomeOptionalParams
      | msRest.ServiceCallback<Models.PercentOfIncomeCalculation>,
    callback?: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>
  ): Promise<Models.CalculatePercentOfIncomeResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      calculatePercentOfIncomeOperationSpec,
      callback
    ) as Promise<Models.CalculatePercentOfIncomeResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateInvoicePaymentResponse>
   */
  calculateInvoicePayment(
    loanId: string,
    options?: Models.OriginationReadApiCalculateInvoicePaymentOptionalParams
  ): Promise<Models.CalculateInvoicePaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  calculateInvoicePayment(loanId: string, callback: msRest.ServiceCallback<Models.InvoicePaymentCalculation>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateInvoicePayment(
    loanId: string,
    options: Models.OriginationReadApiCalculateInvoicePaymentOptionalParams,
    callback: msRest.ServiceCallback<Models.InvoicePaymentCalculation>
  ): void;
  calculateInvoicePayment(
    loanId: string,
    options?:
      | Models.OriginationReadApiCalculateInvoicePaymentOptionalParams
      | msRest.ServiceCallback<Models.InvoicePaymentCalculation>,
    callback?: msRest.ServiceCallback<Models.InvoicePaymentCalculation>
  ): Promise<Models.CalculateInvoicePaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      calculateInvoicePaymentOperationSpec,
      callback
    ) as Promise<Models.CalculateInvoicePaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetMaximumTermOnLoanIdResponse>
   */
  getMaximumTermOnLoanId(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetMaximumTermOnLoanIdResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getMaximumTermOnLoanId(loanId: string, callback: msRest.ServiceCallback<number>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getMaximumTermOnLoanId(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<number>
  ): void;
  getMaximumTermOnLoanId(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<number>,
    callback?: msRest.ServiceCallback<number>
  ): Promise<Models.GetMaximumTermOnLoanIdResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getMaximumTermOnLoanIdOperationSpec,
      callback
    ) as Promise<Models.GetMaximumTermOnLoanIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateAllLatestAdvanceResponse>
   */
  calculateAllLatestAdvance(options?: msRest.RequestOptionsBase): Promise<Models.CalculateAllLatestAdvanceResponse>;
  /**
   * @param callback The callback
   */
  calculateAllLatestAdvance(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateAllLatestAdvance(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  calculateAllLatestAdvance(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.CalculateAllLatestAdvanceResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      calculateAllLatestAdvanceOperationSpec,
      callback
    ) as Promise<Models.CalculateAllLatestAdvanceResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsLatestAdvanceValidResponse>
   */
  isLatestAdvanceValid(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsLatestAdvanceValidResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isLatestAdvanceValid(loanId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isLatestAdvanceValid(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isLatestAdvanceValid(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsLatestAdvanceValidResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isLatestAdvanceValidOperationSpec,
      callback
    ) as Promise<Models.IsLatestAdvanceValidResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankDetailsResponse>
   */
  getBankDetails(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetBankDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getBankDetails(loanId: string, callback: msRest.ServiceCallback<Models.BankDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankDetails(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BankDetails>
  ): void;
  getBankDetails(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BankDetails>,
    callback?: msRest.ServiceCallback<Models.BankDetails>
  ): Promise<Models.GetBankDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getBankDetailsOperationSpec,
      callback
    ) as Promise<Models.GetBankDetailsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllBankDetailsResponse>
   */
  getAllBankDetails(options?: msRest.RequestOptionsBase): Promise<Models.GetAllBankDetailsResponse>;
  /**
   * @param callback The callback
   */
  getAllBankDetails(callback: msRest.ServiceCallback<{ [propertyName: string]: Models.BankDetails }>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllBankDetails(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: Models.BankDetails }>
  ): void;
  getAllBankDetails(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: Models.BankDetails }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: Models.BankDetails }>
  ): Promise<Models.GetAllBankDetailsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllBankDetailsOperationSpec,
      callback
    ) as Promise<Models.GetAllBankDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsBankSetupResponse>
   */
  isBankSetup(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsBankSetupResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isBankSetup(loanId: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isBankSetup(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BoolWrapper>
  ): void;
  isBankSetup(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>,
    callback?: msRest.ServiceCallback<Models.BoolWrapper>
  ): Promise<Models.IsBankSetupResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isBankSetupOperationSpec,
      callback
    ) as Promise<Models.IsBankSetupResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsChangeOfFacilityRequiredResponse>
   */
  isChangeOfFacilityRequired(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsChangeOfFacilityRequiredResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isChangeOfFacilityRequired(loanId: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isChangeOfFacilityRequired(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BoolWrapper>
  ): void;
  isChangeOfFacilityRequired(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>,
    callback?: msRest.ServiceCallback<Models.BoolWrapper>
  ): Promise<Models.IsChangeOfFacilityRequiredResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isChangeOfFacilityRequiredOperationSpec,
      callback
    ) as Promise<Models.IsChangeOfFacilityRequiredResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetNewFacilityLimitResponse>
   */
  getNewFacilityLimit(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetNewFacilityLimitResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getNewFacilityLimit(loanId: string, callback: msRest.ServiceCallback<number>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getNewFacilityLimit(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<number>
  ): void;
  getNewFacilityLimit(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<number>,
    callback?: msRest.ServiceCallback<number>
  ): Promise<Models.GetNewFacilityLimitResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getNewFacilityLimitOperationSpec,
      callback
    ) as Promise<Models.GetNewFacilityLimitResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetHighestFacilityLimitResponse>
   */
  getHighestFacilityLimit(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetHighestFacilityLimitResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getHighestFacilityLimit(loanId: string, callback: msRest.ServiceCallback<number>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getHighestFacilityLimit(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<number>
  ): void;
  getHighestFacilityLimit(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<number>,
    callback?: msRest.ServiceCallback<number>
  ): Promise<Models.GetHighestFacilityLimitResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getHighestFacilityLimitOperationSpec,
      callback
    ) as Promise<Models.GetHighestFacilityLimitResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanApplicationResponse>
   */
  getLoanApplication(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoanApplicationResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanApplication(loanId: string, callback: msRest.ServiceCallback<Models.ApplicationListProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanApplication(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): void;
  getLoanApplication(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationListProjection>,
    callback?: msRest.ServiceCallback<Models.ApplicationListProjection>
  ): Promise<Models.GetLoanApplicationResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanApplicationOperationSpec,
      callback
    ) as Promise<Models.GetLoanApplicationResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsConnectedResponse>
   */
  isConnected(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsConnectedResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isConnected(loanId: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isConnected(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BoolWrapper>
  ): void;
  isConnected(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>,
    callback?: msRest.ServiceCallback<Models.BoolWrapper>
  ): Promise<Models.IsConnectedResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isConnectedOperationSpec,
      callback
    ) as Promise<Models.IsConnectedResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsAccountingSystemConnectedResponse>
   */
  isAccountingSystemConnected(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsAccountingSystemConnectedResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isAccountingSystemConnected(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isAccountingSystemConnected(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  isAccountingSystemConnected(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.IsAccountingSystemConnectedResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isAccountingSystemConnectedOperationSpec,
      callback
    ) as Promise<Models.IsAccountingSystemConnectedResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadTransactionFileResponse>
   */
  downloadTransactionFile(
    loanId: string,
    options?: Models.OriginationReadApiDownloadTransactionFileOptionalParams
  ): Promise<Models.DownloadTransactionFileResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  downloadTransactionFile(loanId: string, callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadTransactionFile(
    loanId: string,
    options: Models.OriginationReadApiDownloadTransactionFileOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  downloadTransactionFile(
    loanId: string,
    options?:
      | Models.OriginationReadApiDownloadTransactionFileOptionalParams
      | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.DownloadTransactionFileResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      downloadTransactionFileOperationSpec,
      callback
    ) as Promise<Models.DownloadTransactionFileResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadTransactionStatementResponse>
   */
  downloadTransactionStatement(
    loanId: string,
    options?: Models.OriginationReadApiDownloadTransactionStatementOptionalParams
  ): Promise<Models.DownloadTransactionStatementResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  downloadTransactionStatement(loanId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadTransactionStatement(
    loanId: string,
    options: Models.OriginationReadApiDownloadTransactionStatementOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  downloadTransactionStatement(
    loanId: string,
    options?:
      | Models.OriginationReadApiDownloadTransactionStatementOptionalParams
      | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.DownloadTransactionStatementResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      downloadTransactionStatementOperationSpec,
      callback
    ) as Promise<Models.DownloadTransactionStatementResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.LookupDebtorsResponse>
   */
  lookupDebtors(
    loanId: string,
    options?: Models.OriginationReadApiLookupDebtorsOptionalParams
  ): Promise<Models.LookupDebtorsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  lookupDebtors(loanId: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  lookupDebtors(
    loanId: string,
    options: Models.OriginationReadApiLookupDebtorsOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  lookupDebtors(
    loanId: string,
    options?: Models.OriginationReadApiLookupDebtorsOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.LookupDebtorsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      lookupDebtorsOperationSpec,
      callback
    ) as Promise<Models.LookupDebtorsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanCache(
    loanId: string,
    options?: Models.OriginationReadApiUpdateLoanCacheOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanCache(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanCache(
    loanId: string,
    options: Models.OriginationReadApiUpdateLoanCacheOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateLoanCache(
    loanId: string,
    options?: Models.OriginationReadApiUpdateLoanCacheOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanCacheOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanBalanceCache(
    loanId: string,
    options?: Models.OriginationReadApiUpdateLoanBalanceCacheOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanBalanceCache(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanBalanceCache(
    loanId: string,
    options: Models.OriginationReadApiUpdateLoanBalanceCacheOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateLoanBalanceCache(
    loanId: string,
    options?: Models.OriginationReadApiUpdateLoanBalanceCacheOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanBalanceCacheOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanSettingCache(
    loanId: string,
    options?: Models.OriginationReadApiUpdateLoanSettingCacheOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanSettingCache(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanSettingCache(
    loanId: string,
    options: Models.OriginationReadApiUpdateLoanSettingCacheOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateLoanSettingCache(
    loanId: string,
    options?: Models.OriginationReadApiUpdateLoanSettingCacheOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanSettingCacheOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsTransactionGreater60DaysResponse>
   */
  isTransactionGreater60Days(
    loanId: string,
    options?: Models.OriginationReadApiIsTransactionGreater60DaysOptionalParams
  ): Promise<Models.IsTransactionGreater60DaysResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isTransactionGreater60Days(loanId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isTransactionGreater60Days(
    loanId: string,
    options: Models.OriginationReadApiIsTransactionGreater60DaysOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isTransactionGreater60Days(
    loanId: string,
    options?: Models.OriginationReadApiIsTransactionGreater60DaysOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsTransactionGreater60DaysResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isTransactionGreater60DaysOperationSpec,
      callback
    ) as Promise<Models.IsTransactionGreater60DaysResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAccountingSystemResponse>
   */
  getAccountingSystem(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAccountingSystemResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getAccountingSystem(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAccountingSystem(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getAccountingSystem(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetAccountingSystemResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getAccountingSystemOperationSpec,
      callback
    ) as Promise<Models.GetAccountingSystemResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllLoansResponse>
   */
  getAllLoans(options?: msRest.RequestOptionsBase): Promise<Models.GetAllLoansResponse>;
  /**
   * @param callback The callback
   */
  getAllLoans(callback: msRest.ServiceCallback<Models.CustomerLoanProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllLoans(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLoanProjection[]>
  ): void;
  getAllLoans(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLoanProjection[]>,
    callback?: msRest.ServiceCallback<Models.CustomerLoanProjection[]>
  ): Promise<Models.GetAllLoansResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllLoansOperationSpec,
      callback
    ) as Promise<Models.GetAllLoansResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanStatusSummaryResponse>
   */
  getLoanStatusSummary(options?: msRest.RequestOptionsBase): Promise<Models.GetLoanStatusSummaryResponse>;
  /**
   * @param callback The callback
   */
  getLoanStatusSummary(callback: msRest.ServiceCallback<Models.StatusSummary[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanStatusSummary(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.StatusSummary[]>
  ): void;
  getLoanStatusSummary(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.StatusSummary[]>,
    callback?: msRest.ServiceCallback<Models.StatusSummary[]>
  ): Promise<Models.GetLoanStatusSummaryResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanStatusSummaryOperationSpec,
      callback
    ) as Promise<Models.GetLoanStatusSummaryResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllPaymentTransactionHealthCheckResponse>
   */
  getAllPaymentTransactionHealthCheck(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllPaymentTransactionHealthCheckResponse>;
  /**
   * @param callback The callback
   */
  getAllPaymentTransactionHealthCheck(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllPaymentTransactionHealthCheck(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getAllPaymentTransactionHealthCheck(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetAllPaymentTransactionHealthCheckResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllPaymentTransactionHealthCheckOperationSpec,
      callback
    ) as Promise<Models.GetAllPaymentTransactionHealthCheckResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPaymentTransactionHealthCheckResponse>
   */
  getPaymentTransactionHealthCheck(
    options?: Models.OriginationReadApiGetPaymentTransactionHealthCheckOptionalParams
  ): Promise<Models.GetPaymentTransactionHealthCheckResponse>;
  /**
   * @param callback The callback
   */
  getPaymentTransactionHealthCheck(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPaymentTransactionHealthCheck(
    options: Models.OriginationReadApiGetPaymentTransactionHealthCheckOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getPaymentTransactionHealthCheck(
    options?:
      | Models.OriginationReadApiGetPaymentTransactionHealthCheckOptionalParams
      | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetPaymentTransactionHealthCheckResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPaymentTransactionHealthCheckOperationSpec,
      callback
    ) as Promise<Models.GetPaymentTransactionHealthCheckResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetHealthCheckAllResponse>
   */
  getHealthCheckAll(options?: msRest.RequestOptionsBase): Promise<Models.GetHealthCheckAllResponse>;
  /**
   * @param callback The callback
   */
  getHealthCheckAll(callback: msRest.ServiceCallback<Models.HealthRuleDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getHealthCheckAll(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.HealthRuleDetails[]>
  ): void;
  getHealthCheckAll(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.HealthRuleDetails[]>,
    callback?: msRest.ServiceCallback<Models.HealthRuleDetails[]>
  ): Promise<Models.GetHealthCheckAllResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getHealthCheckAllOperationSpec,
      callback
    ) as Promise<Models.GetHealthCheckAllResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPaymentQueries1Response>
   */
  getPaymentQueries1(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPaymentQueries1Response>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getPaymentQueries1(loanId: string, callback: msRest.ServiceCallback<Models.PaymentQuery[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPaymentQueries1(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PaymentQuery[]>
  ): void;
  getPaymentQueries1(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PaymentQuery[]>,
    callback?: msRest.ServiceCallback<Models.PaymentQuery[]>
  ): Promise<Models.GetPaymentQueries1Response> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getPaymentQueries1OperationSpec,
      callback
    ) as Promise<Models.GetPaymentQueries1Response>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetReconciledPaymentsResponse>
   */
  getReconciledPayments(
    loanId: string,
    options?: Models.OriginationReadApiGetReconciledPaymentsOptionalParams
  ): Promise<Models.GetReconciledPaymentsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getReconciledPayments(loanId: string, callback: msRest.ServiceCallback<Models.ReconciledPaymentPagedList>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getReconciledPayments(
    loanId: string,
    options: Models.OriginationReadApiGetReconciledPaymentsOptionalParams,
    callback: msRest.ServiceCallback<Models.ReconciledPaymentPagedList>
  ): void;
  getReconciledPayments(
    loanId: string,
    options?:
      | Models.OriginationReadApiGetReconciledPaymentsOptionalParams
      | msRest.ServiceCallback<Models.ReconciledPaymentPagedList>,
    callback?: msRest.ServiceCallback<Models.ReconciledPaymentPagedList>
  ): Promise<Models.GetReconciledPaymentsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getReconciledPaymentsOperationSpec,
      callback
    ) as Promise<Models.GetReconciledPaymentsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPaymentReconciliationTransactionResponse>
   */
  getPaymentReconciliationTransaction(
    loanId: string,
    options?: Models.OriginationReadApiGetPaymentReconciliationTransactionOptionalParams
  ): Promise<Models.GetPaymentReconciliationTransactionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getPaymentReconciliationTransaction(
    loanId: string,
    callback: msRest.ServiceCallback<Models.PaymentAndTransactions[]>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPaymentReconciliationTransaction(
    loanId: string,
    options: Models.OriginationReadApiGetPaymentReconciliationTransactionOptionalParams,
    callback: msRest.ServiceCallback<Models.PaymentAndTransactions[]>
  ): void;
  getPaymentReconciliationTransaction(
    loanId: string,
    options?:
      | Models.OriginationReadApiGetPaymentReconciliationTransactionOptionalParams
      | msRest.ServiceCallback<Models.PaymentAndTransactions[]>,
    callback?: msRest.ServiceCallback<Models.PaymentAndTransactions[]>
  ): Promise<Models.GetPaymentReconciliationTransactionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getPaymentReconciliationTransactionOperationSpec,
      callback
    ) as Promise<Models.GetPaymentReconciliationTransactionResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSyntheticTransactionsResponse>
   */
  getSyntheticTransactions(
    loanId: string,
    options?: Models.OriginationReadApiGetSyntheticTransactionsOptionalParams
  ): Promise<Models.GetSyntheticTransactionsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getSyntheticTransactions(loanId: string, callback: msRest.ServiceCallback<Models.SyntheticPayment[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSyntheticTransactions(
    loanId: string,
    options: Models.OriginationReadApiGetSyntheticTransactionsOptionalParams,
    callback: msRest.ServiceCallback<Models.SyntheticPayment[]>
  ): void;
  getSyntheticTransactions(
    loanId: string,
    options?:
      | Models.OriginationReadApiGetSyntheticTransactionsOptionalParams
      | msRest.ServiceCallback<Models.SyntheticPayment[]>,
    callback?: msRest.ServiceCallback<Models.SyntheticPayment[]>
  ): Promise<Models.GetSyntheticTransactionsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getSyntheticTransactionsOperationSpec,
      callback
    ) as Promise<Models.GetSyntheticTransactionsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSyntheticTransactionsTotalResponse>
   */
  getSyntheticTransactionsTotal(
    loanId: string,
    options?: Models.OriginationReadApiGetSyntheticTransactionsTotalOptionalParams
  ): Promise<Models.GetSyntheticTransactionsTotalResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getSyntheticTransactionsTotal(loanId: string, callback: msRest.ServiceCallback<number>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSyntheticTransactionsTotal(
    loanId: string,
    options: Models.OriginationReadApiGetSyntheticTransactionsTotalOptionalParams,
    callback: msRest.ServiceCallback<number>
  ): void;
  getSyntheticTransactionsTotal(
    loanId: string,
    options?: Models.OriginationReadApiGetSyntheticTransactionsTotalOptionalParams | msRest.ServiceCallback<number>,
    callback?: msRest.ServiceCallback<number>
  ): Promise<Models.GetSyntheticTransactionsTotalResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getSyntheticTransactionsTotalOperationSpec,
      callback
    ) as Promise<Models.GetSyntheticTransactionsTotalResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLockDateResponse>
   */
  getLockDate(options?: msRest.RequestOptionsBase): Promise<Models.GetLockDateResponse>;
  /**
   * @param callback The callback
   */
  getLockDate(callback: msRest.ServiceCallback<Date>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLockDate(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Date>): void;
  getLockDate(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Date>,
    callback?: msRest.ServiceCallback<Date>
  ): Promise<Models.GetLockDateResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLockDateOperationSpec,
      callback
    ) as Promise<Models.GetLockDateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CustomerLookupOfflineResponse>
   */
  customerLookupOffline(options?: msRest.RequestOptionsBase): Promise<Models.CustomerLookupOfflineResponse>;
  /**
   * @param callback The callback
   */
  customerLookupOffline(callback: msRest.ServiceCallback<Models.CustomerLookupData[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  customerLookupOffline(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CustomerLookupData[]>
  ): void;
  customerLookupOffline(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLookupData[]>,
    callback?: msRest.ServiceCallback<Models.CustomerLookupData[]>
  ): Promise<Models.CustomerLookupOfflineResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      customerLookupOfflineOperationSpec,
      callback
    ) as Promise<Models.CustomerLookupOfflineResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CustomerLookupResponse>
   */
  customerLookup(
    options?: Models.OriginationReadApiCustomerLookupOptionalParams
  ): Promise<Models.CustomerLookupResponse>;
  /**
   * @param callback The callback
   */
  customerLookup(callback: msRest.ServiceCallback<Models.CustomerLookupData[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  customerLookup(
    options: Models.OriginationReadApiCustomerLookupOptionalParams,
    callback: msRest.ServiceCallback<Models.CustomerLookupData[]>
  ): void;
  customerLookup(
    options?:
      | Models.OriginationReadApiCustomerLookupOptionalParams
      | msRest.ServiceCallback<Models.CustomerLookupData[]>,
    callback?: msRest.ServiceCallback<Models.CustomerLookupData[]>
  ): Promise<Models.CustomerLookupResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      customerLookupOperationSpec,
      callback
    ) as Promise<Models.CustomerLookupResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankListResponse>
   */
  getBankList(options?: Models.OriginationReadApiGetBankListOptionalParams): Promise<Models.GetBankListResponse>;
  /**
   * @param callback The callback
   */
  getBankList(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankList(
    options: Models.OriginationReadApiGetBankListOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getBankList(
    options?: Models.OriginationReadApiGetBankListOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetBankListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBankListOperationSpec,
      callback
    ) as Promise<Models.GetBankListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankListDetailsResponse>
   */
  getBankListDetails(
    options?: Models.OriginationReadApiGetBankListDetailsOptionalParams
  ): Promise<Models.GetBankListDetailsResponse>;
  /**
   * @param callback The callback
   */
  getBankListDetails(callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsInstitution[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankListDetails(
    options: Models.OriginationReadApiGetBankListDetailsOptionalParams,
    callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsInstitution[]>
  ): void;
  getBankListDetails(
    options?:
      | Models.OriginationReadApiGetBankListDetailsOptionalParams
      | msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsInstitution[]>,
    callback?: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsInstitution[]>
  ): Promise<Models.GetBankListDetailsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBankListDetailsOperationSpec,
      callback
    ) as Promise<Models.GetBankListDetailsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSupportedAccountingSoftwareResponse>
   */
  getSupportedAccountingSoftware(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetSupportedAccountingSoftwareResponse>;
  /**
   * @param callback The callback
   */
  getSupportedAccountingSoftware(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getSupportedAccountingSoftware(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  getSupportedAccountingSoftware(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetSupportedAccountingSoftwareResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getSupportedAccountingSoftwareOperationSpec,
      callback
    ) as Promise<Models.GetSupportedAccountingSoftwareResponse>;
  }

  /**
   * @param zipCode
   * @param [options] The optional parameters
   * @returns Promise<Models.IsZipCodeValidResponse>
   */
  isZipCodeValid(zipCode: number, options?: msRest.RequestOptionsBase): Promise<Models.IsZipCodeValidResponse>;
  /**
   * @param zipCode
   * @param callback The callback
   */
  isZipCodeValid(zipCode: number, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param zipCode
   * @param options The optional parameters
   * @param callback The callback
   */
  isZipCodeValid(zipCode: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isZipCodeValid(
    zipCode: number,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsZipCodeValidResponse> {
    return this.sendOperationRequest(
      {
        zipCode,
        options
      },
      isZipCodeValidOperationSpec,
      callback
    ) as Promise<Models.IsZipCodeValidResponse>;
  }

  /**
   * @param accountingPackage
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAccountingAuthorizeUrlResponse>
   */
  getAccountingAuthorizeUrl(
    accountingPackage: string,
    organisationId: string,
    options?: Models.OriginationReadApiGetAccountingAuthorizeUrlOptionalParams
  ): Promise<Models.GetAccountingAuthorizeUrlResponse>;
  /**
   * @param accountingPackage
   * @param organisationId
   * @param callback The callback
   */
  getAccountingAuthorizeUrl(
    accountingPackage: string,
    organisationId: string,
    callback: msRest.ServiceCallback<string>
  ): void;
  /**
   * @param accountingPackage
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAccountingAuthorizeUrl(
    accountingPackage: string,
    organisationId: string,
    options: Models.OriginationReadApiGetAccountingAuthorizeUrlOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getAccountingAuthorizeUrl(
    accountingPackage: string,
    organisationId: string,
    options?: Models.OriginationReadApiGetAccountingAuthorizeUrlOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetAccountingAuthorizeUrlResponse> {
    return this.sendOperationRequest(
      {
        accountingPackage,
        organisationId,
        options
      },
      getAccountingAuthorizeUrlOperationSpec,
      callback
    ) as Promise<Models.GetAccountingAuthorizeUrlResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetReconnectAccountingAuthorizeUrlResponse>
   */
  getReconnectAccountingAuthorizeUrl(
    loanId: string,
    options?: Models.OriginationReadApiGetReconnectAccountingAuthorizeUrlOptionalParams
  ): Promise<Models.GetReconnectAccountingAuthorizeUrlResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getReconnectAccountingAuthorizeUrl(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getReconnectAccountingAuthorizeUrl(
    loanId: string,
    options: Models.OriginationReadApiGetReconnectAccountingAuthorizeUrlOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getReconnectAccountingAuthorizeUrl(
    loanId: string,
    options?:
      | Models.OriginationReadApiGetReconnectAccountingAuthorizeUrlOptionalParams
      | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetReconnectAccountingAuthorizeUrlResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getReconnectAccountingAuthorizeUrlOperationSpec,
      callback
    ) as Promise<Models.GetReconnectAccountingAuthorizeUrlResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SearchByUsIndustryNameResponse>
   */
  searchByUsIndustryName(
    options?: Models.OriginationReadApiSearchByUsIndustryNameOptionalParams
  ): Promise<Models.SearchByUsIndustryNameResponse>;
  /**
   * @param callback The callback
   */
  searchByUsIndustryName(callback: msRest.ServiceCallback<Models.UsIndustryRecord[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  searchByUsIndustryName(
    options: Models.OriginationReadApiSearchByUsIndustryNameOptionalParams,
    callback: msRest.ServiceCallback<Models.UsIndustryRecord[]>
  ): void;
  searchByUsIndustryName(
    options?:
      | Models.OriginationReadApiSearchByUsIndustryNameOptionalParams
      | msRest.ServiceCallback<Models.UsIndustryRecord[]>,
    callback?: msRest.ServiceCallback<Models.UsIndustryRecord[]>
  ): Promise<Models.SearchByUsIndustryNameResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      searchByUsIndustryNameOperationSpec,
      callback
    ) as Promise<Models.SearchByUsIndustryNameResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllPlexeNotesResponse>
   */
  getAllPlexeNotes(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllPlexeNotesResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getAllPlexeNotes(applicationId: string, callback: msRest.ServiceCallback<Models.Note[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllPlexeNotes(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Note[]>
  ): void;
  getAllPlexeNotes(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Note[]>,
    callback?: msRest.ServiceCallback<Models.Note[]>
  ): Promise<Models.GetAllPlexeNotesResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getAllPlexeNotesOperationSpec,
      callback
    ) as Promise<Models.GetAllPlexeNotesResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAuthorNotesResponse>
   */
  getAuthorNotes(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAuthorNotesResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getAuthorNotes(applicationId: string, callback: msRest.ServiceCallback<Models.Note[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAuthorNotes(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Note[]>
  ): void;
  getAuthorNotes(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Note[]>,
    callback?: msRest.ServiceCallback<Models.Note[]>
  ): Promise<Models.GetAuthorNotesResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getAuthorNotesOperationSpec,
      callback
    ) as Promise<Models.GetAuthorNotesResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllResponse>
   */
  getAll(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAllResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getAll(applicationId: string, callback: msRest.ServiceCallback<Models.Note[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAll(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Note[]>
  ): void;
  getAll(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Note[]>,
    callback?: msRest.ServiceCallback<Models.Note[]>
  ): Promise<Models.GetAllResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getAllOperationSpec,
      callback
    ) as Promise<Models.GetAllResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerAnnouncementsResponse>
   */
  getPartnerAnnouncements(
    partnerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPartnerAnnouncementsResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getPartnerAnnouncements(partnerId: string, callback: msRest.ServiceCallback<Models.Announcement[]>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerAnnouncements(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Announcement[]>
  ): void;
  getPartnerAnnouncements(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Announcement[]>,
    callback?: msRest.ServiceCallback<Models.Announcement[]>
  ): Promise<Models.GetPartnerAnnouncementsResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getPartnerAnnouncementsOperationSpec,
      callback
    ) as Promise<Models.GetPartnerAnnouncementsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllPartnerAnnouncementsResponse>
   */
  getAllPartnerAnnouncements(options?: msRest.RequestOptionsBase): Promise<Models.GetAllPartnerAnnouncementsResponse>;
  /**
   * @param callback The callback
   */
  getAllPartnerAnnouncements(callback: msRest.ServiceCallback<Models.Announcement[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllPartnerAnnouncements(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Announcement[]>
  ): void;
  getAllPartnerAnnouncements(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Announcement[]>,
    callback?: msRest.ServiceCallback<Models.Announcement[]>
  ): Promise<Models.GetAllPartnerAnnouncementsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllPartnerAnnouncementsOperationSpec,
      callback
    ) as Promise<Models.GetAllPartnerAnnouncementsResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerBankDetailsOnIdResponse>
   */
  getPartnerBankDetailsOnId(
    partnerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPartnerBankDetailsOnIdResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getPartnerBankDetailsOnId(partnerId: string, callback: msRest.ServiceCallback<Models.PartnerBankDetails>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerBankDetailsOnId(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PartnerBankDetails>
  ): void;
  getPartnerBankDetailsOnId(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PartnerBankDetails>,
    callback?: msRest.ServiceCallback<Models.PartnerBankDetails>
  ): Promise<Models.GetPartnerBankDetailsOnIdResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getPartnerBankDetailsOnIdOperationSpec,
      callback
    ) as Promise<Models.GetPartnerBankDetailsOnIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerBankDetailsOnNameResponse>
   */
  getPartnerBankDetailsOnName(
    options?: Models.OriginationReadApiGetPartnerBankDetailsOnNameOptionalParams
  ): Promise<Models.GetPartnerBankDetailsOnNameResponse>;
  /**
   * @param callback The callback
   */
  getPartnerBankDetailsOnName(callback: msRest.ServiceCallback<Models.PartnerBankDetails>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerBankDetailsOnName(
    options: Models.OriginationReadApiGetPartnerBankDetailsOnNameOptionalParams,
    callback: msRest.ServiceCallback<Models.PartnerBankDetails>
  ): void;
  getPartnerBankDetailsOnName(
    options?:
      | Models.OriginationReadApiGetPartnerBankDetailsOnNameOptionalParams
      | msRest.ServiceCallback<Models.PartnerBankDetails>,
    callback?: msRest.ServiceCallback<Models.PartnerBankDetails>
  ): Promise<Models.GetPartnerBankDetailsOnNameResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerBankDetailsOnNameOperationSpec,
      callback
    ) as Promise<Models.GetPartnerBankDetailsOnNameResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerLogoResponse>
   */
  getPartnerLogo(partnerId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPartnerLogoResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getPartnerLogo(partnerId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerLogo(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  getPartnerLogo(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.GetPartnerLogoResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getPartnerLogoOperationSpec,
      callback
    ) as Promise<Models.GetPartnerLogoResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsPartnerIsEnabledResponse>
   */
  isPartnerIsEnabled(
    partnerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsPartnerIsEnabledResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  isPartnerIsEnabled(partnerId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  isPartnerIsEnabled(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isPartnerIsEnabled(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsPartnerIsEnabledResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      isPartnerIsEnabledOperationSpec,
      callback
    ) as Promise<Models.IsPartnerIsEnabledResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerCommissionsResponse>
   */
  getPartnerCommissions(
    partnerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPartnerCommissionsResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getPartnerCommissions(partnerId: string, callback: msRest.ServiceCallback<Models.PartnerPaidCommission[]>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerCommissions(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PartnerPaidCommission[]>
  ): void;
  getPartnerCommissions(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PartnerPaidCommission[]>,
    callback?: msRest.ServiceCallback<Models.PartnerPaidCommission[]>
  ): Promise<Models.GetPartnerCommissionsResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getPartnerCommissionsOperationSpec,
      callback
    ) as Promise<Models.GetPartnerCommissionsResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerCommissionsOnBusinessResponse>
   */
  getPartnerCommissionsOnBusiness(
    partnerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPartnerCommissionsOnBusinessResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getPartnerCommissionsOnBusiness(
    partnerId: string,
    callback: msRest.ServiceCallback<Models.PartnerPaidCommission[]>
  ): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerCommissionsOnBusiness(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PartnerPaidCommission[]>
  ): void;
  getPartnerCommissionsOnBusiness(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PartnerPaidCommission[]>,
    callback?: msRest.ServiceCallback<Models.PartnerPaidCommission[]>
  ): Promise<Models.GetPartnerCommissionsOnBusinessResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getPartnerCommissionsOnBusinessOperationSpec,
      callback
    ) as Promise<Models.GetPartnerCommissionsOnBusinessResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerResponse>
   */
  getPartner(partnerId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPartnerResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getPartner(partnerId: string, callback: msRest.ServiceCallback<Models.Partner>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartner(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Partner>
  ): void;
  getPartner(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Partner>,
    callback?: msRest.ServiceCallback<Models.Partner>
  ): Promise<Models.GetPartnerResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getPartnerOperationSpec,
      callback
    ) as Promise<Models.GetPartnerResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerSentLinkRequestResponse>
   */
  getPartnerSentLinkRequest(
    partnerId: string,
    options?: Models.OriginationReadApiGetPartnerSentLinkRequestOptionalParams
  ): Promise<Models.GetPartnerSentLinkRequestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getPartnerSentLinkRequest(partnerId: string, callback: msRest.ServiceCallback<Models.SendLinkRequest>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerSentLinkRequest(
    partnerId: string,
    options: Models.OriginationReadApiGetPartnerSentLinkRequestOptionalParams,
    callback: msRest.ServiceCallback<Models.SendLinkRequest>
  ): void;
  getPartnerSentLinkRequest(
    partnerId: string,
    options?:
      | Models.OriginationReadApiGetPartnerSentLinkRequestOptionalParams
      | msRest.ServiceCallback<Models.SendLinkRequest>,
    callback?: msRest.ServiceCallback<Models.SendLinkRequest>
  ): Promise<Models.GetPartnerSentLinkRequestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getPartnerSentLinkRequestOperationSpec,
      callback
    ) as Promise<Models.GetPartnerSentLinkRequestResponse>;
  }

  /**
   * @param email
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerOnEmailResponse>
   */
  getPartnerOnEmail(email: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPartnerOnEmailResponse>;
  /**
   * @param email
   * @param callback The callback
   */
  getPartnerOnEmail(email: string, callback: msRest.ServiceCallback<Models.Partner>): void;
  /**
   * @param email
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerOnEmail(
    email: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Partner>
  ): void;
  getPartnerOnEmail(
    email: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Partner>,
    callback?: msRest.ServiceCallback<Models.Partner>
  ): Promise<Models.GetPartnerOnEmailResponse> {
    return this.sendOperationRequest(
      {
        email,
        options
      },
      getPartnerOnEmailOperationSpec,
      callback
    ) as Promise<Models.GetPartnerOnEmailResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnersResponse>
   */
  getPartners(options?: Models.OriginationReadApiGetPartnersOptionalParams): Promise<Models.GetPartnersResponse>;
  /**
   * @param callback The callback
   */
  getPartners(callback: msRest.ServiceCallback<Models.PartnerProjectionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartners(
    options: Models.OriginationReadApiGetPartnersOptionalParams,
    callback: msRest.ServiceCallback<Models.PartnerProjectionPagedList>
  ): void;
  getPartners(
    options?:
      | Models.OriginationReadApiGetPartnersOptionalParams
      | msRest.ServiceCallback<Models.PartnerProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.PartnerProjectionPagedList>
  ): Promise<Models.GetPartnersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnersOperationSpec,
      callback
    ) as Promise<Models.GetPartnersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SearchPartnersResponse>
   */
  searchPartners(
    options?: Models.OriginationReadApiSearchPartnersOptionalParams
  ): Promise<Models.SearchPartnersResponse>;
  /**
   * @param callback The callback
   */
  searchPartners(callback: msRest.ServiceCallback<Models.PartnerProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  searchPartners(
    options: Models.OriginationReadApiSearchPartnersOptionalParams,
    callback: msRest.ServiceCallback<Models.PartnerProjection[]>
  ): void;
  searchPartners(
    options?:
      | Models.OriginationReadApiSearchPartnersOptionalParams
      | msRest.ServiceCallback<Models.PartnerProjection[]>,
    callback?: msRest.ServiceCallback<Models.PartnerProjection[]>
  ): Promise<Models.SearchPartnersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      searchPartnersOperationSpec,
      callback
    ) as Promise<Models.SearchPartnersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllPartnerBusinessNamesResponse>
   */
  getAllPartnerBusinessNames(options?: msRest.RequestOptionsBase): Promise<Models.GetAllPartnerBusinessNamesResponse>;
  /**
   * @param callback The callback
   */
  getAllPartnerBusinessNames(callback: msRest.ServiceCallback<Models.PartnerProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllPartnerBusinessNames(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PartnerProjection[]>
  ): void;
  getAllPartnerBusinessNames(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PartnerProjection[]>,
    callback?: msRest.ServiceCallback<Models.PartnerProjection[]>
  ): Promise<Models.GetAllPartnerBusinessNamesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllPartnerBusinessNamesOperationSpec,
      callback
    ) as Promise<Models.GetAllPartnerBusinessNamesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAuthorizationToken1Response>
   */
  getAuthorizationToken1(
    options?: Models.OriginationReadApiGetAuthorizationToken1OptionalParams
  ): Promise<Models.GetAuthorizationToken1Response>;
  /**
   * @param callback The callback
   */
  getAuthorizationToken1(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAuthorizationToken1(
    options: Models.OriginationReadApiGetAuthorizationToken1OptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getAuthorizationToken1(
    options?: Models.OriginationReadApiGetAuthorizationToken1OptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetAuthorizationToken1Response> {
    return this.sendOperationRequest(
      {
        options
      },
      getAuthorizationToken1OperationSpec,
      callback
    ) as Promise<Models.GetAuthorizationToken1Response>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAccreditationResponse>
   */
  getAccreditation(
    options?: Models.OriginationReadApiGetAccreditationOptionalParams
  ): Promise<Models.GetAccreditationResponse>;
  /**
   * @param callback The callback
   */
  getAccreditation(callback: msRest.ServiceCallback<Models.PartnerAccreditationRequest>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAccreditation(
    options: Models.OriginationReadApiGetAccreditationOptionalParams,
    callback: msRest.ServiceCallback<Models.PartnerAccreditationRequest>
  ): void;
  getAccreditation(
    options?:
      | Models.OriginationReadApiGetAccreditationOptionalParams
      | msRest.ServiceCallback<Models.PartnerAccreditationRequest>,
    callback?: msRest.ServiceCallback<Models.PartnerAccreditationRequest>
  ): Promise<Models.GetAccreditationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAccreditationOperationSpec,
      callback
    ) as Promise<Models.GetAccreditationResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadBankStatementsResponse>
   */
  downloadBankStatements(
    id: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.DownloadBankStatementsResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  downloadBankStatements(id: string, callback: msRest.ServiceCallback<Models.TransactionDataFile[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadBankStatements(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionDataFile[]>
  ): void;
  downloadBankStatements(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile[]>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile[]>
  ): Promise<Models.DownloadBankStatementsResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      downloadBankStatementsOperationSpec,
      callback
    ) as Promise<Models.DownloadBankStatementsResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadBlankInvoicesResponse>
   */
  downloadBlankInvoices(id: string, options?: msRest.RequestOptionsBase): Promise<Models.DownloadBlankInvoicesResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  downloadBlankInvoices(id: string, callback: msRest.ServiceCallback<Models.TransactionDataFile[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadBlankInvoices(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionDataFile[]>
  ): void;
  downloadBlankInvoices(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile[]>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile[]>
  ): Promise<Models.DownloadBlankInvoicesResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      downloadBlankInvoicesOperationSpec,
      callback
    ) as Promise<Models.DownloadBlankInvoicesResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadW9FormResponse>
   */
  downloadW9Form(id: string, options?: msRest.RequestOptionsBase): Promise<Models.DownloadW9FormResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  downloadW9Form(id: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadW9Form(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  downloadW9Form(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.DownloadW9FormResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      downloadW9FormOperationSpec,
      callback
    ) as Promise<Models.DownloadW9FormResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerDailyReportsResponse>
   */
  getPartnerDailyReports(
    options?: Models.OriginationReadApiGetPartnerDailyReportsOptionalParams
  ): Promise<Models.GetPartnerDailyReportsResponse>;
  /**
   * @param callback The callback
   */
  getPartnerDailyReports(callback: msRest.ServiceCallback<Models.PartnerProjectionPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerDailyReports(
    options: Models.OriginationReadApiGetPartnerDailyReportsOptionalParams,
    callback: msRest.ServiceCallback<Models.PartnerProjectionPagedList>
  ): void;
  getPartnerDailyReports(
    options?:
      | Models.OriginationReadApiGetPartnerDailyReportsOptionalParams
      | msRest.ServiceCallback<Models.PartnerProjectionPagedList>,
    callback?: msRest.ServiceCallback<Models.PartnerProjectionPagedList>
  ): Promise<Models.GetPartnerDailyReportsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerDailyReportsOperationSpec,
      callback
    ) as Promise<Models.GetPartnerDailyReportsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerIdByUrlReferralResponse>
   */
  getPartnerIdByUrlReferral(
    options?: Models.OriginationReadApiGetPartnerIdByUrlReferralOptionalParams
  ): Promise<Models.GetPartnerIdByUrlReferralResponse>;
  /**
   * @param callback The callback
   */
  getPartnerIdByUrlReferral(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerIdByUrlReferral(
    options: Models.OriginationReadApiGetPartnerIdByUrlReferralOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getPartnerIdByUrlReferral(
    options?: Models.OriginationReadApiGetPartnerIdByUrlReferralOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetPartnerIdByUrlReferralResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerIdByUrlReferralOperationSpec,
      callback
    ) as Promise<Models.GetPartnerIdByUrlReferralResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadPartnerLogoResponse>
   */
  downloadPartnerLogo(
    partnerId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.DownloadPartnerLogoResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  downloadPartnerLogo(partnerId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadPartnerLogo(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  downloadPartnerLogo(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.DownloadPartnerLogoResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      downloadPartnerLogoOperationSpec,
      callback
    ) as Promise<Models.DownloadPartnerLogoResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.AdvanceExtendedSummaryOnPartnerResponse>
   */
  advanceExtendedSummaryOnPartner(
    partnerId: string,
    options?: Models.OriginationReadApiAdvanceExtendedSummaryOnPartnerOptionalParams
  ): Promise<Models.AdvanceExtendedSummaryOnPartnerResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  advanceExtendedSummaryOnPartner(
    partnerId: string,
    callback: msRest.ServiceCallback<Models.AdvanceFullDetailPagedList>
  ): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  advanceExtendedSummaryOnPartner(
    partnerId: string,
    options: Models.OriginationReadApiAdvanceExtendedSummaryOnPartnerOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceFullDetailPagedList>
  ): void;
  advanceExtendedSummaryOnPartner(
    partnerId: string,
    options?:
      | Models.OriginationReadApiAdvanceExtendedSummaryOnPartnerOptionalParams
      | msRest.ServiceCallback<Models.AdvanceFullDetailPagedList>,
    callback?: msRest.ServiceCallback<Models.AdvanceFullDetailPagedList>
  ): Promise<Models.AdvanceExtendedSummaryOnPartnerResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      advanceExtendedSummaryOnPartnerOperationSpec,
      callback
    ) as Promise<Models.AdvanceExtendedSummaryOnPartnerResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadAdvanceExtendedSummaryOnPartnerFileResponse>
   */
  downloadAdvanceExtendedSummaryOnPartnerFile(
    partnerId: string,
    options?: Models.OriginationReadApiDownloadAdvanceExtendedSummaryOnPartnerFileOptionalParams
  ): Promise<Models.DownloadAdvanceExtendedSummaryOnPartnerFileResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  downloadAdvanceExtendedSummaryOnPartnerFile(
    partnerId: string,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadAdvanceExtendedSummaryOnPartnerFile(
    partnerId: string,
    options: Models.OriginationReadApiDownloadAdvanceExtendedSummaryOnPartnerFileOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  downloadAdvanceExtendedSummaryOnPartnerFile(
    partnerId: string,
    options?:
      | Models.OriginationReadApiDownloadAdvanceExtendedSummaryOnPartnerFileOptionalParams
      | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.DownloadAdvanceExtendedSummaryOnPartnerFileResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      downloadAdvanceExtendedSummaryOnPartnerFileOperationSpec,
      callback
    ) as Promise<Models.DownloadAdvanceExtendedSummaryOnPartnerFileResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSendLinkUrlResponse>
   */
  getSendLinkUrl(
    partnerId: string,
    options?: Models.OriginationReadApiGetSendLinkUrlOptionalParams
  ): Promise<Models.GetSendLinkUrlResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getSendLinkUrl(partnerId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSendLinkUrl(
    partnerId: string,
    options: Models.OriginationReadApiGetSendLinkUrlOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getSendLinkUrl(
    partnerId: string,
    options?: Models.OriginationReadApiGetSendLinkUrlOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetSendLinkUrlResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getSendLinkUrlOperationSpec,
      callback
    ) as Promise<Models.GetSendLinkUrlResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerChannelResponse>
   */
  getPartnerChannel(
    options?: Models.OriginationReadApiGetPartnerChannelOptionalParams
  ): Promise<Models.GetPartnerChannelResponse>;
  /**
   * @param callback The callback
   */
  getPartnerChannel(callback: msRest.ServiceCallback<Models.PartnerChannel>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerChannel(
    options: Models.OriginationReadApiGetPartnerChannelOptionalParams,
    callback: msRest.ServiceCallback<Models.PartnerChannel>
  ): void;
  getPartnerChannel(
    options?: Models.OriginationReadApiGetPartnerChannelOptionalParams | msRest.ServiceCallback<Models.PartnerChannel>,
    callback?: msRest.ServiceCallback<Models.PartnerChannel>
  ): Promise<Models.GetPartnerChannelResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerChannelOperationSpec,
      callback
    ) as Promise<Models.GetPartnerChannelResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDisabledLoans1Response>
   */
  getDisabledLoans1(
    options?: Models.OriginationReadApiGetDisabledLoans1OptionalParams
  ): Promise<Models.GetDisabledLoans1Response>;
  /**
   * @param callback The callback
   */
  getDisabledLoans1(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDisabledLoans1(
    options: Models.OriginationReadApiGetDisabledLoans1OptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  getDisabledLoans1(
    options?: Models.OriginationReadApiGetDisabledLoans1OptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetDisabledLoans1Response> {
    return this.sendOperationRequest(
      {
        options
      },
      getDisabledLoans1OperationSpec,
      callback
    ) as Promise<Models.GetDisabledLoans1Response>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetReportDashboardSettingsResponse>
   */
  getReportDashboardSettings(options?: msRest.RequestOptionsBase): Promise<Models.GetReportDashboardSettingsResponse>;
  /**
   * @param callback The callback
   */
  getReportDashboardSettings(callback: msRest.ServiceCallback<Models.ReportDashboard[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getReportDashboardSettings(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ReportDashboard[]>
  ): void;
  getReportDashboardSettings(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ReportDashboard[]>,
    callback?: msRest.ServiceCallback<Models.ReportDashboard[]>
  ): Promise<Models.GetReportDashboardSettingsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getReportDashboardSettingsOperationSpec,
      callback
    ) as Promise<Models.GetReportDashboardSettingsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SearchDataResponse>
   */
  searchData(options?: Models.OriginationReadApiSearchDataOptionalParams): Promise<Models.SearchDataResponse>;
  /**
   * @param callback The callback
   */
  searchData(callback: msRest.ServiceCallback<Models.MultipleSearch>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  searchData(
    options: Models.OriginationReadApiSearchDataOptionalParams,
    callback: msRest.ServiceCallback<Models.MultipleSearch>
  ): void;
  searchData(
    options?: Models.OriginationReadApiSearchDataOptionalParams | msRest.ServiceCallback<Models.MultipleSearch>,
    callback?: msRest.ServiceCallback<Models.MultipleSearch>
  ): Promise<Models.SearchDataResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      searchDataOperationSpec,
      callback
    ) as Promise<Models.SearchDataResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCommunicationTemplatesSettingsResponse>
   */
  getCommunicationTemplatesSettings(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetCommunicationTemplatesSettingsResponse>;
  /**
   * @param callback The callback
   */
  getCommunicationTemplatesSettings(callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCommunicationTemplatesSettings(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>
  ): void;
  getCommunicationTemplatesSettings(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>,
    callback?: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>
  ): Promise<Models.GetCommunicationTemplatesSettingsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCommunicationTemplatesSettingsOperationSpec,
      callback
    ) as Promise<Models.GetCommunicationTemplatesSettingsResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCommunicationTemplateSettingsResponse>
   */
  getCommunicationTemplateSettings(
    id: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetCommunicationTemplateSettingsResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getCommunicationTemplateSettings(
    id: string,
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting>
  ): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getCommunicationTemplateSettings(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting>
  ): void;
  getCommunicationTemplateSettings(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunicationTemplateSetting>,
    callback?: msRest.ServiceCallback<Models.CommunicationTemplateSetting>
  ): Promise<Models.GetCommunicationTemplateSettingsResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      getCommunicationTemplateSettingsOperationSpec,
      callback
    ) as Promise<Models.GetCommunicationTemplateSettingsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSMSTemplatesSettingsResponse>
   */
  getSMSTemplatesSettings(options?: msRest.RequestOptionsBase): Promise<Models.GetSMSTemplatesSettingsResponse>;
  /**
   * @param callback The callback
   */
  getSMSTemplatesSettings(callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getSMSTemplatesSettings(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>
  ): void;
  getSMSTemplatesSettings(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>,
    callback?: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>
  ): Promise<Models.GetSMSTemplatesSettingsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getSMSTemplatesSettingsOperationSpec,
      callback
    ) as Promise<Models.GetSMSTemplatesSettingsResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSMSTemplateSettingsResponse>
   */
  getSMSTemplateSettings(
    id: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetSMSTemplateSettingsResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getSMSTemplateSettings(id: string, callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getSMSTemplateSettings(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting>
  ): void;
  getSMSTemplateSettings(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunicationTemplateSetting>,
    callback?: msRest.ServiceCallback<Models.CommunicationTemplateSetting>
  ): Promise<Models.GetSMSTemplateSettingsResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      getSMSTemplateSettingsOperationSpec,
      callback
    ) as Promise<Models.GetSMSTemplateSettingsResponse>;
  }

  /**
   * @param templateId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteCommunicationTemplateSettings(
    templateId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param templateId
   * @param callback The callback
   */
  deleteCommunicationTemplateSettings(templateId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param templateId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteCommunicationTemplateSettings(
    templateId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  deleteCommunicationTemplateSettings(
    templateId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        templateId,
        options
      },
      deleteCommunicationTemplateSettingsOperationSpec,
      callback
    );
  }

  /**
   * @param templateId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteSMSTemplateSettings(templateId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param templateId
   * @param callback The callback
   */
  deleteSMSTemplateSettings(templateId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param templateId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteSMSTemplateSettings(
    templateId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  deleteSMSTemplateSettings(
    templateId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        templateId,
        options
      },
      deleteSMSTemplateSettingsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerCommunicationTemplatesSettingsResponse>
   */
  getPartnerCommunicationTemplatesSettings(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPartnerCommunicationTemplatesSettingsResponse>;
  /**
   * @param callback The callback
   */
  getPartnerCommunicationTemplatesSettings(
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>
  ): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerCommunicationTemplatesSettings(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>
  ): void;
  getPartnerCommunicationTemplatesSettings(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>,
    callback?: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>
  ): Promise<Models.GetPartnerCommunicationTemplatesSettingsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerCommunicationTemplatesSettingsOperationSpec,
      callback
    ) as Promise<Models.GetPartnerCommunicationTemplatesSettingsResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerCommunicationTemplateSettingsResponse>
   */
  getPartnerCommunicationTemplateSettings(
    id: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPartnerCommunicationTemplateSettingsResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getPartnerCommunicationTemplateSettings(
    id: string,
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting>
  ): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerCommunicationTemplateSettings(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting>
  ): void;
  getPartnerCommunicationTemplateSettings(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunicationTemplateSetting>,
    callback?: msRest.ServiceCallback<Models.CommunicationTemplateSetting>
  ): Promise<Models.GetPartnerCommunicationTemplateSettingsResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      getPartnerCommunicationTemplateSettingsOperationSpec,
      callback
    ) as Promise<Models.GetPartnerCommunicationTemplateSettingsResponse>;
  }

  /**
   * @param templateId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deletePartnerCommunicationTemplateSettings(
    templateId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param templateId
   * @param callback The callback
   */
  deletePartnerCommunicationTemplateSettings(templateId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param templateId
   * @param options The optional parameters
   * @param callback The callback
   */
  deletePartnerCommunicationTemplateSettings(
    templateId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  deletePartnerCommunicationTemplateSettings(
    templateId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        templateId,
        options
      },
      deletePartnerCommunicationTemplateSettingsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentCommunicationTemplatesSettingsResponse>
   */
  getAgentCommunicationTemplatesSettings(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAgentCommunicationTemplatesSettingsResponse>;
  /**
   * @param callback The callback
   */
  getAgentCommunicationTemplatesSettings(callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentCommunicationTemplatesSettings(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>
  ): void;
  getAgentCommunicationTemplatesSettings(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>,
    callback?: msRest.ServiceCallback<Models.CommunicationTemplateSetting[]>
  ): Promise<Models.GetAgentCommunicationTemplatesSettingsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAgentCommunicationTemplatesSettingsOperationSpec,
      callback
    ) as Promise<Models.GetAgentCommunicationTemplatesSettingsResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentCommunicationTemplateSettingsResponse>
   */
  getAgentCommunicationTemplateSettings(
    id: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAgentCommunicationTemplateSettingsResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getAgentCommunicationTemplateSettings(
    id: string,
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting>
  ): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentCommunicationTemplateSettings(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.CommunicationTemplateSetting>
  ): void;
  getAgentCommunicationTemplateSettings(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunicationTemplateSetting>,
    callback?: msRest.ServiceCallback<Models.CommunicationTemplateSetting>
  ): Promise<Models.GetAgentCommunicationTemplateSettingsResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      getAgentCommunicationTemplateSettingsOperationSpec,
      callback
    ) as Promise<Models.GetAgentCommunicationTemplateSettingsResponse>;
  }

  /**
   * @param templateId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteAgentCommunicationTemplateSettings(
    templateId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param templateId
   * @param callback The callback
   */
  deleteAgentCommunicationTemplateSettings(templateId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param templateId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteAgentCommunicationTemplateSettings(
    templateId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  deleteAgentCommunicationTemplateSettings(
    templateId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        templateId,
        options
      },
      deleteAgentCommunicationTemplateSettingsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsAdminCommunicationPanelEnabledResponse>
   */
  isAdminCommunicationPanelEnabled(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsAdminCommunicationPanelEnabledResponse>;
  /**
   * @param callback The callback
   */
  isAdminCommunicationPanelEnabled(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isAdminCommunicationPanelEnabled(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isAdminCommunicationPanelEnabled(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsAdminCommunicationPanelEnabledResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isAdminCommunicationPanelEnabledOperationSpec,
      callback
    ) as Promise<Models.IsAdminCommunicationPanelEnabledResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankStatementStaleDetailResponse>
   */
  getBankStatementStaleDetail(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetBankStatementStaleDetailResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getBankStatementStaleDetail(
    organisationId: string,
    callback: msRest.ServiceCallback<Models.BankStatementStaleDetail>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankStatementStaleDetail(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BankStatementStaleDetail>
  ): void;
  getBankStatementStaleDetail(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BankStatementStaleDetail>,
    callback?: msRest.ServiceCallback<Models.BankStatementStaleDetail>
  ): Promise<Models.GetBankStatementStaleDetailResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getBankStatementStaleDetailOperationSpec,
      callback
    ) as Promise<Models.GetBankStatementStaleDetailResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetManualUploadDetailsResponse>
   */
  getManualUploadDetails(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetManualUploadDetailsResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getManualUploadDetails(organisationId: string, callback: msRest.ServiceCallback<Models.BankData[]>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getManualUploadDetails(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.BankData[]>
  ): void;
  getManualUploadDetails(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BankData[]>,
    callback?: msRest.ServiceCallback<Models.BankData[]>
  ): Promise<Models.GetManualUploadDetailsResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getManualUploadDetailsOperationSpec,
      callback
    ) as Promise<Models.GetManualUploadDetailsResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDisconnectedBankAccountsResponse>
   */
  getDisconnectedBankAccounts(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetDisconnectedBankAccountsResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getDisconnectedBankAccounts(
    organisationId: string,
    callback: msRest.ServiceCallback<Models.ConnectionStatusProjection[]>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getDisconnectedBankAccounts(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ConnectionStatusProjection[]>
  ): void;
  getDisconnectedBankAccounts(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ConnectionStatusProjection[]>,
    callback?: msRest.ServiceCallback<Models.ConnectionStatusProjection[]>
  ): Promise<Models.GetDisconnectedBankAccountsResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getDisconnectedBankAccountsOperationSpec,
      callback
    ) as Promise<Models.GetDisconnectedBankAccountsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SummariesApplicationResponse>
   */
  summariesApplication(
    options?: Models.OriginationReadApiSummariesApplicationOptionalParams
  ): Promise<Models.SummariesApplicationResponse>;
  /**
   * @param callback The callback
   */
  summariesApplication(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  summariesApplication(
    options: Models.OriginationReadApiSummariesApplicationOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  summariesApplication(
    options?: Models.OriginationReadApiSummariesApplicationOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SummariesApplicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      summariesApplicationOperationSpec,
      callback
    ) as Promise<Models.SummariesApplicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SummariesApplicationResultsResponse>
   */
  summariesApplicationResults(
    options?: Models.OriginationReadApiSummariesApplicationResultsOptionalParams
  ): Promise<Models.SummariesApplicationResultsResponse>;
  /**
   * @param callback The callback
   */
  summariesApplicationResults(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  summariesApplicationResults(
    options: Models.OriginationReadApiSummariesApplicationResultsOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  summariesApplicationResults(
    options?: Models.OriginationReadApiSummariesApplicationResultsOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SummariesApplicationResultsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      summariesApplicationResultsOperationSpec,
      callback
    ) as Promise<Models.SummariesApplicationResultsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SummariesAllRecentCommunicationResponse>
   */
  summariesAllRecentCommunication(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.SummariesAllRecentCommunicationResponse>;
  /**
   * @param callback The callback
   */
  summariesAllRecentCommunication(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  summariesAllRecentCommunication(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  summariesAllRecentCommunication(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SummariesAllRecentCommunicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      summariesAllRecentCommunicationOperationSpec,
      callback
    ) as Promise<Models.SummariesAllRecentCommunicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SummariesAllRecentCustomerCommunicationResponse>
   */
  summariesAllRecentCustomerCommunication(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.SummariesAllRecentCustomerCommunicationResponse>;
  /**
   * @param callback The callback
   */
  summariesAllRecentCustomerCommunication(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  summariesAllRecentCustomerCommunication(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  summariesAllRecentCustomerCommunication(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SummariesAllRecentCustomerCommunicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      summariesAllRecentCustomerCommunicationOperationSpec,
      callback
    ) as Promise<Models.SummariesAllRecentCustomerCommunicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SummariesAllRecentPartnerAgentCommunicationResponse>
   */
  summariesAllRecentPartnerAgentCommunication(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.SummariesAllRecentPartnerAgentCommunicationResponse>;
  /**
   * @param callback The callback
   */
  summariesAllRecentPartnerAgentCommunication(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  summariesAllRecentPartnerAgentCommunication(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  summariesAllRecentPartnerAgentCommunication(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SummariesAllRecentPartnerAgentCommunicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      summariesAllRecentPartnerAgentCommunicationOperationSpec,
      callback
    ) as Promise<Models.SummariesAllRecentPartnerAgentCommunicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SummariesApplicationCommunicationResponse>
   */
  summariesApplicationCommunication(
    options?: Models.OriginationReadApiSummariesApplicationCommunicationOptionalParams
  ): Promise<Models.SummariesApplicationCommunicationResponse>;
  /**
   * @param callback The callback
   */
  summariesApplicationCommunication(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  summariesApplicationCommunication(
    options: Models.OriginationReadApiSummariesApplicationCommunicationOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  summariesApplicationCommunication(
    options?: Models.OriginationReadApiSummariesApplicationCommunicationOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SummariesApplicationCommunicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      summariesApplicationCommunicationOperationSpec,
      callback
    ) as Promise<Models.SummariesApplicationCommunicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SummariesLoanResponse>
   */
  summariesLoan(options?: Models.OriginationReadApiSummariesLoanOptionalParams): Promise<Models.SummariesLoanResponse>;
  /**
   * @param callback The callback
   */
  summariesLoan(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  summariesLoan(
    options: Models.OriginationReadApiSummariesLoanOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  summariesLoan(
    options?: Models.OriginationReadApiSummariesLoanOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SummariesLoanResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      summariesLoanOperationSpec,
      callback
    ) as Promise<Models.SummariesLoanResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SummariesLoanCommunicationResponse>
   */
  summariesLoanCommunication(
    options?: Models.OriginationReadApiSummariesLoanCommunicationOptionalParams
  ): Promise<Models.SummariesLoanCommunicationResponse>;
  /**
   * @param callback The callback
   */
  summariesLoanCommunication(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  summariesLoanCommunication(
    options: Models.OriginationReadApiSummariesLoanCommunicationOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  summariesLoanCommunication(
    options?: Models.OriginationReadApiSummariesLoanCommunicationOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SummariesLoanCommunicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      summariesLoanCommunicationOperationSpec,
      callback
    ) as Promise<Models.SummariesLoanCommunicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCommunicationRequestStringResponse>
   */
  getCommunicationRequestString(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetCommunicationRequestStringResponse>;
  /**
   * @param callback The callback
   */
  getCommunicationRequestString(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCommunicationRequestString(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getCommunicationRequestString(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetCommunicationRequestStringResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCommunicationRequestStringOperationSpec,
      callback
    ) as Promise<Models.GetCommunicationRequestStringResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationRequestStringResponse>
   */
  getApplicationRequestString(options?: msRest.RequestOptionsBase): Promise<Models.GetApplicationRequestStringResponse>;
  /**
   * @param callback The callback
   */
  getApplicationRequestString(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationRequestString(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getApplicationRequestString(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetApplicationRequestStringResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getApplicationRequestStringOperationSpec,
      callback
    ) as Promise<Models.GetApplicationRequestStringResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationResultRequestStringResponse>
   */
  getApplicationResultRequestString(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationResultRequestStringResponse>;
  /**
   * @param callback The callback
   */
  getApplicationResultRequestString(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationResultRequestString(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getApplicationResultRequestString(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetApplicationResultRequestStringResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getApplicationResultRequestStringOperationSpec,
      callback
    ) as Promise<Models.GetApplicationResultRequestStringResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanRequestStringResponse>
   */
  getLoanRequestString(options?: msRest.RequestOptionsBase): Promise<Models.GetLoanRequestStringResponse>;
  /**
   * @param callback The callback
   */
  getLoanRequestString(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanRequestString(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getLoanRequestString(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetLoanRequestStringResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanRequestStringOperationSpec,
      callback
    ) as Promise<Models.GetLoanRequestStringResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationCommunicationRequestStringResponse>
   */
  getApplicationCommunicationRequestString(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationCommunicationRequestStringResponse>;
  /**
   * @param callback The callback
   */
  getApplicationCommunicationRequestString(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationCommunicationRequestString(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<string>
  ): void;
  getApplicationCommunicationRequestString(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetApplicationCommunicationRequestStringResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getApplicationCommunicationRequestStringOperationSpec,
      callback
    ) as Promise<Models.GetApplicationCommunicationRequestStringResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSupportingDocumentRequestResponse>
   */
  getSupportingDocumentRequest(
    applicationId: string,
    options?: Models.OriginationReadApiGetSupportingDocumentRequestOptionalParams
  ): Promise<Models.GetSupportingDocumentRequestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getSupportingDocumentRequest(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.SupportDocumentDetails[]>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSupportingDocumentRequest(
    applicationId: string,
    options: Models.OriginationReadApiGetSupportingDocumentRequestOptionalParams,
    callback: msRest.ServiceCallback<Models.SupportDocumentDetails[]>
  ): void;
  getSupportingDocumentRequest(
    applicationId: string,
    options?:
      | Models.OriginationReadApiGetSupportingDocumentRequestOptionalParams
      | msRest.ServiceCallback<Models.SupportDocumentDetails[]>,
    callback?: msRest.ServiceCallback<Models.SupportDocumentDetails[]>
  ): Promise<Models.GetSupportingDocumentRequestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getSupportingDocumentRequestOperationSpec,
      callback
    ) as Promise<Models.GetSupportingDocumentRequestResponse>;
  }

  /**
   * @param applicationId
   * @param location
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSupportingDocumentResponse>
   */
  getSupportingDocument(
    applicationId: string,
    location: string,
    options?: Models.OriginationReadApiGetSupportingDocumentOptionalParams
  ): Promise<Models.GetSupportingDocumentResponse>;
  /**
   * @param applicationId
   * @param location
   * @param callback The callback
   */
  getSupportingDocument(
    applicationId: string,
    location: string,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  /**
   * @param applicationId
   * @param location
   * @param options The optional parameters
   * @param callback The callback
   */
  getSupportingDocument(
    applicationId: string,
    location: string,
    options: Models.OriginationReadApiGetSupportingDocumentOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  getSupportingDocument(
    applicationId: string,
    location: string,
    options?:
      | Models.OriginationReadApiGetSupportingDocumentOptionalParams
      | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.GetSupportingDocumentResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        location,
        options
      },
      getSupportingDocumentOperationSpec,
      callback
    ) as Promise<Models.GetSupportingDocumentResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSupportingDocumentsResponse>
   */
  getSupportingDocuments(
    applicationId: string,
    options?: Models.OriginationReadApiGetSupportingDocumentsOptionalParams
  ): Promise<Models.GetSupportingDocumentsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getSupportingDocuments(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.SupportDocumentDetailsPagedList>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSupportingDocuments(
    applicationId: string,
    options: Models.OriginationReadApiGetSupportingDocumentsOptionalParams,
    callback: msRest.ServiceCallback<Models.SupportDocumentDetailsPagedList>
  ): void;
  getSupportingDocuments(
    applicationId: string,
    options?:
      | Models.OriginationReadApiGetSupportingDocumentsOptionalParams
      | msRest.ServiceCallback<Models.SupportDocumentDetailsPagedList>,
    callback?: msRest.ServiceCallback<Models.SupportDocumentDetailsPagedList>
  ): Promise<Models.GetSupportingDocumentsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getSupportingDocumentsOperationSpec,
      callback
    ) as Promise<Models.GetSupportingDocumentsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsSupportedDocumentCompletedResponse>
   */
  isSupportedDocumentCompleted(
    applicationId: string,
    options?: Models.OriginationReadApiIsSupportedDocumentCompletedOptionalParams
  ): Promise<Models.IsSupportedDocumentCompletedResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isSupportedDocumentCompleted(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isSupportedDocumentCompleted(
    applicationId: string,
    options: Models.OriginationReadApiIsSupportedDocumentCompletedOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isSupportedDocumentCompleted(
    applicationId: string,
    options?: Models.OriginationReadApiIsSupportedDocumentCompletedOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsSupportedDocumentCompletedResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isSupportedDocumentCompletedOperationSpec,
      callback
    ) as Promise<Models.IsSupportedDocumentCompletedResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsSupportedEnabledResponse>
   */
  isSupportedEnabled(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.IsSupportedEnabledResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isSupportedEnabled(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isSupportedEnabled(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  isSupportedEnabled(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsSupportedEnabledResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isSupportedEnabledOperationSpec,
      callback
    ) as Promise<Models.IsSupportedEnabledResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRequestSupportingDocumentsResponse>
   */
  getRequestSupportingDocuments(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetRequestSupportingDocumentsResponse>;
  /**
   * @param callback The callback
   */
  getRequestSupportingDocuments(callback: msRest.ServiceCallback<Models.SupportDocumentDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getRequestSupportingDocuments(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.SupportDocumentDetails[]>
  ): void;
  getRequestSupportingDocuments(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SupportDocumentDetails[]>,
    callback?: msRest.ServiceCallback<Models.SupportDocumentDetails[]>
  ): Promise<Models.GetRequestSupportingDocumentsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getRequestSupportingDocumentsOperationSpec,
      callback
    ) as Promise<Models.GetRequestSupportingDocumentsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRequestSecondarySupportingDocumentsResponse>
   */
  getRequestSecondarySupportingDocuments(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetRequestSecondarySupportingDocumentsResponse>;
  /**
   * @param callback The callback
   */
  getRequestSecondarySupportingDocuments(callback: msRest.ServiceCallback<Models.SupportDocumentDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getRequestSecondarySupportingDocuments(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.SupportDocumentDetails[]>
  ): void;
  getRequestSecondarySupportingDocuments(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SupportDocumentDetails[]>,
    callback?: msRest.ServiceCallback<Models.SupportDocumentDetails[]>
  ): Promise<Models.GetRequestSecondarySupportingDocumentsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getRequestSecondarySupportingDocumentsOperationSpec,
      callback
    ) as Promise<Models.GetRequestSecondarySupportingDocumentsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadSupportDocumentsResponse>
   */
  downloadSupportDocuments(
    applicationId: string,
    options?: Models.OriginationReadApiDownloadSupportDocumentsOptionalParams
  ): Promise<Models.DownloadSupportDocumentsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  downloadSupportDocuments(applicationId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadSupportDocuments(
    applicationId: string,
    options: Models.OriginationReadApiDownloadSupportDocumentsOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDataFile[]>
  ): void;
  downloadSupportDocuments(
    applicationId: string,
    options?:
      | Models.OriginationReadApiDownloadSupportDocumentsOptionalParams
      | msRest.ServiceCallback<Models.TransactionDataFile[]>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile[]>
  ): Promise<Models.DownloadSupportDocumentsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      downloadSupportDocumentsOperationSpec,
      callback
    ) as Promise<Models.DownloadSupportDocumentsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAdminTodosResponse>
   */
  getAdminTodos(options?: msRest.RequestOptionsBase): Promise<Models.GetAdminTodosResponse>;
  /**
   * @param callback The callback
   */
  getAdminTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAdminTodos(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  getAdminTodos(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetAdminTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAdminTodosOperationSpec,
      callback
    ) as Promise<Models.GetAdminTodosResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAdminTodoByIdResponse>
   */
  getAdminTodoById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAdminTodoByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getAdminTodoById(id: string, callback: msRest.ServiceCallback<Models.TodoItem>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getAdminTodoById(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TodoItem>
  ): void;
  getAdminTodoById(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TodoItem>,
    callback?: msRest.ServiceCallback<Models.TodoItem>
  ): Promise<Models.GetAdminTodoByIdResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      getAdminTodoByIdOperationSpec,
      callback
    ) as Promise<Models.GetAdminTodoByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCurrentAdminTodosResponse>
   */
  getCurrentAdminTodos(
    options?: Models.OriginationReadApiGetCurrentAdminTodosOptionalParams
  ): Promise<Models.GetCurrentAdminTodosResponse>;
  /**
   * @param callback The callback
   */
  getCurrentAdminTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCurrentAdminTodos(
    options: Models.OriginationReadApiGetCurrentAdminTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getCurrentAdminTodos(
    options?: Models.OriginationReadApiGetCurrentAdminTodosOptionalParams | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetCurrentAdminTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCurrentAdminTodosOperationSpec,
      callback
    ) as Promise<Models.GetCurrentAdminTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCompletedAdminTodosResponse>
   */
  getCompletedAdminTodos(
    options?: Models.OriginationReadApiGetCompletedAdminTodosOptionalParams
  ): Promise<Models.GetCompletedAdminTodosResponse>;
  /**
   * @param callback The callback
   */
  getCompletedAdminTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCompletedAdminTodos(
    options: Models.OriginationReadApiGetCompletedAdminTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getCompletedAdminTodos(
    options?: Models.OriginationReadApiGetCompletedAdminTodosOptionalParams | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetCompletedAdminTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCompletedAdminTodosOperationSpec,
      callback
    ) as Promise<Models.GetCompletedAdminTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetExpiredAdminTodosResponse>
   */
  getExpiredAdminTodos(
    options?: Models.OriginationReadApiGetExpiredAdminTodosOptionalParams
  ): Promise<Models.GetExpiredAdminTodosResponse>;
  /**
   * @param callback The callback
   */
  getExpiredAdminTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getExpiredAdminTodos(
    options: Models.OriginationReadApiGetExpiredAdminTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getExpiredAdminTodos(
    options?: Models.OriginationReadApiGetExpiredAdminTodosOptionalParams | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetExpiredAdminTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getExpiredAdminTodosOperationSpec,
      callback
    ) as Promise<Models.GetExpiredAdminTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentTodosResponse>
   */
  getAgentTodos(options?: msRest.RequestOptionsBase): Promise<Models.GetAgentTodosResponse>;
  /**
   * @param callback The callback
   */
  getAgentTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentTodos(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  getAgentTodos(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetAgentTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAgentTodosOperationSpec,
      callback
    ) as Promise<Models.GetAgentTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentTodosByAgentIdResponse>
   */
  getAgentTodosByAgentId(
    options?: Models.OriginationReadApiGetAgentTodosByAgentIdOptionalParams
  ): Promise<Models.GetAgentTodosByAgentIdResponse>;
  /**
   * @param callback The callback
   */
  getAgentTodosByAgentId(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentTodosByAgentId(
    options: Models.OriginationReadApiGetAgentTodosByAgentIdOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getAgentTodosByAgentId(
    options?: Models.OriginationReadApiGetAgentTodosByAgentIdOptionalParams | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetAgentTodosByAgentIdResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAgentTodosByAgentIdOperationSpec,
      callback
    ) as Promise<Models.GetAgentTodosByAgentIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAgentTodoByIdResponse>
   */
  getAgentTodoById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAgentTodoByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getAgentTodoById(id: string, callback: msRest.ServiceCallback<Models.TodoItem>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getAgentTodoById(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TodoItem>
  ): void;
  getAgentTodoById(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TodoItem>,
    callback?: msRest.ServiceCallback<Models.TodoItem>
  ): Promise<Models.GetAgentTodoByIdResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      getAgentTodoByIdOperationSpec,
      callback
    ) as Promise<Models.GetAgentTodoByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCurrentAgentTodosResponse>
   */
  getCurrentAgentTodos(
    options?: Models.OriginationReadApiGetCurrentAgentTodosOptionalParams
  ): Promise<Models.GetCurrentAgentTodosResponse>;
  /**
   * @param callback The callback
   */
  getCurrentAgentTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCurrentAgentTodos(
    options: Models.OriginationReadApiGetCurrentAgentTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getCurrentAgentTodos(
    options?: Models.OriginationReadApiGetCurrentAgentTodosOptionalParams | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetCurrentAgentTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCurrentAgentTodosOperationSpec,
      callback
    ) as Promise<Models.GetCurrentAgentTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCompletedAgentTodosResponse>
   */
  getCompletedAgentTodos(
    options?: Models.OriginationReadApiGetCompletedAgentTodosOptionalParams
  ): Promise<Models.GetCompletedAgentTodosResponse>;
  /**
   * @param callback The callback
   */
  getCompletedAgentTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCompletedAgentTodos(
    options: Models.OriginationReadApiGetCompletedAgentTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getCompletedAgentTodos(
    options?: Models.OriginationReadApiGetCompletedAgentTodosOptionalParams | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetCompletedAgentTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCompletedAgentTodosOperationSpec,
      callback
    ) as Promise<Models.GetCompletedAgentTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetExpiredAgentTodosResponse>
   */
  getExpiredAgentTodos(
    options?: Models.OriginationReadApiGetExpiredAgentTodosOptionalParams
  ): Promise<Models.GetExpiredAgentTodosResponse>;
  /**
   * @param callback The callback
   */
  getExpiredAgentTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getExpiredAgentTodos(
    options: Models.OriginationReadApiGetExpiredAgentTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getExpiredAgentTodos(
    options?: Models.OriginationReadApiGetExpiredAgentTodosOptionalParams | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetExpiredAgentTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getExpiredAgentTodosOperationSpec,
      callback
    ) as Promise<Models.GetExpiredAgentTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerTodosResponse>
   */
  getPartnerTodos(options?: msRest.RequestOptionsBase): Promise<Models.GetPartnerTodosResponse>;
  /**
   * @param callback The callback
   */
  getPartnerTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerTodos(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  getPartnerTodos(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetPartnerTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerTodosOperationSpec,
      callback
    ) as Promise<Models.GetPartnerTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerTodosByPartnerIdResponse>
   */
  getPartnerTodosByPartnerId(
    options?: Models.OriginationReadApiGetPartnerTodosByPartnerIdOptionalParams
  ): Promise<Models.GetPartnerTodosByPartnerIdResponse>;
  /**
   * @param callback The callback
   */
  getPartnerTodosByPartnerId(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerTodosByPartnerId(
    options: Models.OriginationReadApiGetPartnerTodosByPartnerIdOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getPartnerTodosByPartnerId(
    options?:
      | Models.OriginationReadApiGetPartnerTodosByPartnerIdOptionalParams
      | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetPartnerTodosByPartnerIdResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerTodosByPartnerIdOperationSpec,
      callback
    ) as Promise<Models.GetPartnerTodosByPartnerIdResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerTodoByIdResponse>
   */
  getPartnerTodoById(id: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPartnerTodoByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getPartnerTodoById(id: string, callback: msRest.ServiceCallback<Models.TodoItem>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerTodoById(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TodoItem>
  ): void;
  getPartnerTodoById(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TodoItem>,
    callback?: msRest.ServiceCallback<Models.TodoItem>
  ): Promise<Models.GetPartnerTodoByIdResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      getPartnerTodoByIdOperationSpec,
      callback
    ) as Promise<Models.GetPartnerTodoByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCurrentPartnerTodosResponse>
   */
  getCurrentPartnerTodos(
    options?: Models.OriginationReadApiGetCurrentPartnerTodosOptionalParams
  ): Promise<Models.GetCurrentPartnerTodosResponse>;
  /**
   * @param callback The callback
   */
  getCurrentPartnerTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCurrentPartnerTodos(
    options: Models.OriginationReadApiGetCurrentPartnerTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getCurrentPartnerTodos(
    options?: Models.OriginationReadApiGetCurrentPartnerTodosOptionalParams | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetCurrentPartnerTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCurrentPartnerTodosOperationSpec,
      callback
    ) as Promise<Models.GetCurrentPartnerTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCompletedPartnerTodosResponse>
   */
  getCompletedPartnerTodos(
    options?: Models.OriginationReadApiGetCompletedPartnerTodosOptionalParams
  ): Promise<Models.GetCompletedPartnerTodosResponse>;
  /**
   * @param callback The callback
   */
  getCompletedPartnerTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCompletedPartnerTodos(
    options: Models.OriginationReadApiGetCompletedPartnerTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getCompletedPartnerTodos(
    options?:
      | Models.OriginationReadApiGetCompletedPartnerTodosOptionalParams
      | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetCompletedPartnerTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCompletedPartnerTodosOperationSpec,
      callback
    ) as Promise<Models.GetCompletedPartnerTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetExpiredPartnerTodosResponse>
   */
  getExpiredPartnerTodos(
    options?: Models.OriginationReadApiGetExpiredPartnerTodosOptionalParams
  ): Promise<Models.GetExpiredPartnerTodosResponse>;
  /**
   * @param callback The callback
   */
  getExpiredPartnerTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getExpiredPartnerTodos(
    options: Models.OriginationReadApiGetExpiredPartnerTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getExpiredPartnerTodos(
    options?: Models.OriginationReadApiGetExpiredPartnerTodosOptionalParams | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetExpiredPartnerTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getExpiredPartnerTodosOperationSpec,
      callback
    ) as Promise<Models.GetExpiredPartnerTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerBusinessTodosResponse>
   */
  getPartnerBusinessTodos(
    options?: Models.OriginationReadApiGetPartnerBusinessTodosOptionalParams
  ): Promise<Models.GetPartnerBusinessTodosResponse>;
  /**
   * @param callback The callback
   */
  getPartnerBusinessTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerBusinessTodos(
    options: Models.OriginationReadApiGetPartnerBusinessTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getPartnerBusinessTodos(
    options?:
      | Models.OriginationReadApiGetPartnerBusinessTodosOptionalParams
      | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetPartnerBusinessTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerBusinessTodosOperationSpec,
      callback
    ) as Promise<Models.GetPartnerBusinessTodosResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerBusinessTodoByIdResponse>
   */
  getPartnerBusinessTodoById(
    id: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPartnerBusinessTodoByIdResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  getPartnerBusinessTodoById(id: string, callback: msRest.ServiceCallback<Models.TodoItem>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerBusinessTodoById(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TodoItem>
  ): void;
  getPartnerBusinessTodoById(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TodoItem>,
    callback?: msRest.ServiceCallback<Models.TodoItem>
  ): Promise<Models.GetPartnerBusinessTodoByIdResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      getPartnerBusinessTodoByIdOperationSpec,
      callback
    ) as Promise<Models.GetPartnerBusinessTodoByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCurrentPartnerBusinessTodosResponse>
   */
  getCurrentPartnerBusinessTodos(
    options?: Models.OriginationReadApiGetCurrentPartnerBusinessTodosOptionalParams
  ): Promise<Models.GetCurrentPartnerBusinessTodosResponse>;
  /**
   * @param callback The callback
   */
  getCurrentPartnerBusinessTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCurrentPartnerBusinessTodos(
    options: Models.OriginationReadApiGetCurrentPartnerBusinessTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getCurrentPartnerBusinessTodos(
    options?:
      | Models.OriginationReadApiGetCurrentPartnerBusinessTodosOptionalParams
      | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetCurrentPartnerBusinessTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCurrentPartnerBusinessTodosOperationSpec,
      callback
    ) as Promise<Models.GetCurrentPartnerBusinessTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCompletedPartnerBusinessTodosResponse>
   */
  getCompletedPartnerBusinessTodos(
    options?: Models.OriginationReadApiGetCompletedPartnerBusinessTodosOptionalParams
  ): Promise<Models.GetCompletedPartnerBusinessTodosResponse>;
  /**
   * @param callback The callback
   */
  getCompletedPartnerBusinessTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCompletedPartnerBusinessTodos(
    options: Models.OriginationReadApiGetCompletedPartnerBusinessTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getCompletedPartnerBusinessTodos(
    options?:
      | Models.OriginationReadApiGetCompletedPartnerBusinessTodosOptionalParams
      | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetCompletedPartnerBusinessTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCompletedPartnerBusinessTodosOperationSpec,
      callback
    ) as Promise<Models.GetCompletedPartnerBusinessTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetExpiredPartnerBusinessTodosResponse>
   */
  getExpiredPartnerBusinessTodos(
    options?: Models.OriginationReadApiGetExpiredPartnerBusinessTodosOptionalParams
  ): Promise<Models.GetExpiredPartnerBusinessTodosResponse>;
  /**
   * @param callback The callback
   */
  getExpiredPartnerBusinessTodos(callback: msRest.ServiceCallback<Models.TodoItem[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getExpiredPartnerBusinessTodos(
    options: Models.OriginationReadApiGetExpiredPartnerBusinessTodosOptionalParams,
    callback: msRest.ServiceCallback<Models.TodoItem[]>
  ): void;
  getExpiredPartnerBusinessTodos(
    options?:
      | Models.OriginationReadApiGetExpiredPartnerBusinessTodosOptionalParams
      | msRest.ServiceCallback<Models.TodoItem[]>,
    callback?: msRest.ServiceCallback<Models.TodoItem[]>
  ): Promise<Models.GetExpiredPartnerBusinessTodosResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getExpiredPartnerBusinessTodosOperationSpec,
      callback
    ) as Promise<Models.GetExpiredPartnerBusinessTodosResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTransactionTypesResponse>
   */
  getTransactionTypes(options?: msRest.RequestOptionsBase): Promise<Models.GetTransactionTypesResponse>;
  /**
   * @param callback The callback
   */
  getTransactionTypes(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getTransactionTypes(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  getTransactionTypes(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetTransactionTypesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getTransactionTypesOperationSpec,
      callback
    ) as Promise<Models.GetTransactionTypesResponse>;
  }

  /**
   * @param url
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDesignElementsOnSiteResponse>
   */
  getDesignElementsOnSite(
    url: string,
    options?: Models.OriginationReadApiGetDesignElementsOnSiteOptionalParams
  ): Promise<Models.GetDesignElementsOnSiteResponse>;
  /**
   * @param url
   * @param callback The callback
   */
  getDesignElementsOnSite(url: string, callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  /**
   * @param url
   * @param options The optional parameters
   * @param callback The callback
   */
  getDesignElementsOnSite(
    url: string,
    options: Models.OriginationReadApiGetDesignElementsOnSiteOptionalParams,
    callback: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): void;
  getDesignElementsOnSite(
    url: string,
    options?:
      | Models.OriginationReadApiGetDesignElementsOnSiteOptionalParams
      | msRest.ServiceCallback<{ [propertyName: string]: string }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): Promise<Models.GetDesignElementsOnSiteResponse> {
    return this.sendOperationRequest(
      {
        url,
        options
      },
      getDesignElementsOnSiteOperationSpec,
      callback
    ) as Promise<Models.GetDesignElementsOnSiteResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllSitesResponse>
   */
  getAllSites(options?: msRest.RequestOptionsBase): Promise<Models.GetAllSitesResponse>;
  /**
   * @param callback The callback
   */
  getAllSites(callback: msRest.ServiceCallback<Models.SiteDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllSites(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SiteDetails[]>): void;
  getAllSites(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SiteDetails[]>,
    callback?: msRest.ServiceCallback<Models.SiteDetails[]>
  ): Promise<Models.GetAllSitesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllSitesOperationSpec,
      callback
    ) as Promise<Models.GetAllSitesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDesignElementListResponse>
   */
  getDesignElementList(options?: msRest.RequestOptionsBase): Promise<Models.GetDesignElementListResponse>;
  /**
   * @param callback The callback
   */
  getDesignElementList(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDesignElementList(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  getDesignElementList(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetDesignElementListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDesignElementListOperationSpec,
      callback
    ) as Promise<Models.GetDesignElementListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDesignLogoListResponse>
   */
  getDesignLogoList(options?: msRest.RequestOptionsBase): Promise<Models.GetDesignLogoListResponse>;
  /**
   * @param callback The callback
   */
  getDesignLogoList(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDesignLogoList(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  getDesignLogoList(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetDesignLogoListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDesignLogoListOperationSpec,
      callback
    ) as Promise<Models.GetDesignLogoListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllWhiteLabelSiteDetailResponse>
   */
  getAllWhiteLabelSiteDetail(options?: msRest.RequestOptionsBase): Promise<Models.GetAllWhiteLabelSiteDetailResponse>;
  /**
   * @param callback The callback
   */
  getAllWhiteLabelSiteDetail(callback: msRest.ServiceCallback<Models.WhiteLabelSiteDetail[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllWhiteLabelSiteDetail(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.WhiteLabelSiteDetail[]>
  ): void;
  getAllWhiteLabelSiteDetail(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.WhiteLabelSiteDetail[]>,
    callback?: msRest.ServiceCallback<Models.WhiteLabelSiteDetail[]>
  ): Promise<Models.GetAllWhiteLabelSiteDetailResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllWhiteLabelSiteDetailOperationSpec,
      callback
    ) as Promise<Models.GetAllWhiteLabelSiteDetailResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getAgentAnnouncementsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Agent/{agentId}/get-agent-announcement',
  urlParameters: [Parameters.agentId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Announcement'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllAgentAnnouncementsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Agent/get-all-agent-announcements',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Announcement'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Agent/{partnerId}/get-agents',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.showDeleted],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Agent'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Agent/{agentId}/get-agent',
  urlParameters: [Parameters.agentId0],
  responses: {
    200: {
      bodyMapper: Mappers.Agent
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Agent/{agentId}/get-agent-applications',
  urlParameters: [Parameters.agentId0],
  queryParameters: [Parameters.showCancelled],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ApplicationListProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const searchAgentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Agent/{partnerId}/search-agents',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.search0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Agent'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const searchAllAgentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Agent/search-all-agents',
  queryParameters: [Parameters.search0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Agent'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const advanceExtendedSummaryOnAgentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Agent/{agentId}/advance-extended-summary-on-agent',
  urlParameters: [Parameters.agentId0],
  queryParameters: [Parameters.skip0, Parameters.take0],
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceFullDetailPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadAdvanceExtendedSummaryOnAgentFileOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Agent/{agentId}/download-advance-extended-summary-on-agent-csv',
  urlParameters: [Parameters.agentId0],
  queryParameters: [Parameters.startDate, Parameters.endDate, Parameters.skip0, Parameters.take0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentSentLinkRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Agent/{agentId}/get-agent-sent-link-request',
  urlParameters: [Parameters.agentId0],
  queryParameters: [Parameters.requestId0],
  responses: {
    200: {
      bodyMapper: Mappers.SendLinkRequest
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentSendLinkUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Agent/{agentId}/get-agent-send-link-url',
  urlParameters: [Parameters.agentId0],
  queryParameters: [Parameters.partnerId1, Parameters.requestId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAlertNotificationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/AlertNotification/get-alert-notifications',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'AlertNotificationProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAlertNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/AlertNotification/get-alert-notification/{Id}',
  urlParameters: [Parameters.id0],
  responses: {
    200: {
      bodyMapper: Mappers.AlertNotificationProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getAlertNotificationCategoriesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/AlertNotificationEmailCategory/get-alert-notification-categories',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'AlertNotificationEmailCategoryProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAlertNotificationCategoriesByEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/AlertNotificationEmailCategory/get-alert-notification-categories-by-email',
  queryParameters: [Parameters.email0],
  responses: {
    200: {
      bodyMapper: Mappers.AlertNotificationProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicantNamesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/{organisationId}/applicant-names',
  urlParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Name'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicantsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/{applicationId}/applicants',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ApplicantProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPrimaryApplicantOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/{applicationId}/primary-applicants',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.PrimaryApplicant
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicantDriverLicenceUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/{applicantId}/get-driver-licence-url',
  urlParameters: [Parameters.applicantId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicantOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/{applicantId}/get-applicant',
  urlParameters: [Parameters.applicantId0],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicantProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicantDriverLicenceImageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/{applicantId}/get-driver-licence-image',
  urlParameters: [Parameters.applicantId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'ByteArray'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSecondaryApplicantOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/{applicationId}/secondary-applicants',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.SecondaryApplicant
    },
    500: {},
    default: {}
  },
  serializer
};

const getOrganisationExtraDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/get-organisation-extra-details',
  queryParameters: [Parameters.organisationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const validateSecondaryEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/validate-secondary-email',
  queryParameters: [Parameters.applicationId1, Parameters.email0],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const validateSecondaryMobileOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/validate-secondary-mobile',
  queryParameters: [Parameters.applicationId1, Parameters.mobile],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const isApplicantAddressValidOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/is-applicant-address-valid',
  queryParameters: [Parameters.organisationId1, Parameters.applicantId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isNotAllowedAddressOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/is-not-allowed-address',
  queryParameters: [Parameters.address],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isExistingCustomerEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/is-existing-customer-email',
  queryParameters: [Parameters.email0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isExistingCustomerPhoneOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/is-existing-customer-phone',
  queryParameters: [Parameters.phone],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllCancelReasonsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-all-cancel-reasons',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CancelReason'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllPartnerApplicationStatusTypesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-all-partner-application-status-types',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerApplicationStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-partner-application-status',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: Mappers.PartnerApplicationStatus
    },
    500: {},
    default: {}
  },
  serializer
};

const inDraftOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/in-draft',
  queryParameters: [Parameters.name],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const overviewOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/summary',
  queryParameters: [Parameters.partnerId1],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationsOverview
    },
    500: {},
    default: {}
  },
  serializer
};

const validateOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/valid-otp',
  queryParameters: [Parameters.otp],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const applicationStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-status',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Boolean'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAlertOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-alert',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const testEquifaxAlertOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'test-equifax-alert',
  queryParameters: [Parameters.applicationId1, Parameters.applicantId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getAlertsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-alerts',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Alert'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const testAlertOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/test-alert',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const testAllAlertOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/test-all-alert',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const applicationConnectionStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-connection-status',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Boolean'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const hasOrganisationNameBeenFinalisedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/has-organisation-name-been-finalised',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isOfferReadyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/is-offer-ready',
  queryParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const hasLoggedInOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/has-logged-in',
  queryParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadContractFileOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/download-contract',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.fileType],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-data',
  queryParameters: [Parameters.objectType, Parameters.id1, Parameters.partnerId1],
  responses: {
    200: {
      bodyMapper: Mappers.Summary
    },
    500: {},
    default: {}
  },
  serializer
};

const getCommissionDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-commission-details',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.secured, Parameters.establishmentFeeRate, Parameters.commissionRate],
  responses: {
    200: {
      bodyMapper: Mappers.CommissionDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationIdOnShortCodeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{shortCode}/short-code-on-application-id',
  urlParameters: [Parameters.shortCode0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{shortCode}/get-token',
  urlParameters: [Parameters.shortCode0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationOnShortCodeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{shortCode}/application',
  urlParameters: [Parameters.shortCode0],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getShortCodeOnApplicationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/short-code',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getQuickUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/quick-url',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSecondApplicantUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/second-applicant-url',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBaseUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/base-url',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-application-list',
  queryParameters: [
    Parameters.orderBy0,
    Parameters.skip0,
    Parameters.take0,
    Parameters.status0,
    Parameters.showCancel,
    Parameters.addFacilityLimit,
    Parameters.cancelledDays0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjectionExtensionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerApplicationListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-partner-application-list',
  queryParameters: [
    Parameters.partnerId1,
    Parameters.orderBy0,
    Parameters.skip0,
    Parameters.take0,
    Parameters.status0,
    Parameters.showCancel,
    Parameters.addFacilityLimit,
    Parameters.cancelledDays0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjectionExtensionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getCompleteApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-complete-applications',
  queryParameters: [
    Parameters.orderBy0,
    Parameters.skip0,
    Parameters.take0,
    Parameters.status0,
    Parameters.showCancel,
    Parameters.addFacilityLimit,
    Parameters.cancelledDays0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjectionExtensionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerCancelledApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-partner-cancelled-applications',
  queryParameters: [Parameters.partnerId1, Parameters.cancelledDays1],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjectionExtensionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerCompletedApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-partner-completed-applications',
  queryParameters: [Parameters.partnerId1],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjectionExtensionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerAllApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-partner-all-applications',
  queryParameters: [Parameters.partnerId1],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjectionExtensionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentCancelledApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-agent-cancelled-applications',
  queryParameters: [Parameters.agentId1, Parameters.cancelledDays1],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjectionExtensionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentCompletedApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-agent-completed-applications',
  queryParameters: [Parameters.agentId1],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjectionExtensionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentAllApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-agent-all-applications',
  queryParameters: [Parameters.agentId1],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjectionExtensionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getBusinessNameFromApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/business-name',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankfeedAccountsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/bankfeed-accounts',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.excludeCreditCards],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'BankAccount'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankfeedMFADetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/bankfeed-mfa',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.yodlee],
  responses: {
    200: {
      bodyMapper: Mappers.PlexeBankStatementsProxyModelsMFADetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankfeedFaskLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/bankfeed-fastlink',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.PlexeBankStatementsProxyModelsFastLinkToken
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankfeedPlaidLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/bankfeed-plaidlink',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.redirectUri, Parameters.requestId1, Parameters.isNew],
  responses: {
    200: {
      bodyMapper: Mappers.PlexeBankStatementsProxyModelsFastLinkToken
    },
    500: {},
    default: {}
  },
  serializer
};

const isProvisoApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/is-proviso-application',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const offerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/offer/{offerId}',
  urlParameters: [Parameters.applicationId0, Parameters.offerId0],
  responses: {
    200: {
      bodyMapper: Mappers.OfferAmount
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-application-settings',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationSettings
    },
    500: {},
    default: {}
  },
  serializer
};

const getCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-communication',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.direct],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CommunicationProjectionExtension'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isNewCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/is-new-communication',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const commissionMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/commission',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.loanType],
  responses: {
    200: {
      bodyMapper: Mappers.Commission
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const debtorSummaryOnApplicationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/debtor-summary-on-application-id',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.orderBy1],
  responses: {
    200: {
      bodyMapper: Mappers.LoanDebtorProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateIndicativeFixedRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/calculate-indicative-fixed-repayment',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.amount0, Parameters.terms],
  responses: {
    200: {
      bodyMapper: Mappers.FixedRepaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateIndicativeLineOfCreditSliderOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/calculate-indicative-line-of-credit-slider',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.amount1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'SliderResult'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateIndicativeLineOfCreditRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/calculate-indicative-line-of-credit-repayment',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [
    Parameters.amount1,
    Parameters.percentageOfIncome,
    Parameters.fixedRepaymentCalculation,
    Parameters.term
  ],
  responses: {
    200: {
      bodyMapper: Mappers.LineOfCreditRepaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateIndicativePercentOfIncomeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/calculate-indicative-percent-of-income',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.amount0, Parameters.percent],
  responses: {
    200: {
      bodyMapper: Mappers.PercentOfIncomeCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateIndicativeInvoicePaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/calculate-indicative-invoice-payment',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.amount0, Parameters.overrideCalculation],
  responses: {
    200: {
      bodyMapper: Mappers.InvoicePaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const getMaximumTermOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-maximum-term',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Number'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const incomeDetailsForApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/income-details',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.IncomeDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const isApplicationCompleteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/is-application-complete',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getOfferDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/offerdetails',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.force],
  responses: {
    200: {
      bodyMapper: Mappers.OfferDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getOrganisationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/organisation-id',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getOfferIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/offer-id',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationOnCustomerIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{customerId}/get-application-id',
  urlParameters: [Parameters.customerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationsByCustomerIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{customerId}/get-applications-by-customer',
  urlParameters: [Parameters.customerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ApplicationListProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationDetailsOnCustomerIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{customerId}/get-application-on-customer',
  urlParameters: [Parameters.customerId0],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationOnOrganisationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{organisationId}/get-application-id-organisation',
  urlParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationsOnPartnerIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{partnerId}/get-applications-partner-id',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationsOnPartnerIdOnStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{partnerId}/get-applications-partner-id-on-status',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.status0],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationsOnPartnerIdOnStatusCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{partnerId}/get-applications-partner-id-on-status-csv',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.status0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getApplicationsOnPartnerBusinessNameOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-applications-on-partner-business-name',
  queryParameters: [Parameters.businessName, Parameters.status0],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationDetailOnOrganisationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{organisationId}/get-application-on-organisation',
  urlParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationOnOrganisationNameOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{organisation}/get-application-id-organisation-name',
  urlParameters: [Parameters.organisation],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateApplicationCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/update-application-cache',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.shortCode1, Parameters.customerId1, Parameters.organisationId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateCommunicationCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/update-communication-cache',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getSingleApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{customerId}/get-single-application',
  urlParameters: [Parameters.customerId0],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getByPartnerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-by-partner',
  queryParameters: [Parameters.partnerId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ApplicationListProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationCurrentProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-current-process',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.applicantId1, Parameters.isSecondary],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationCurrentProcessStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-current-process-status',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.applicantId1, Parameters.isSecondary],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationProcessStatus
    },
    500: {},
    default: {}
  },
  serializer
};

const getPrimaryAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/primary-bank-account',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.BankAccount
    },
    500: {},
    default: {}
  },
  serializer
};

const getMobileNumberByEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-mobile-number-by-email',
  queryParameters: [Parameters.email0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getContractSignatureOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-contract-signature',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ContractSignature'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankAccountConnectionSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-bankaccount-connection-settings',
  responses: {
    200: {
      bodyMapper: Mappers.BankAccountConnectionSettings
    },
    500: {},
    default: {}
  },
  serializer
};

const sanitizeCommunicationTemplateMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/sanitize-communication-template-message',
  queryParameters: [Parameters.applicationId0, Parameters.isPartner, Parameters.isAgent],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      defaultValue: '',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getFrontendBaseUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-frontend-base-url',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankfeedPlaidLinkDemoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/bankfeed-plaidlink-demo',
  responses: {
    200: {
      bodyMapper: Mappers.PlexeBankStatementsProxyModelsFastLinkToken
    },
    500: {},
    default: {}
  },
  serializer
};

const isValidStateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{zipCode}/is-valid-state',
  urlParameters: [Parameters.zipCode],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankAccountConnectionDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/bankaccount-connection-details',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'BankAccountConnectionDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationContactsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-application-contacts',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationStatusSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-application-status-summary',
  queryParameters: [Parameters.afterMonths, Parameters.cancelledDays2],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'StatusSummary'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationOtherStatusSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-application-other-status-summary',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'StatusSummary'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerApplicationStatusSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-partner-application-status-summary',
  queryParameters: [Parameters.afterMonths, Parameters.cancelledDays3],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'StatusSummary'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerApplicationOtherStatusSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/get-partner-application-other-status-summary',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'StatusSummary'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isApplicationAddressValidOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/is-application-address-valid',
  queryParameters: [Parameters.organisationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getUnderReviewKycNoteDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-under-review-kyc-note-data',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Object'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isManualReviewOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationAutoApproval/{applicationId}/is-manual-review',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const underReviewTestOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationAutoApproval/{applicationId}/under-review-test',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.DecisionDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const compareEnableDisableApprovalTestOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationAutoApproval/{applicationId}/compare-enabledisable-test',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getUnderReviewStateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationAutoApproval/get-underreview-state',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllNotesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationInsight/get-all-notes',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Note'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getNotesByTypeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationInsight/get-notes-by-type',
  queryParameters: [Parameters.applicationId1, Parameters.type],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Note'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationInsightsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationInsight/get-application-insights',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Note'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getOldApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/get-old-applications',
  queryParameters: [Parameters.days0, Parameters.status1, Parameters.autoCancel],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationSummaryCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/get-application-summary-csv',
  queryParameters: [Parameters.dateParameter],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getLoanNoAdvanceSummaryCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/get-loan-no-advance-summary-csv',
  queryParameters: [Parameters.dateParameter],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getCancelOldOfferReadyApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/get-cancel-old-offer-ready-applications',
  queryParameters: [Parameters.days0, Parameters.autoCancel],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const contactListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/contact-list',
  queryParameters: [Parameters.partnerId2],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Sequence',
              element: {
                type: {
                  name: 'String'
                }
              }
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const offerReadyReminderForPartnerAndAgentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/get-offer-ready-reminder-for-partner-and-agent',
  queryParameters: [Parameters.send],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const offerReadyReminderForCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/get-offer-ready-reminder-for-customer',
  queryParameters: [Parameters.send],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const draftReminderForPartnerAndAgentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/get-draft-reminder-for-partner-and-agent',
  queryParameters: [Parameters.send],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const undrawnAdvanceReminderForPartnerAndAgentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/get-undrawn-advance-reminder-for-partner-and-agent',
  queryParameters: [Parameters.send],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const undrawnAdvanceReminderForCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/get-undrawn-advance-reminder-for-customer',
  queryParameters: [Parameters.send],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const noApplicationAndNoLoanCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/get-no-application-and-no-loan-customer',
  queryParameters: [Parameters.disable],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getInvalidStateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ApplicationProcessing/get-invalid-state',
  queryParameters: [Parameters.send],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadBankStatementDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/bankstatement/{organisationId}/download-bankstatement-document',
  urlParameters: [Parameters.organisationId0],
  queryParameters: [Parameters.fileName],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankStatementDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/bankstatement/{organisationId}/get-bankstatement-documents',
  urlParameters: [Parameters.organisationId0],
  queryParameters: [Parameters.externalSubmitted],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'FileDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllBankStatementDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/bankstatement/{organisationId}/get-all-bankstatement-documents',
  urlParameters: [Parameters.organisationId0],
  queryParameters: [Parameters.externalSubmitted],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'FileDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadBankStatementDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/bankstatement/{organisationId}/download-bankstatement-documents',
  urlParameters: [Parameters.organisationId0],
  queryParameters: [Parameters.externalSubmitted],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TransactionDataFile'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankStatementMissingTransactionDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/bankstatement/{organisationId}/get-bankstatement-missingtransaction-documents',
  urlParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'FileDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadBankStatementMissingTransactionDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/bankstatement/{organisationId}/download-bankstatement-missingtransaction-document',
  urlParameters: [Parameters.organisationId0],
  queryParameters: [Parameters.fileName],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankStatementMissingTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/bankstatement/{organisationId}/get-bankstatement-missing-transactions',
  urlParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlexeOrganisationServiceModelsTransactionMatrix'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getUploadBankStatementUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/bankstatement/{organisationId}/get-upload-bankstatement-url',
  urlParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getChargeOffDateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ChargeOff/{loanId}/get-chargeOff-date',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'DateTime'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getManualCheckListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/CheckList/get-manual-check-list',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: Mappers.CheckList
    },
    500: {},
    default: {}
  },
  serializer
};

const getSettlementCheckListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/CheckList/get-settlement-check-list',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: Mappers.CheckList
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentApplicationCommunicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Communication/{agentId}/get-agent-application-communications',
  urlParameters: [Parameters.agentId0],
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CommunicationProjectionExtension'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isNewAgentCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Communication/{agentId}/is-new-agent-communication',
  urlParameters: [Parameters.agentId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isNewAgentApplicationCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Communication/{agentId}/is-new-agent-application-communication',
  urlParameters: [Parameters.agentId0],
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isNewAgentApplicationCommunicationListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Communication/{agentId}/is-new-agent-application-communication-list',
  urlParameters: [Parameters.agentId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Boolean'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerCommunicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Communication/{partnerId}/get-partner-communications',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CommunicationProjectionExtension'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerApplicationCommunicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Communication/{partnerId}/get-partner-application-communications',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CommunicationProjectionExtension'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isNewPartnerCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Communication/{partnerId}/is-new-partner-communication',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isNewPartnerApplicationCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Communication/{partnerId}/is-new-partner-application-communication',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isNewPartnerApplicationCommunicationListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Communication/{partnerId}/is-new-partner-application-communication-list',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Boolean'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankStatementCommunicationMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Communication/{organisationId}/get-bank-statement-communication-message',
  urlParameters: [Parameters.organisationId0],
  queryParameters: [Parameters.isCustomer, Parameters.isPartner, Parameters.isAgent],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getCommunicationTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Communication/get-communication-todos',
  queryParameters: [Parameters.dateParameter, Parameters.isSave],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodayCommunication'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getCommunicationRulesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/CommunicationRule/get-communication-rules',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CommunicationRule'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const isRuleAllowedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/CommunicationRule/is-rule-allowed',
  queryParameters: [Parameters.ruleName, Parameters.userId],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    default: {}
  },
  serializer
};

const getStartupConfigOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Config/get-startup-config',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isAccountingSystemEnabledOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Config/is-accounting-system-enabled',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isApplicantImageCaptureEnabledOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Config/is-applicant-image-capture-enabled',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllContactsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Contact/{applicationId}/get-all-contacts',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Contact'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLocationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Customer',
  queryParameters: [Parameters.ipAddress],
  responses: {
    200: {
      bodyMapper: Mappers.Location
    },
    500: {},
    default: {}
  },
  serializer
};

const getCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Customer/{customerId}',
  urlParameters: [Parameters.customerId0],
  responses: {
    200: {
      bodyMapper: Mappers.Customer
    },
    500: {},
    default: {}
  },
  serializer
};

const getCustomerOnEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Customer/{email}/email',
  urlParameters: [Parameters.email1],
  responses: {
    200: {
      bodyMapper: Mappers.Customer
    },
    500: {},
    default: {}
  },
  serializer
};

const getCustomersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Customer/get-customers',
  queryParameters: [Parameters.searchText, Parameters.skip0, Parameters.take0, Parameters.direction, Parameters.column],
  responses: {
    200: {
      bodyMapper: Mappers.CustomerProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const searchCustomersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Customer/search-customers',
  queryParameters: [Parameters.charList],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAuthorizationTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Customer/get-authorization-token',
  queryParameters: [Parameters.customerId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getDashboardAccountsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Dashboard/accounts',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: Mappers.DashboardAccounts
    },
    500: {},
    default: {}
  },
  serializer
};

const getDashboardLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Dashboard/loans',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: Mappers.DashboardLoan
    },
    500: {},
    default: {}
  },
  serializer
};

const getDashboardBusinessPerformanceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Dashboard/business-performances',
  queryParameters: [Parameters.applicationId1, Parameters.monthRange],
  responses: {
    200: {
      bodyMapper: Mappers.DashboardBusinessPerformance
    },
    500: {},
    default: {}
  },
  serializer
};

const getDashboardInsightOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Dashboard/insights',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: Mappers.DashboardInsight
    },
    500: {},
    default: {}
  },
  serializer
};

const getDashboardCashFlowsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Dashboard/cashflows',
  queryParameters: [Parameters.applicationId1, Parameters.monthRange],
  responses: {
    200: {
      bodyMapper: Mappers.DashboardCashFlows
    },
    500: {},
    default: {}
  },
  serializer
};

const getDashboardOutgoingInoviceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Dashboard/outgoing-invoices',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: Mappers.DashboardOutgoingInvoices
    },
    500: {},
    default: {}
  },
  serializer
};

const buildOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Dashboard/build',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getLoanRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Dashboard/loan-repayment',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: Mappers.DashboardLoanRepayment
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanUtilisationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Dashboard/get-loan-utilisation',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: Mappers.DashboardCreditUtilisation
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanFundingOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Dashboard/get-loan-funding',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'DashboardFundUtilisation'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllDisplayMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/get-all-display-message',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'DisplayMessageModal'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getDisplayMessageForLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/get-display-message-for-customer',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'DisplayMessageModal'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllQuestionMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/get-all-question-message',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'QuestionMessageModal'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getQuestionMessageForLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/get-question-message-for-customer',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'QuestionMessageModal'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllQuestionMessageForLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/get-all-question-message-for-customer',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Sequence',
              element: {
                type: {
                  name: 'Composite',
                  className: 'QuestionMessageModal'
                }
              }
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSystemMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/DisplayMessage/get-system-message',
  queryParameters: [Parameters.isReset],
  responses: {
    200: {
      bodyMapper: Mappers.SystemMessageModal
    },
    500: {},
    default: {}
  },
  serializer
};

const getDocumentsPagedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/DocumentSupport/{applicationId}/get-documents-paged',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.externalSubmitted],
  responses: {
    200: {
      bodyMapper: Mappers.DatedTransactionDataFilePagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/DocumentSupport/{applicationId}/get-document',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.fileName],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/DocumentSupport/{applicationId}/download-documents',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TransactionDataFile'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getEarlyRepaymentDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/EarlyRepayment/{loanId}/get-early-repayment',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: Mappers.EarlyRepaymentDetail
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllContractDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Files/{applicationId}/get-all-contract-documents',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ContractDocument'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllContractDocumentsPagedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Files/{applicationId}/get-all-contract-documents-paged',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.skip0, Parameters.take0],
  responses: {
    200: {
      bodyMapper: Mappers.ContractDocumentPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getContractDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Files/{applicationId}/get-contract-details',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const deletePastContractOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Files/{applicationId}/delete-past-contract',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.documentId0],
  responses: {
    200: {
      bodyMapper: Mappers.ContractDocumentPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getBaseSupportDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Files/get-base-support-documents',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ContractDocument'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadContractFileOnDocumentIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Files/{documentId}/get-contract-document-on-documentid',
  urlParameters: [Parameters.documentId1],
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getW9FormSampleDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Files/get-w9form-sample-document',
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getIntelligenceListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Intelligence/get-intelligence-list',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'IntelligenceResult'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isLegalFlaggedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Intelligence/is-legal-flagged',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getInvestorsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Investor/get-investors',
  queryParameters: [Parameters.search1, Parameters.skip1, Parameters.take1, Parameters.direction, Parameters.column],
  responses: {
    200: {
      bodyMapper: Mappers.CustomerProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const isBankTransactionDataStaleOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/is-bank-transaction-data-stale',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankTransactionDataStaleMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-bank-transaction-data-stale-message',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLastDisableReasonOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-last-disabled-reason',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllTestNameDisableReasonsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-all-test-name-disabled-reasons',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllLoansTestNameDisableReasonsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/get-all-loans-test-name-disabled-reasons',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'HealthRuleDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllInternalDisableReasonsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-all-internal-disabled-reasons',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllExternalDisableReasonsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-all-external-disabled-reasons',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoansOnCustomerIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{customerId}/get-loans-id',
  urlParameters: [Parameters.customerId0],
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.orderBy2, Parameters.includedClosed],
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getPaymentQueriesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-payment-queries',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PaymentQuery'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanReadyApplicationsOnCustomerIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{customerId}/get-loan-ready-applications',
  urlParameters: [Parameters.customerId0],
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.orderBy2],
  responses: {
    200: {
      bodyMapper: Mappers.LoanReadyApplicationPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getCommissionOnLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/commission',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.secured],
  responses: {
    200: {
      bodyMapper: Mappers.Commission
    },
    500: {},
    default: {}
  },
  serializer
};

const getPaymentArrangementsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/payment-arrangements',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PaymentArrangement'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanNotificationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-loan-notifications',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.onlyUnread, Parameters.skip1, Parameters.take1],
  responses: {
    200: {
      bodyMapper: Mappers.LoanNotificationProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanByOrganisationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{organisationId}/get-loan-on-organisation-id',
  urlParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-loan',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanByApplicationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{applicationId}/get-loan-by-applicationid',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanDataByOrganistionIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{organisationId}/get-loan-data-by-organisation',
  urlParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanDataByLoanIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-loan-data-by-loan',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanBalancesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-loan-balances',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.force],
  responses: {
    200: {
      bodyMapper: Mappers.LoanBalances
    },
    500: {},
    default: {}
  },
  serializer
};

const getEveryLoanBalancesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/get-every-loan-balances',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getEveryLoanBalancesCsvLocalOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/get-every-loan-balances-csv-local',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getAllLoanBalancesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-all-loan-balances',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.force],
  responses: {
    200: {
      bodyMapper: Mappers.AllLoanBalances
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-loan-settings',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.force],
  responses: {
    200: {
      bodyMapper: Mappers.LoanSettings
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllLoanDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/get-loan-data',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerLoanProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllLoanDataApplicationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/get-loan-data-applicationid',
  queryParameters: [Parameters.appID],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerLoanProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/list',
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.orderBy2, Parameters.includedClosed],
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getClosedLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/closed',
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getEnabledLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/enabled',
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getDisabledLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/disabled',
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoansByTypeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/by-type',
  queryParameters: [Parameters.typeName],
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoansByHealthOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/by-criteria',
  queryParameters: [Parameters.criteria],
  responses: {
    200: {
      bodyMapper: Mappers.CustomerLoanProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getPaidCommissionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/commission-summary',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.orderBy1],
  responses: {
    200: {
      bodyMapper: Mappers.PaidCommissionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const debtorSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/debtor-summary',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.orderBy0],
  responses: {
    200: {
      bodyMapper: Mappers.LoanDebtorProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const transactionSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/transaction-summary',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0,
    Parameters.orderBy0,
    Parameters.orderbyDescending,
    Parameters.filterColumn,
    Parameters.filterValue,
    Parameters.viewType,
    Parameters.showPaymentRequests
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const transactionsOutstandingBalanceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/transactions-outstanding-balance',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.dateParameter],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Number'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getTransactionBalanceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-transaction-balance',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.dateParameter],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Number'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/get-all-transactions',
  queryParameters: [Parameters.startDate, Parameters.endDate],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TransactionProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllRepaymentTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/get-all-repayment-transactions',
  queryParameters: [Parameters.startDate, Parameters.endDate],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TransactionProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllRepaymentTransactionsPerLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/get-all-repayment-transactions-perloan',
  queryParameters: [Parameters.loanId1, Parameters.startDate, Parameters.endDate],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TransactionProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const transactionSummaryOnIdsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/transaction-summary-on-ids',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Uuid'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TransactionProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const advanceSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/advance-summary',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.orderBy0],
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const advanceFullSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/advance-full-summary',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.orderBy0],
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceAdjustmentProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const advanceExtendedSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/advance-extended-summary',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.orderBy0],
  responses: {
    200: {
      bodyMapper: Mappers.ExtendedAdvanceProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const advanceHistoryDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/advance-details/{advanceId}',
  urlParameters: [Parameters.loanId0, Parameters.advanceId1],
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const cashflowDetailsMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/cashflow-details',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: Mappers.CashflowDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const incomeDetailsMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/income-details',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: Mappers.IncomeDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateFixedRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/calculate-fixed-repayment',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.amount0, Parameters.terms],
  responses: {
    200: {
      bodyMapper: Mappers.FixedRepaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateLineOfCreditSliderOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/calculate-line-of-credit-slider',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.amount1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Number'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateLineOfCreditRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/calculate-line-of-credit-repayment',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.amount1,
    Parameters.percentageOfIncome,
    Parameters.fixedRepaymentCalculation,
    Parameters.term
  ],
  responses: {
    200: {
      bodyMapper: Mappers.LineOfCreditRepaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculatePercentOfIncomeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/calculate-percent-of-income',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.amount0, Parameters.percent],
  responses: {
    200: {
      bodyMapper: Mappers.PercentOfIncomeCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateInvoicePaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/calculate-invoice-payment',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.amount0, Parameters.overrideCalculation],
  responses: {
    200: {
      bodyMapper: Mappers.InvoicePaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const getMaximumTermOnLoanIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-maximum-term-loan-id',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Number'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateAllLatestAdvanceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/calculate-all-latest-advance',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isLatestAdvanceValidOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/is-latest-advance-valid',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/bank-details',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: Mappers.BankDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllBankDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/get-all-bank-details',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Composite',
              className: 'BankDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isBankSetupOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/is-bank-setup',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const isChangeOfFacilityRequiredOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/is-change-facility-required',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const getNewFacilityLimitOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-new-facility-limit',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Number'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getHighestFacilityLimitOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-highest-facility-limit',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Number'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/application',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const isConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/is-connected',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const isAccountingSystemConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/is-accounting-system-connected',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadTransactionFileOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/download-transactions',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.startDate, Parameters.endDate, Parameters.fileType],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadTransactionStatementOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/download-transactions-statement',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.startDate, Parameters.endDate],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const lookupDebtorsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/lookup-debtors',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.debtor],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateLoanCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/update-loan-cache',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.organisationId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateLoanBalanceCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/update-loan-balance',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.offerId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateLoanSettingCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/update-loan-setting',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.offerId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const isTransactionGreater60DaysOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/is-transaction-greater-60-days',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.transactionId],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAccountingSystemOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/{loanId}/get-accounting-system',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/get-all-loans',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerLoanProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanStatusSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/get-loan-status-summary',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'StatusSummary'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllPaymentTransactionHealthCheckOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/get-all-payment-transaction-health-check',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPaymentTransactionHealthCheckOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Loan/get-payment-transaction-health-check',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getHealthCheckAllOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanProcessing/get-health-check-all',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'HealthRuleDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPaymentQueries1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanReconciliation/{loanId}/get-payment-queries',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PaymentQuery'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getReconciledPaymentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanReconciliation/{loanId}/get-reconciled-payments',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.orderBy0],
  responses: {
    200: {
      bodyMapper: Mappers.ReconciledPaymentPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getPaymentReconciliationTransactionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanReconciliation/{loanId}/get-payment-reconciliation-transactions',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.startDate, Parameters.endDate, Parameters.showAll, Parameters.onlyKnownDebtors],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PaymentAndTransactions'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSyntheticTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanReconciliation/{loanId}/get-synthetic-transactions',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.debtor],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'SyntheticPayment'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSyntheticTransactionsTotalOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanReconciliation/{loanId}/get-synthetic-transactions-total',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.debtor],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Number'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLockDateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LockDate/get-lock-date',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'DateTime'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const customerLookupOfflineOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Lookup/customer-lookup-offline',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerLookupData'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const customerLookupOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Lookup/customer-lookup',
  queryParameters: [Parameters.search1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerLookupData'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Lookup/get-bank-list',
  queryParameters: [Parameters.reset],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankListDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Lookup/get-bank-list-details',
  queryParameters: [Parameters.reset],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlexeBankStatementsProxyModelsInstitution'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSupportedAccountingSoftwareOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Lookup/get-supported-accounting-software',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isZipCodeValidOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Lookup/{zipCode}/is-zipcode-valid',
  urlParameters: [Parameters.zipCode],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAccountingAuthorizeUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Lookup/{organisationId}/accounting-authorize-url/{accountingPackage}',
  urlParameters: [Parameters.accountingPackage, Parameters.organisationId0],
  queryParameters: [Parameters.host],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getReconnectAccountingAuthorizeUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Lookup/{loanId}/reconnect-accounting-authorize-url',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.host],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const searchByUsIndustryNameOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Lookup/search-by-us-industry-name',
  queryParameters: [Parameters.search1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'UsIndustryRecord'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllPlexeNotesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Notes/{applicationId}/get-all-plexe-notes',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Note'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAuthorNotesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Notes/{applicationId}/get-author-notes',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Note'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Notes/{applicationId}/get-all',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Note'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerAnnouncementsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/get-partner-announcement',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Announcement'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllPartnerAnnouncementsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/get-all-partner-announcements',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Announcement'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerBankDetailsOnIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/get-partner-bank-details-on-id',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: Mappers.PartnerBankDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerBankDetailsOnNameOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/get-partner-bank-details-on-name',
  queryParameters: [Parameters.name],
  responses: {
    200: {
      bodyMapper: Mappers.PartnerBankDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerLogoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/get-partner-logo',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const isPartnerIsEnabledOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/is-partner-api-enabled',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerCommissionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/paid-commission',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PartnerPaidCommission'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerCommissionsOnBusinessOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/paid-commission-on-business',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PartnerPaidCommission'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: Mappers.Partner
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerSentLinkRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/get-sent-link-request',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.requestId0],
  responses: {
    200: {
      bodyMapper: Mappers.SendLinkRequest
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerOnEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{email}/email',
  urlParameters: [Parameters.email1],
  responses: {
    200: {
      bodyMapper: Mappers.Partner
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/get-partners',
  queryParameters: [Parameters.searchText, Parameters.skip0, Parameters.take0, Parameters.direction, Parameters.column],
  responses: {
    200: {
      bodyMapper: Mappers.PartnerProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const searchPartnersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/search-partners',
  queryParameters: [Parameters.charList],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PartnerProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllPartnerBusinessNamesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/get-all-partner-business-names',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PartnerProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAuthorizationToken1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/get-authorization-token',
  queryParameters: [Parameters.userName, Parameters.password],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAccreditationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/get-accreditation',
  queryParameters: [Parameters.id2],
  responses: {
    200: {
      bodyMapper: Mappers.PartnerAccreditationRequest
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadBankStatementsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{Id}/download-bank-statements',
  urlParameters: [Parameters.id3],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TransactionDataFile'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadBlankInvoicesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{Id}/download-blank-invoices',
  urlParameters: [Parameters.id3],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TransactionDataFile'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadW9FormOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{Id}/download-w9form',
  urlParameters: [Parameters.id3],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerDailyReportsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/get-partner-daily-reports',
  queryParameters: [Parameters.skip0, Parameters.take0],
  responses: {
    200: {
      bodyMapper: Mappers.PartnerProjectionPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerIdByUrlReferralOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/get-partnerid-by-url-referral',
  queryParameters: [Parameters.urlReferral],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadPartnerLogoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/download-partner-logo',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const advanceExtendedSummaryOnPartnerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/advance-extended-summary-on-partner',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.skip0, Parameters.take0],
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceFullDetailPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadAdvanceExtendedSummaryOnPartnerFileOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/download-advance-extended-summary-on-partner-csv',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.startDate, Parameters.endDate, Parameters.skip0, Parameters.take0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getSendLinkUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/get-send-link-url',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.requestId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerChannelOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/PartnerChannel/get-partner-channel',
  queryParameters: [Parameters.partnerId1],
  responses: {
    200: {
      bodyMapper: Mappers.PartnerChannel
    },
    500: {},
    default: {}
  },
  serializer
};

const getDisabledLoans1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Report/get-disabled-loans',
  queryParameters: [Parameters.code],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getReportDashboardSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportDashboardSetting/get-report-dashboard-settings',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ReportDashboard'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const searchDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Search/search-data',
  queryParameters: [Parameters.search1],
  responses: {
    200: {
      bodyMapper: Mappers.MultipleSearch
    },
    500: {},
    default: {}
  },
  serializer
};

const getCommunicationTemplatesSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Setting/get-communication-templates-settings',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CommunicationTemplateSetting'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getCommunicationTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Setting/get-communication-template-settings/{id}',
  urlParameters: [Parameters.id1],
  responses: {
    200: {
      bodyMapper: Mappers.CommunicationTemplateSetting
    },
    default: {}
  },
  serializer
};

const getSMSTemplatesSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Setting/get-sms-templates-settings',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CommunicationTemplateSetting'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getSMSTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Setting/get-sms-template-settings/{id}',
  urlParameters: [Parameters.id1],
  responses: {
    200: {
      bodyMapper: Mappers.CommunicationTemplateSetting
    },
    default: {}
  },
  serializer
};

const deleteCommunicationTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Setting/delete-communication-template-settings/{templateId}',
  urlParameters: [Parameters.templateId],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteSMSTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Setting/delete-sms-template-settings/{templateId}',
  urlParameters: [Parameters.templateId],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getPartnerCommunicationTemplatesSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Setting/get-partnercommunication-templates-settings',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CommunicationTemplateSetting'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getPartnerCommunicationTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Setting/get-partnercommunication-template-settings/{id}',
  urlParameters: [Parameters.id1],
  responses: {
    200: {
      bodyMapper: Mappers.CommunicationTemplateSetting
    },
    default: {}
  },
  serializer
};

const deletePartnerCommunicationTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Setting/delete-partnercommunication-template-settings/{templateId}',
  urlParameters: [Parameters.templateId],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getAgentCommunicationTemplatesSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Setting/get-agentcommunication-templates-settings',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CommunicationTemplateSetting'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAgentCommunicationTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Setting/get-agentcommunication-template-settings/{id}',
  urlParameters: [Parameters.id1],
  responses: {
    200: {
      bodyMapper: Mappers.CommunicationTemplateSetting
    },
    default: {}
  },
  serializer
};

const deleteAgentCommunicationTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Setting/delete-agentcommunication-template-settings/{templateId}',
  urlParameters: [Parameters.templateId],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const isAdminCommunicationPanelEnabledOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Setting/is-admin-communication-panel-enabled',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    default: {}
  },
  serializer
};

const getBankStatementStaleDetailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/StaleFinancialData/get-bank-statement-stale-detail',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: Mappers.BankStatementStaleDetail
    },
    500: {},
    default: {}
  },
  serializer
};

const getManualUploadDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/StaleFinancialData/get-manual-upload-details',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'BankData'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getDisconnectedBankAccountsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/StaleFinancialData/get-disconnected-bank-accounts',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ConnectionStatusProjection'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const summariesApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Summary/summaries-application',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const summariesApplicationResultsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Summary/summaries-application-results',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const summariesAllRecentCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Summary/summaries-recent-communication',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const summariesAllRecentCustomerCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Summary/summaries-recent-customer-communication',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const summariesAllRecentPartnerAgentCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Summary/summaries-recent-partner-agent-communication',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const summariesApplicationCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Summary/summaries-application-communication',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const summariesLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Summary/summaries-loan',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const summariesLoanCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Summary/summaries-loan-communication',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getCommunicationRequestStringOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SummaryRequest/get-communication-request-string',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationRequestStringOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SummaryRequest/get-application-request-string',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationResultRequestStringOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SummaryRequest/get-application-result-request-string',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanRequestStringOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SummaryRequest/get-loan-request-string',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationCommunicationRequestStringOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SummaryRequest/get-application-communication-request-string',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSupportingDocumentRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SupportingDocument/{applicationId}/get-supporting-document-request',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.isSecondary],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'SupportDocumentDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSupportingDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SupportingDocument/{applicationId}/get-supporting-document/{location}',
  urlParameters: [Parameters.applicationId0, Parameters.location],
  queryParameters: [Parameters.isSecondary],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getSupportingDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SupportingDocument/{applicationId}/get-supporting-document',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.skip0, Parameters.take0, Parameters.isSecondary, Parameters.all],
  responses: {
    200: {
      bodyMapper: Mappers.SupportDocumentDetailsPagedList
    },
    500: {},
    default: {}
  },
  serializer
};

const isSupportedDocumentCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SupportingDocument/{applicationId}/is-supported-document-completed',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.isSecondary],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isSupportedEnabledOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SupportingDocument/{applicationId}/is-supported-enabled',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getRequestSupportingDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SupportingDocument/get-request-supporting-documents',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'SupportDocumentDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getRequestSecondarySupportingDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SupportingDocument/get-request-secondary-supporting-documents',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'SupportDocumentDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadSupportDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SupportingDocument/{applicationId}/download-support-documents',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.isSecondary, Parameters.all],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TransactionDataFile'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAdminTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-admin-todos',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAdminTodoByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-admin-todo-by-id/{id}',
  urlParameters: [Parameters.id1],
  responses: {
    200: {
      bodyMapper: Mappers.TodoItem
    },
    500: {},
    default: {}
  },
  serializer
};

const getCurrentAdminTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-current-admin-todos',
  queryParameters: [Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getCompletedAdminTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-completed-admin-todos',
  queryParameters: [Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getExpiredAdminTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-expired-admin-todos',
  queryParameters: [Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-agent-todos',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentTodosByAgentIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-agent-todos-by-agentid',
  queryParameters: [Parameters.agentId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAgentTodoByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-agent-todo-by-id/{id}',
  urlParameters: [Parameters.id1],
  responses: {
    200: {
      bodyMapper: Mappers.TodoItem
    },
    500: {},
    default: {}
  },
  serializer
};

const getCurrentAgentTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-current-agent-todos',
  queryParameters: [Parameters.agentId1, Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getCompletedAgentTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-completed-agent-todos',
  queryParameters: [Parameters.agentId1, Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getExpiredAgentTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-expired-agent-todos',
  queryParameters: [Parameters.agentId1, Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-partner-todos',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerTodosByPartnerIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-partner-todos-by-partnerId',
  queryParameters: [Parameters.partnerId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerTodoByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-partner-todo-by-id/{id}',
  urlParameters: [Parameters.id1],
  responses: {
    200: {
      bodyMapper: Mappers.TodoItem
    },
    500: {},
    default: {}
  },
  serializer
};

const getCurrentPartnerTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-current-partner-todos',
  queryParameters: [Parameters.partnerId1, Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getCompletedPartnerTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-completed-partner-todos',
  queryParameters: [Parameters.partnerId1, Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getExpiredPartnerTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-expired-partner-todos',
  queryParameters: [Parameters.partnerId1, Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerBusinessTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-partner-business-todos',
  queryParameters: [Parameters.businessName],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerBusinessTodoByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-partner-business-todo-by-id/{id}',
  urlParameters: [Parameters.id1],
  responses: {
    200: {
      bodyMapper: Mappers.TodoItem
    },
    500: {},
    default: {}
  },
  serializer
};

const getCurrentPartnerBusinessTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-current-partner-business-todos',
  queryParameters: [Parameters.businessName, Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getCompletedPartnerBusinessTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-completed-partner-business-todos',
  queryParameters: [Parameters.businessName, Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getExpiredPartnerBusinessTodosOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Todo/get-expired-partner-business-todos',
  queryParameters: [Parameters.businessName, Parameters.days1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TodoItem'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getTransactionTypesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/TransactionType/transaction-types',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getDesignElementsOnSiteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/WhiteLabel/get-css-details-on-site',
  queryParameters: [Parameters.url, Parameters.refresh],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllSitesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/WhiteLabel/get-all-sites',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'SiteDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getDesignElementListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/WhiteLabel/get-design-element-list',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getDesignLogoListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/WhiteLabel/get-design-logo-list',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllWhiteLabelSiteDetailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/WhiteLabel/get-all-white-label-site-detail',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'WhiteLabelSiteDetail'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

export {
  OriginationReadApi,
  OriginationReadApiContext,
  Models as OriginationReadApiModels,
  Mappers as OriginationReadApiMappers
};
